import { createUrl } from './url-utils'
import config from '../config'
import content from '../content'
import { FormExpiredError } from './form-expired-error'
import { FormSubmittedError } from './form-submitted-error'
import { ApiError } from './api-error'

export interface ErrorResponse {
    errorType: string
    errorMessage: string
}

// Taken from backend/shared/aws/types.py
export const errorTypeMap = {
    unauthorized: 'unauthorized',
    unexpected: 'unexpected',
    formNotFound: 'formNotFound',
    formExpired: 'formExpired',
    formAlreadySubmitted: 'formAlreadySubmitted',
    invalidJsonBody: 'invalidJsonBody',
    saveAnswersFailed: 'saveAnswersFailed',
    saveStatusFailed: 'saveStatusFailed',
    noAnswersToSubmit: 'noAnswersToSubmit',
    formFailedValidation: 'formFailedValidation'
}

export const api = async (
    token: string,
    path: RequestInfo | URL,
    init: RequestInit = {},
    params?: Record<string, string | string[] | number>
): Promise<Response> => {
    const url = createUrl(`${config.apiUrl}${path}`, params)
    let res: Response

    try {
        // attempt fetch
        res = await fetch(url, {
            ...init,
            credentials: 'include',
            headers: {
                ...init.headers,
                Authorization: `Bearer ${token}`
            }
        })
        if (res.ok) return res
    } catch (e) {
        // default Error
        throw new ApiError(content.errorApi)
    }

    // attempt to identify the error type
    // if not matched throw the default error
    const { errorMessage, errorType }: ErrorResponse = await res.json()
    if (errorType === errorTypeMap.formExpired) {
        throw new FormExpiredError(errorMessage)
    } else if (errorType === errorTypeMap.formAlreadySubmitted) {
        throw new FormSubmittedError(errorMessage)
    }

    // default Error
    throw new ApiError(content.errorApi)
}

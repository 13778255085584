export default function handleDynamicText(
    text: string | undefined,
    values: Record<string, string> | undefined
): string {
    if (typeof text !== 'string') {
        return ''
    }

    return text.replace(/\[(.*?)\]/g, (match, key) => (values?.[key] !== undefined ? String(values[key]) : match))
}

import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import { P } from '../../components/ui'
import config from '../../config'
import { isValidPassword, validateField } from '../../utils/form-utils'
import content from '../../content'
import { useAuthContext } from '../../auth/AuthContext'
import ErrorMessage from '../../components/form/ErrorMessage'
import Input from '../../components/form/TextInput'

type Props = {
    onSuccess: () => void
}

export default function SetNewPasswordScreen({ onSuccess }: Props) {
    const { confirmSignIn } = useAuthContext()
    const [disabled, setDisabled] = useState(false)

    const {
        formState: { errors },
        handleSubmit,
        setError,
        getValues,
        control
    } = useForm<{ password: string; reenteredPassword: string }>({
        ...config.form,
        defaultValues: { password: '', reenteredPassword: '' }
    })

    return (
        <>
            <ErrorSummary errors={errors} idPrefix='field-' />

            <P>You need to change your password to sign into your account.</P>
            <div className='govuk-inset-text'>
                <div className='govuk-!-margin-bottom-5'>Your password must contain:</div>
                <ul>
                    <li>At least 8 characters</li>
                    <li>A lower case letter</li>
                    <li>An upper case letter</li>
                    <li>A number</li>
                    <li>
                        At least one of these special characters: <br />
                        !@#$%^&+=)(-_* <br />
                        but do not use any other special characters
                    </li>
                </ul>
            </div>

            <form
                onSubmit={handleSubmit(async data => {
                    setDisabled(true)
                    confirmSignIn(data.password, {
                        onSuccess: () => onSuccess(),
                        onError: err => {
                            setError('password', { message: err.message })
                        },
                        onSettled: () => {
                            setDisabled(false)
                        }
                    })
                })}
            >
                <div className='govuk-form-group' key='password'>
                    <label htmlFor='field-password' className='govuk-label'>
                        Password
                    </label>
                    <Controller
                        name='password'
                        control={control}
                        rules={{
                            validate: {
                                validateField: (value: string) => validateField('Password', { required: true }, value),
                                validPassword: value => isValidPassword(value) || content.invalidPassword
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-password'
                                    type='password'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
                <div className='govuk-form-group' key='reentered-password'>
                    <label htmlFor='field-reenteredPassword' className='govuk-label'>
                        Re-enter password
                    </label>
                    <Controller
                        name='reenteredPassword'
                        control={control}
                        rules={{
                            validate: {
                                validateField: (value: string) =>
                                    validateField('Re-enter password', { required: true }, value),
                                validPassword: value => isValidPassword(value) || content.invalidPassword,
                                passwordEqual: value => value === getValues().password || content.nonMatchingPasswords
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-reenteredPassword'
                                    type='password'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonContinue}
                </button>
            </form>
        </>
    )
}

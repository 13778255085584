import { useId, useMemo, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../ErrorMessage'
import { getErrorMessage } from '../../../utils/form-utils'
import YearInput from './YearInput'
import MonthInput from './MonthInput'
import DayInput from './DayInput'
import {
    dateIsEmpty,
    dateIsFullyPopulated,
    getMinYearValue,
    invalidDateErrorMessage,
    isMinYearRuleValid,
    isValidDate
} from './utils'
import { ErrorMessages, Question, Rules } from '../../../config/types'
import Details from '../Details'

function dateNowStr(): string {
    // We only want the year, month and day without the time for comparison
    return new Date().toISOString().slice(0, 10)
}

function normaliseDateString(value: string): string {
    // We want to ensure the value is in the format yyyy-mm-dd, adding leading zeros if needed
    const values = value.split('-')
    return `${values[0].padStart(4, '0')}-${values[1].padStart(2, '0')}-${values[2].padStart(2, '0')}`
}

function isBeforeNowRuleValid(rules: Rules, value: string) {
    if (rules.isBeforeNow && rules.isBeforeNow === 'enabled' && dateIsFullyPopulated(value)) {
        // Compare the dates using string comparison, no need to convert to Dates
        if (!(normaliseDateString(value) < dateNowStr())) {
            return false
        }
    }
    return true
}

function isAfterNowRuleValid(rules: Rules, value: string): boolean {
    if (rules.isAfterNow && rules.isAfterNow === 'enabled' && dateIsFullyPopulated(value)) {
        // Compare the dates using string comparison, no need to convert to Dates
        if (!(normaliseDateString(value) > dateNowStr())) {
            return false
        }
    }
    return true
}

export function isYearsOldRuleValid(rules: Rules, value: string): boolean {
    if (rules.isYearsOld && dateIsFullyPopulated(value)) {
        const yearsOld = parseInt(rules.isYearsOld, 10)
        if (!Number.isNaN(yearsOld) && yearsOld > 0) {
            const comparisonDate = new Date()
            comparisonDate.setFullYear(new Date().getFullYear() - yearsOld)
            const comparisonString = comparisonDate.toISOString().slice(0, 10)
            if (comparisonString < normaliseDateString(value)) {
                return false
            }
        }
    }
    return true
}

const validateDateInputField = (question: Question, rules: Rules, value: string, errorMessages: ErrorMessages) => {
    if (rules.required && !dateIsFullyPopulated(value)) {
        return getErrorMessage('required', question.title, '', errorMessages?.required || '')
    }

    if (!dateIsEmpty(value)) {
        if (!dateIsFullyPopulated(value) || !isValidDate(value)) {
            return invalidDateErrorMessage(question)
        }
    }

    if (!isBeforeNowRuleValid(rules, value)) {
        return getErrorMessage('isBeforeNow', question.title, '', errorMessages?.isBeforeNow || '')
    }

    if (!isAfterNowRuleValid(rules, value)) {
        return getErrorMessage('isAfterNow', question.title, '', errorMessages?.isAfterNow || '')
    }

    if (!isYearsOldRuleValid(rules, value)) {
        return getErrorMessage('isYearsOld', question.title, rules.isYearsOld ?? '', errorMessages?.isYearsOld || '')
    }

    if (!isMinYearRuleValid(rules, value)) {
        const minYearInt = getMinYearValue(rules)
        return getErrorMessage('minYear', question.title, `${minYearInt}`, errorMessages?.minYear || '')
    }

    return undefined
}

interface DateInputProps {
    name: string
    defaultValue?: string // An ISO date string with only the yyyy-mm-dd
    disabled?: boolean
    dayLabel?: string
    monthLabel?: string
    yearLabel?: string
    anchorId?: string
    onChange?(value: string): void
}

export function DateInput({
    name,
    defaultValue,
    disabled = false,
    dayLabel = 'Day',
    monthLabel = 'Month',
    yearLabel = 'Year',
    anchorId = useId(),
    onChange
}: DateInputProps) {
    const dayId = useMemo(() => `${anchorId}`, [anchorId])
    const monthId = useMemo(() => `${anchorId}2`, [anchorId])
    const yearId = useMemo(() => `${anchorId}3`, [anchorId])

    // The value should be a valid date in ISO format e.g. 2024-01-01
    const values = useMemo<string[]>(() => {
        const initValue = defaultValue ?? '--'
        return initValue.split('-')
    }, [defaultValue])
    const [dayValue, setDayValue] = useState(values[2])
    const [monthValue, setMonthValue] = useState(values[1])
    const [yearValue, setYearValue] = useState(values[0])

    const triggerChange = (newValue: string) => {
        if (onChange) {
            if (dateIsEmpty(newValue)) {
                onChange('')
            } else {
                onChange(newValue)
            }
        }
    }

    const handleDayChange = (day: string) => {
        setDayValue(day)
        triggerChange(`${yearValue}-${monthValue}-${day}`)
    }

    const handleMonthChange = (month: string) => {
        setMonthValue(month)
        triggerChange(`${yearValue}-${month}-${dayValue}`)
    }

    const handleYearChange = (year: string) => {
        setYearValue(year)
        triggerChange(`${year}-${monthValue}-${dayValue}`)
    }

    return (
        <div className='govuk-date-input'>
            <DayInput
                testId={`${name}-day`}
                disabled={disabled}
                label={dayLabel}
                anchorId={dayId}
                onChange={handleDayChange}
                value={dayValue}
            />
            <MonthInput
                testId={`${name}-month`}
                disabled={disabled}
                label={monthLabel}
                anchorId={monthId}
                onChange={handleMonthChange}
                value={monthValue}
            />
            <YearInput
                testId={`${name}-year`}
                disabled={disabled}
                label={yearLabel}
                anchorId={yearId}
                onChange={handleYearChange}
                value={yearValue}
            />
        </div>
    )
}

interface DateInputFieldProps {
    question: Question
    disabled?: boolean
    anchorId: string
    detailsHeading?: string
    detailsContent?: string
}

export function DateInputField({ question, disabled, anchorId, detailsHeading, detailsContent }: DateInputFieldProps) {
    const { register, getFieldState, getValues, setValue } = useFormContext()
    const { error } = getFieldState(question.name)

    useEffect(() => {
        // If the value is currently not defined in the form, initialise it
        if (getValues(question.name) === undefined) {
            setValue(question.name, '')
        }
    }, [])

    const { onChange, name } = register(question.name, {
        validate: (value: string) =>
            validateDateInputField(question, question?.rules || {}, value, question.errorMessages || {})
    })

    const handleChange = (dateChange: string) => {
        onChange({ target: { name, value: dateChange } })
    }

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <div className='govuk-date-input'>
                <DateInput
                    name={question.name}
                    disabled={disabled}
                    anchorId={anchorId}
                    onChange={handleChange}
                    defaultValue={getValues(question.name)}
                />
            </div>
        </>
    )
}

import addresses from './pages/addresses'
import citizenship from './pages/citizenship'
import contactDetails from './pages/contactDetails'
import courtAndPoliceRecords from './pages/courtAndPoliceRecords'
import health from './pages/health'
import identity from './pages/identity'
import illegalSubstancesUse from './pages/illegalSubstancesUse'
import nationalSecurityHistory from './pages/nationalSecurityHistory'
import employmentHistory from './pages/employmentHistory'
import previousSecurityClearances from './pages/previousSecurityClearances'
import { FormConfig } from './types'

const formConfig: FormConfig = {
    title: 'Your questionnaire',
    subTitle: 'Summary page',
    showSections: true,
    sections: [
        {
            title: 'About You',
            slug: 'about-you',
            subsections: [
                {
                    title: 'Contact details',
                    slug: 'contact-details',
                    pages: contactDetails
                },
                {
                    title: 'Identity',
                    slug: 'identity',
                    pages: identity
                },
                {
                    title: 'Citizenship',
                    slug: 'citizenship',
                    pages: citizenship
                },
                {
                    title: 'Addresses',
                    slug: 'addresses',
                    pages: addresses
                },
                {
                    title: 'Health',
                    slug: 'health',
                    pages: health
                },
                {
                    title: 'Court and police records',
                    slug: 'court-and-police-records',
                    pages: courtAndPoliceRecords
                },
                {
                    title: 'Illegal substances use',
                    slug: 'illegal-substances-use',
                    pages: illegalSubstancesUse
                },
                {
                    title: 'National Security History',
                    slug: 'national-security-history',
                    pages: nationalSecurityHistory
                }
            ]
        },
        {
            title: 'Employment',
            slug: 'employment',
            subsections: [
                {
                    title: 'Employment History',
                    slug: 'employment-history',
                    pages: employmentHistory
                },
                {
                    title: 'Previous security clearances',
                    slug: 'previous-security-clearances',
                    pages: previousSecurityClearances
                }
            ]
        }
    ]
}
export default formConfig

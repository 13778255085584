export function findArrayDuplicates<T>(arr: T[]) {
    const sortedArray: T[] = arr.slice().sort()

    const results: T[] = []

    for (let i = 0; i < sortedArray.length - 1; i++) {
        if (sortedArray[i + 1] === sortedArray[i]) {
            results.push(sortedArray[i])
        }
    }
    return results
}

export const isObject = (obj: unknown): obj is object => obj === Object(obj)

import validator from 'validator'
import { parsePhoneNumber } from 'libphonenumber-js'
import { FieldValues } from 'react-hook-form'
import { defaultReservedCharacters } from '../constants'
import { ErrorMessages, Question, Rules, RulesInContent } from '../config/types'
import { defaultErrorMessages } from '../config/defaultContent'

export const getErrorMessage = (name: RulesInContent, label: string, value: string, errorMessage?: string) =>
    String(errorMessage || defaultErrorMessages[name] || '')
        .replace('$LABEL', label)
        .replace('$CONDITION', value)

export const isPostcode = (value: string) => {
    const re = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i
    return re.test(value)
}

export const isValidPassword = (value: string) => {
    const re = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=)(\-_*])([a-zA-Z0-9!@#$%^&+=*)(\-_]){8,}$/
    return re.test(value)
}

export const validateMobile = (value: string) => {
    const defaultCountryCode = 'GB'

    if (!value.startsWith('+') && !value.startsWith('0')) {
        return false
    }

    try {
        const phoneNumber = parsePhoneNumber(value, defaultCountryCode)
        return phoneNumber?.isValid()
    } catch (error) {
        return false
    }
}

export const isMinLength = (value: string, minLength: number) => value.length >= minLength

export const isMaxLength = (value: string, maxLength: number) => value.length <= maxLength

export const isMinNumber = (value: string, minNumber: number) =>
    validator.isNumeric(value) && Number(value) >= minNumber

export const isMaxNumber = (value: string, maxNumber: number) =>
    validator.isNumeric(value) && Number(value) <= maxNumber

export const matchesPattern = (value: string, pattern: any) => {
    const re = new RegExp(pattern)
    return re.test(value)
}

export const containsReservedCharacters = (value: string, reservedCharacters: string = defaultReservedCharacters) => {
    const escapedReservedCharacters = reservedCharacters.replace(/[.*+?^${}()|[\]\\-]/g, '\\$&')
    return !new RegExp(`^[^${escapedReservedCharacters}]*$`).test(value)
}

export const hasValue = (value: unknown) => !!value && String(value).trim() !== '' && !!Object.keys(value).length

export const validateField = (title: string, rules: Rules, value: string, errorMessages?: ErrorMessages) => {
    const trimmedValue = typeof value === 'string' ? value.trim() : value

    if (rules.required && !hasValue(trimmedValue)) {
        return getErrorMessage('required', title, '', errorMessages?.required || '')
    }

    if (!rules.required && !hasValue(trimmedValue)) {
        // allow empty optional values
        return undefined
    }

    if (rules.reservedCharacters && containsReservedCharacters(trimmedValue, rules.reservedCharacters)) {
        return getErrorMessage('reservedCharacters', title, '', errorMessages?.reservedCharacters || '')
    }

    if (rules.email && !validator.isEmail(trimmedValue)) {
        return getErrorMessage('email', title, '', errorMessages?.email || '')
    }

    if (rules.number && !validator.isNumeric(trimmedValue)) {
        return getErrorMessage('number', title, '', errorMessages?.number || '')
    }

    if (rules.telephone && !validateMobile(trimmedValue)) {
        return getErrorMessage('telephone', title, '', errorMessages?.telephone || '')
    }

    if (rules.minLength && !isMinLength(trimmedValue, Number(rules.minLength))) {
        return getErrorMessage('minLength', title, String(rules.minLength), errorMessages?.minLength || '')
    }

    if (rules.maxLength && !isMaxLength(trimmedValue, Number(rules.maxLength))) {
        return getErrorMessage('maxLength', title, String(rules.maxLength), errorMessages?.maxLength || '')
    }

    if (rules.min && !isMinNumber(trimmedValue, Number(rules.min))) {
        return getErrorMessage('min', title, String(rules.min), errorMessages?.min || '')
    }

    if (rules.max && !isMaxNumber(trimmedValue, Number(rules.max))) {
        return getErrorMessage('max', title, String(rules.max), errorMessages?.max || '')
    }

    if (rules.pattern && !matchesPattern(trimmedValue, rules.pattern)) {
        return getErrorMessage('pattern', title, '', errorMessages?.pattern || '')
    }

    return undefined
}

export const getInputRules = (rules: Rules, title: string, defaultMaxLength: number) => {
    const specialCharacterValidation = (value: string) =>
        !containsReservedCharacters(value, rules.reservedCharacters) || getErrorMessage('reservedCharacters', title, '')

    return {
        ...rules,
        validate: { specialCharacterValidation },
        maxLength: rules.maxLength || defaultMaxLength
    }
}

export const formDataToObject = (formData: [string, FormDataEntryValue][]) =>
    formData.reduce(
        (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue[0]]: currentValue[1]
        }),
        {}
    )

export const getListAnswers = (formValues: FieldValues, questions: Question[]) => {
    const groupedAnswers: {
        [key: string]: { question: Question; questionName: string; answer: string; id: string }[]
    } = {}

    Object.entries(formValues).forEach(([questionName, answer]) => {
        const question = questions.find(q => questionName.endsWith(q.name))
        const id = questionName.split('-')[0]

        if (question) {
            if (!groupedAnswers[id]) groupedAnswers[id] = []
            groupedAnswers[id].push({ question, questionName, answer, id })
        }
    })

    return groupedAnswers
}

export const listHasValue = (formValues: FieldValues, questions: Question[]): boolean =>
    Object.entries(formValues).some(([questionName]) => questions.some(q => questionName.endsWith(q.name)))

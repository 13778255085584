import React from 'react'
import { Link } from 'react-router-dom'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { P } from '../../components/ui'

function Error404Screen() {
    return (
        <PageTemplate>
            <h1 className='govuk-heading-l'>Page not found</h1>
            <P>If you typed the web address, check it is correct.</P>
            <P>If you pasted the web address, check you copied the entire address.</P>
            <P>
                Return to{' '}
                <Link data-testid='page-404-overview-link' to='/'>
                    Your questionnaire
                </Link>{' '}
                page.
            </P>
        </PageTemplate>
    )
}

export default Error404Screen

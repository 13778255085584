import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { useMemo, useState } from 'react'
import { Query } from '../../types'
import Error404Screen from '../ErrorScreen/Error404Screen'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { HandleNavigation, HandleSave } from './types'
import { updateForm } from '../../service/form'
import { FormExpiredError } from '../../utils/form-expired-error'
import { FormSubmittedError } from '../../utils/form-submitted-error'
import config from '../../config'
import type { FetchFormResponse, UpdateFormParams, UpdateFormResponse } from '../../service/types'
import QuestionPageTemplate from '../../components/form/QuestionPageTemplate/QuestionPageTemplate'
import InformationPageTemplate from '../../components/form/InformationPageTemplate'
import { getEnabledSubsections, extractConditionalQuestions } from '../../utils/config-utils'
import { useFormData } from '../../hooks/useFormData'
import { useAuthContext } from '../../auth/AuthContext'
import { SubsectionRouterProps } from '../../components/Router/SubsectionRouterProps'
import { useFormConfig } from '../../hooks/useFormConfig'

const { apiUrl, urls } = config

function Subsection({ subsection, sectionTitle, sectionName, urlPath }: SubsectionRouterProps) {
    const navigate = useNavigate()
    const { page } = useParams()
    const queryClient = useQueryClient()

    const { data: currentData, refetch, isFetching } = useFormData()
    const { fetchJWT } = useAuthContext()
    const [saving, setSaving] = useState(false)

    const { mutateAsync } = useMutation<UpdateFormResponse, boolean, UpdateFormParams>({
        throwOnError: false,
        mutationFn: async params => {
            try {
                const { idToken } = await fetchJWT()
                if (idToken) {
                    await updateForm(idToken, params)
                    return true
                }
                navigate(urls.defaultError)
                return false
            } catch (error) {
                if (error instanceof FormExpiredError || error instanceof FormSubmittedError) {
                    navigate(config.urls.landingAccounts)
                } else {
                    navigate(urls.defaultError)
                }
                // Unable to throw an exception, it fails the jest tests
                return false
            }
        }
    })

    const { formConfig } = useFormConfig()

    const enabledPages = useMemo(
        () => getEnabledSubsections(formConfig, currentData)[subsection.slug]?.pages ?? [],
        [currentData, formConfig, subsection.slug]
    )

    const currentPageIndex = useMemo(
        () => (page ? enabledPages?.findIndex(({ name }) => name === page) : 0),
        [page, enabledPages]
    )

    const pageConfig = useMemo(() => enabledPages?.[currentPageIndex], [currentPageIndex, enabledPages])
    const is404 = useMemo(() => !enabledPages || currentPageIndex === -1, [currentPageIndex, enabledPages])

    const handleNavigation: HandleNavigation = async () => {
        const data = (await refetch())?.data

        const enabledPages = getEnabledSubsections(formConfig, data)[subsection.slug]?.pages

        const nextPageIndex = currentPageIndex + 1

        const url =
            nextPageIndex >= enabledPages.length
                ? urls.landingAccounts
                : `${urlPath}/${enabledPages[nextPageIndex].name}`

        navigate(url)
    }

    const handleSave: HandleSave = async (formData, isDraft, isList = false) => {
        const pageName = enabledPages[currentPageIndex].name
        const drafts = { ...currentData?.drafts }
        const newDraft = (drafts[subsection.slug] || []).filter(x => x !== pageName)
        if (isDraft) newDraft.push(pageName)

        drafts[subsection.slug] = newDraft

        queryClient.setQueryData<FetchFormResponse>([Query.FormData], {
            ...currentData,
            answers: {
                ...currentData?.answers,
                ...formData
            },
            drafts
        })

        if (apiUrl) {
            // filter so only data from the current page is sent
            const questions = extractConditionalQuestions(('questions' in pageConfig && pageConfig.questions) || [])

            const apiData: FetchFormResponse['answers'] = questions.reduce((result, { name }) => {
                if (isList) {
                    const listAnswers = Object.fromEntries(
                        Object.entries(formData).filter(
                            ([question, answer]) => question.endsWith(name) && answer !== undefined
                        )
                    )

                    return { ...result, ...listAnswers }
                }
                let answer = formData?.[name]

                if (typeof answer === 'string') answer = answer.trim()
                return typeof answer !== undefined ? { ...result, [name]: answer } : result
            }, {})

            setSaving(true)
            return mutateAsync(
                {
                    'subsection-id': subsection.slug,
                    'page-id': pageName,
                    'is-save-unfinished': isDraft,
                    answers: apiData
                },
                {
                    onSuccess: data => {
                        // Will only return true if there was no error returned
                        if (data && !isList) {
                            handleNavigation()
                        }
                    },
                    onSettled: () => {
                        setSaving(false)
                        refetch()
                    }
                }
            )
        }
        return Promise.resolve({})
    }

    return is404 ? (
        <Error404Screen />
    ) : (
        <PageTemplate
            key={pageConfig.name}
            breadcrumbs={{ sectionName, sectionTitle }}
            title={pageConfig.title}
            subTitle={sectionTitle}
        >
            {pageConfig.pageType === 'question' && (
                <QuestionPageTemplate
                    config={pageConfig}
                    onSave={async data => {
                        await handleSave(data, false)
                    }}
                    onSaveUnfinished={async data => {
                        await handleSave(data, true)
                    }}
                    disabled={saving || isFetching}
                />
            )}

            {pageConfig.pageType === 'list' && (
                <QuestionPageTemplate
                    config={pageConfig}
                    onSave={async data => {
                        await handleSave(data, false, true)
                    }}
                    onSaveUnfinished={async data => {
                        await handleSave(data, true)
                    }}
                    onContinue={() => {
                        handleNavigation()
                    }}
                    disabled={saving || isFetching}
                />
            )}

            {pageConfig.pageType === 'information' && (
                <InformationPageTemplate
                    config={pageConfig}
                    onContinue={handleNavigation}
                    disabled={saving || isFetching}
                />
            )}
        </PageTemplate>
    )
}

export default Subsection

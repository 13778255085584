import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import Details from './Details'
import { validateField } from '../../utils/form-utils'
import { ErrorMessages, Rules } from '../../config/types'

type FileUploadProps = {
    id: string
    name: string
    label?: string
    detailsHeading?: string
    detailsContent?: string
    rules?: Rules
    errorMessages?: ErrorMessages
}

function FileUpload(props: FileUploadProps) {
    const { id, name, label, detailsHeading, detailsContent, rules, errorMessages } = props
    const { register, getFieldState } = useFormContext()

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(label || 'File upload', rules || {}, value, errorMessages)
    })

    const { error } = getFieldState(name)

    return (
        <div className='govuk-form-group'>
            {label && (
                <label className='govuk-heading-m' htmlFor={id}>
                    {label}
                </label>
            )}

            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}
            {error && <ErrorMessage error={error} />}

            <input
                {...hooksFormRegister}
                id={id}
                name={name}
                className='govuk-file-upload'
                type='file'
                data-testid='file-upload'
                accept='image/*,.pdf'
                style={{ marginTop: '15px' }}
            />
        </div>
    )
}

FileUpload.displayName = 'FileUpload'

export default FileUpload

import { useQuery } from '@tanstack/react-query'
import { useAuthContext } from '../auth/AuthContext'
import { FetchFormResponse } from '../service/types'
import { Query } from '../types'
import { fetchForm } from '../service/form'

export const useFormData = () => {
    const { fetchJWT } = useAuthContext()

    return useQuery<FetchFormResponse, Error>({
        queryKey: [Query.FormData],
        queryFn: async () => {
            const token = (await fetchJWT()).idToken
            if (!token) {
                throw Error('Not authenticated')
            }
            return await fetchForm(token)
        },
        staleTime: Infinity
    })
}

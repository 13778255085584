import { PostcodeLookupResponse } from './types'
import { api } from '../utils/data-utils'
import { useAuthContext } from '../auth/AuthContext'

export const useFetchAddress = () => {
    const { fetchJWT } = useAuthContext()
    return (postcode: string) => async (): Promise<PostcodeLookupResponse> => {
        const token = (await fetchJWT()).idToken
        if (!token) {
            throw Error('Not authenticated')
        }
        const url = `/address?postcode=${postcode}`
        try {
            const res = await api(token, url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
            return res.json()
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

import { useId, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import type { Field, LoginData, Props } from './types'
import ErrorSummary from '../../ErrorSummary/ErrorSummary'
import { validateField } from '../../../utils/form-utils'
import { P } from '../../ui'
import content from '../../../content'
import config from '../../../config'
import { useAuthContext } from '../../../auth/AuthContext'
import ErrorMessage from '../ErrorMessage'
import Input from '../TextInput'
import CTCPortal from '../../../screens/CTCPortal/CTCPortal'

const fields: Field[] = [
    { name: 'username', label: 'Email address' },
    { name: 'password', label: 'Password' }
]

export default function SigninForm({ onSuccess }: Props) {
    const id = useId()
    const { signIn } = useAuthContext()
    const [disabled, setDisabled] = useState(false)
    const [beforeSignIn, setBeforeSignIn] = useState(true)

    const {
        formState: { errors },
        handleSubmit,
        setError,
        control
    } = useForm<LoginData>({
        ...config.form,
        defaultValues: { username: '', password: '' }
    })

    if (beforeSignIn) {
        return (
            <>
                <CTCPortal />
                <button
                    type='submit'
                    className='govuk-button'
                    onClick={() => {
                        setBeforeSignIn(false)
                    }}
                >
                    <b>Sign in</b>
                    <svg
                        className='govuk-button__start-icon'
                        xmlns='http://www.w3.org/2000/svg'
                        width='17.5'
                        height='19'
                        viewBox='0 0 33 40'
                        aria-hidden='true'
                        focusable='false'
                    >
                        <path fill='currentColor' d='M0 0h13l20 20-20 20H0l20-20z' />
                    </svg>
                </button>
            </>
        )
    }

    return (
        <>
            <ErrorSummary errors={errors} idPrefix={id} />
            <h1 className='govuk-heading-xl'>Sign in</h1>

            <P>If this is your first time signing in, check your email for credentials to sign in.</P>

            <form
                noValidate
                onSubmit={handleSubmit(async data => {
                    setDisabled(true)
                    signIn(data, {
                        onSuccess: () => onSuccess(),
                        onError: err => {
                            setError('username', {
                                message: err.message
                            })
                        },
                        onSettled: () => {
                            setDisabled(false)
                        }
                    })
                })}
            >
                {fields.map(({ name, label }) => {
                    const errorMessage = errors[name]
                    const fieldId = `${id}${name}`

                    return (
                        <div className={`govuk-form-group ${errorMessage && 'govuk-form-group--error'}`} key={name}>
                            <label htmlFor={fieldId} className='govuk-label'>
                                {label}
                            </label>
                            <Controller
                                name={name}
                                control={control}
                                rules={{
                                    validate: (value: string) =>
                                        validateField(label, { required: true, email: name === 'username' }, value)
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <>
                                        {error && <ErrorMessage error={error} />}
                                        <Input
                                            {...field}
                                            id={fieldId}
                                            type={name === 'password' ? 'password' : 'email'}
                                            width='three-quarters'
                                            autoComplete='off'
                                            disabled={disabled}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    )
                })}

                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonSignin}
                </button>
            </form>

            <P>
                <Link to={config.urls.resetPasswordEmail} className='govuk-link'>
                    {content.forgotPassword}
                </Link>
            </P>
        </>
    )
}

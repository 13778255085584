import { useEffect, useId, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { getErrorMessage, hasValue, isPostcode } from '../../../utils/form-utils'
import ErrorMessage from '../ErrorMessage'
import { useFetchAddress } from '../../../service/api'
import { PostcodeLookupResponse } from '../../../service/types'
import content from '../../../content'
import ButtonText from '../../ButtonText'
import { P } from '../../ui'
import { fields } from './fields'
import { AddressData, Props } from './types'
import { Query } from '../../../types'
import Details from '../Details'

const {
    labels: { postcode: postcodeLabel, addressSelect, addressConfirm },
    buttonPostcodeLookup,
    buttonChangePostcode,
    buttonSearchAgain,
    buttonContinue,
    noAddressFound
} = content

enum Screen {
    EnterPostcode = 'EnterPostcode',
    Results = 'Results',
    ManualAddress = 'ManualAddress',
    ConfirmAddress = 'ConfirmAddress',
    NoAddressFound = 'NoAddressFound'
}

// For Manual Address
export const validateManualAddress = (value: Partial<AddressData>): boolean | string => {
    let result: boolean | string = true

    if (value === undefined) {
        result = content.invalidPostcode
        return result
    }

    fields.forEach(({ required, name }) => {
        if (required && !hasValue(value[name])) result = getErrorMessage('required', content.labels?.[name], '')
    })

    if (!isPostcode(value?.postcode || '')) result = content.invalidPostcode

    return result
}

export default function PostcodeLookup({
    onValueSelect,
    onValueChange,
    setShowSaveButton,
    defaultValue = {},
    manualAddressError,
    detailsHeading,
    detailsContent,
    ...props
}: Props) {
    const queryClient = useQueryClient()
    const id = useId()
    const idPostcode = `postcode-${id}`
    const idAddress = `address-list--${id}`
    const [address, setAddress] = useState<Partial<AddressData>>(defaultValue)
    const [screen, setScreen] = useState<string>(hasValue(defaultValue) ? Screen.ConfirmAddress : Screen.EnterPostcode)

    const {
        formState: { errors: formErrors, touchedFields, isValid },
        register,
        trigger,
        clearErrors,
        watch
    } = useForm<Pick<{ postcode: string }, 'postcode'>>({
        mode: 'onSubmit',
        defaultValues: {
            postcode: ''
        }
    })

    const postcode = watch('postcode')
    const fetchAddress = useFetchAddress()
    const { data, refetch, status, isLoading, isFetching } = useQuery<
        PostcodeLookupResponse,
        Error,
        PostcodeLookupResponse
    >({
        queryKey: [Query.PostcodeLookup],
        queryFn: fetchAddress(postcode),
        enabled: false
    })

    const results = data?.results
    const isTouched = !!Object.keys(touchedFields).length

    useEffect(() => {
        if (hasValue(defaultValue)) {
            setShowSaveButton(true)
        } else {
            setShowSaveButton(false)
        }
    }, [])

    useEffect(() => {
        if (status === 'success') {
            if (data.results?.length) {
                setScreen(Screen.Results)
                setShowSaveButton(false)
            } else {
                setScreen(Screen.NoAddressFound)
            }
        }
    }, [status, isLoading, isFetching])

    if (!isValid) queryClient.removeQueries({ queryKey: [Query.PostcodeLookup] })

    // For Manual Address
    const [value, setValues] = useState<Partial<AddressData>>(defaultValue)

    // For Manual Address
    useEffect(() => {
        if (onValueChange) {
            const newValue = { ...value }
            fields.forEach(({ name }) => {
                if (!value[name]) newValue[name] = ''
            })
            setValues(newValue)
            onValueChange(props.name, newValue)
        }
    }, [])

    return (
        <div className={`govuk-form-group ${formErrors.postcode && 'govuk-form-group--error'}`}>
            {formErrors.postcode && <ErrorMessage error={formErrors.postcode} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <div className='govuk-form-group'>
                <P>
                    Find your address by{' '}
                    <ButtonText
                        className='govuk-link govuk-link--no-visited-state'
                        onClick={e => {
                            e.preventDefault()
                            if (screen !== Screen.EnterPostcode) setScreen(Screen.EnterPostcode)
                            setShowSaveButton(false)
                            clearErrors()
                        }}
                        style={{ fontSize: '19px' }}
                    >
                        searching your postcode
                    </ButtonText>{' '}
                    or{' '}
                    <ButtonText
                        className='govuk-link govuk-link--no-visited-state'
                        onClick={e => {
                            e.preventDefault()
                            if (screen !== Screen.ManualAddress) setScreen(Screen.ManualAddress)
                            setShowSaveButton(true)
                            clearErrors()
                        }}
                        style={{ fontSize: '19px' }}
                    >
                        enter it manually
                    </ButtonText>
                </P>
            </div>

            <div className='govuk-form-group'>
                {screen === Screen.EnterPostcode && (
                    <>
                        <label className='govuk-heading-s' htmlFor={idPostcode}>
                            {postcodeLabel}
                        </label>
                        <div style={{ display: 'flex' }}>
                            <input
                                {...register('postcode', {
                                    required: {
                                        value: true,
                                        message: getErrorMessage('required', postcodeLabel, '')
                                    },
                                    validate: {
                                        matchPattern: v => isPostcode(v) || content.invalidPostcode
                                    },
                                    onChange: e => {
                                        if (isTouched && !e.currentTarget.value) trigger()
                                    }
                                })}
                                className='govuk-input govuk-input--width-10'
                                id={idPostcode}
                                type='text'
                                style={{ display: 'flex' }}
                            />
                        </div>
                        <div className='gov-uk-button-group'>
                            <button
                                className='govuk-button'
                                type='button'
                                disabled={!postcode}
                                style={{ marginTop: '25px' }}
                                onClick={async () => {
                                    clearErrors()
                                    if (await trigger()) {
                                        refetch()
                                    }
                                }}
                            >
                                {buttonPostcodeLookup}
                            </button>
                        </div>
                    </>
                )}

                {screen === Screen.Results && (
                    <>
                        <label className='govuk-heading-s' htmlFor={idPostcode}>
                            {postcodeLabel}
                        </label>
                        <div style={{ display: 'flex' }}>
                            <P id={idPostcode}>{postcode}</P>
                            <ButtonText
                                className='govuk-link govuk-link--no-visited-state'
                                onClick={() => {
                                    setScreen(Screen.EnterPostcode)
                                    setShowSaveButton(false)
                                }}
                                style={{ fontSize: '19px', display: 'flex', marginLeft: '20px' }}
                            >
                                {buttonChangePostcode}
                            </ButtonText>
                        </div>

                        {results?.length && (
                            <div className='govuk-form-group govuk-!-margin-bottom-6' data-testid='fetched-address'>
                                <label className='govuk-heading-s' htmlFor={idAddress}>
                                    {addressSelect}
                                </label>

                                <select
                                    className='govuk-select'
                                    id={idAddress}
                                    name='address'
                                    onChange={e => {
                                        // extract data
                                        const value = Number(e.currentTarget.value)
                                        const address = results[value]

                                        setAddress({
                                            line1: address.BuildingNameOrNumber || address.AddressLine1 || '',
                                            line2: (address.BuildingNameOrNumber ? address.AddressLine1 : '') || '',
                                            town: address.TownOrCity || '',
                                            county: '',
                                            postcode: address.Postcode || ''
                                        })

                                        if (typeof onValueSelect === 'function')
                                            onValueSelect({
                                                line1: address.BuildingNameOrNumber || address.AddressLine1 || '',
                                                line2: (address.BuildingNameOrNumber ? address.AddressLine1 : '') || '',
                                                town: address.TownOrCity || '',
                                                county: '',
                                                postcode: address.Postcode || ''
                                            })
                                    }}
                                >
                                    <option value=''>{results.length} addresses found</option>
                                    {results.map((address, i) => (
                                        <option value={i} key={`${address.BuildingNameOrNumber}${address.Postcode}`}>
                                            {address.Address}
                                        </option>
                                    ))}
                                </select>
                                <div className='gov-uk-button-group'>
                                    <button
                                        className='govuk-button'
                                        type='button'
                                        disabled={!hasValue(address)}
                                        style={{ marginTop: '25px' }}
                                        onClick={() => {
                                            setShowSaveButton(true)
                                            setScreen(Screen.ConfirmAddress)
                                        }}
                                    >
                                        {buttonContinue}
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {screen === Screen.ConfirmAddress && (
                    <>
                        <label className='govuk-heading-s' htmlFor={idAddress}>
                            {addressConfirm}
                        </label>
                        <div className='govuk-inset-text' id={idAddress}>
                            <ul className='govuk-list govuk-!-margin-0'>
                                {(['line1', 'line2', 'town', 'county', 'postcode'] as Array<keyof typeof address>)
                                    .map(field => address[field])
                                    .filter(Boolean)
                                    .map(a => (
                                        <li key={a}>{a}</li>
                                    ))}
                            </ul>
                        </div>
                    </>
                )}

                {screen === Screen.NoAddressFound && (
                    <>
                        <label className='govuk-heading-s'>{noAddressFound}</label>
                        <div className='govuk-inset-text'>
                            No address could be found for postcode <b>{postcode}</b>
                        </div>
                        <ButtonText
                            className='govuk-link govuk-link--no-visited-state'
                            onClick={() => {
                                setScreen(Screen.EnterPostcode)
                            }}
                            style={{ fontSize: '19px' }}
                        >
                            {buttonSearchAgain}
                        </ButtonText>
                    </>
                )}

                {screen === Screen.ManualAddress && (
                    <>
                        {manualAddressError && <ErrorMessage error={manualAddressError} />}
                        {fields.map(({ name, width, required }) => {
                            const fieldId = `field-${name}-${id}`
                            return (
                                <div className='govuk-form-group' key={name}>
                                    <label className='govuk-label' htmlFor={fieldId}>
                                        {content.labels?.[name]} {!required && '(optional)'}
                                    </label>
                                    <input
                                        {...props}
                                        id={fieldId}
                                        name={name}
                                        value={value[name] || ''}
                                        onChange={e => {
                                            const newValue = { ...value, [name]: e.currentTarget.value }
                                            setValues(newValue)
                                            setAddress(newValue)
                                            if (typeof onValueChange === 'function') onValueChange(props.name, newValue)
                                        }}
                                        className={`govuk-input ${width === 'medium' && 'govuk-!-width-two-thirds'} ${
                                            width === 'small' && 'govuk-input--width-10'
                                        }`}
                                        type='text'
                                    />
                                </div>
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}

import { ComponentProps, useId, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { getErrorMessage } from '../../utils/form-utils'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import ErrorMessage from '../../components/form/ErrorMessage'
import { submitDeclaration } from '../../service/form'
import { ApiError } from '../../utils/api-error'
import { FormExpiredError } from '../../utils/form-expired-error'
import { FormSubmittedError } from '../../utils/form-submitted-error'
import config from '../../config'
import { P } from '../../components/ui'
import Checkbox from '../../components/form/Checkbox'
import { useAuthContext } from '../../auth/AuthContext'

const title = 'Sign your declaration'
const subTitle = 'Declaration'
const props: Partial<ComponentProps<typeof PageTemplate>> = {
    title,
    subTitle,
    breadcrumbs: { sectionName: `${subTitle}: ${title}` }
}

function Declaration() {
    const id = useId()
    const { fetchJWT } = useAuthContext()
    const navigate = useNavigate()
    const inputId = `${id}declare`
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        formState: { errors },
        handleSubmit,
        register,
        setError
    } = useForm<{
        declare: string
    }>({
        ...config.form,
        defaultValues: { declare: '' }
    })

    const { mutate } = useMutation<boolean, ApiError>({
        mutationFn: async () => {
            try {
                const { idToken } = await fetchJWT()
                if (!idToken) {
                    throw Error('Missing accessToken')
                }
                setIsSubmitting(true)
                return await submitDeclaration(idToken)
            } catch (e) {
                if (e instanceof FormExpiredError || e instanceof FormSubmittedError) {
                    navigate(config.urls.landingAccounts)
                    return false
                }
                throw e
            }
        },
        onSuccess: result => {
            if (result) {
                navigate(config.urls.declarationSuccess)
            }
        },
        onError: err => setError('declare', { message: err.message }),
        onSettled: () => {
            setIsSubmitting(false)
        }
    })

    return (
        <PageTemplate {...props}>
            <ErrorSummary errors={errors} idPrefix={id} />

            <P>
                By submitting your questionnaire you confirm that the information you are providing is correct to the
                best of your knowledge
            </P>

            <form
                onSubmit={handleSubmit(async () => {
                    await mutate()
                })}
            >
                <div className={`govuk-form-group ${errors.declare && 'govuk-form-group--error'}`}>
                    {errors.declare && <ErrorMessage error={errors.declare} />}

                    <div className='govuk-checkboxes__item'>
                        <Checkbox
                            {...register('declare', {
                                required: {
                                    value: true,
                                    message: getErrorMessage('required', 'Declaration', '')
                                }
                            })}
                            id={inputId}
                            disabled={isSubmitting}
                        />

                        <label className='govuk-label govuk-checkboxes__label' htmlFor={inputId}>
                            I agree
                        </label>
                    </div>
                </div>

                <button type='submit' className='govuk-button' disabled={isSubmitting}>
                    Submit your questionnaire
                </button>
            </form>
        </PageTemplate>
    )
}

export default Declaration

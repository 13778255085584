import { useState, useEffect } from 'react'
import { getCookie, setCookie } from '../../utils/cookie-utils'

function CookieBanner() {
    const [hasConsented, setHasContented] = useState<boolean | null>(null)
    const [showConfirmation, setConfirmation] = useState(false)

    useEffect(() => {
        const consentCookie = getCookie('cookie_policy')
        if (consentCookie === 'accepted') {
            setHasContented(true)
        } else if (consentCookie === 'rejected') {
            setHasContented(false)
        } else {
            setHasContented(null)
        }
    }, [])

    const acceptCookies = () => {
        setCookie('cookie_policy', 'accepted', 365)
        setHasContented(true)
        setConfirmation(true)
    }

    const rejectCookies = () => {
        setCookie('cookie_policy', 'rejected', 365)
        setHasContented(false)
        setConfirmation(true)
    }

    const hideConfirmation = () => setConfirmation(false)

    if (hasConsented !== null)
        return showConfirmation ? (
            <div className='govuk-cookie-banner' data-nosnippet role='region' aria-label='Cookies on CTC Portal'>
                <div className='govuk-cookie-banner__message govuk-width-container' role='alert'>
                    <div className='govuk-grid-row'>
                        <div className='govuk-grid-column-two-thirds'>
                            <div className='govuk-cookie-banner__content'>
                                <p className='govuk-body'>
                                    You have {hasConsented ? 'accepted' : 'rejected'} additional cookies. You can{' '}
                                    <a className='govuk-link' href='/cookies'>
                                        change your cookie settings
                                    </a>{' '}
                                    at any time.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='govuk-button-group'>
                        <button
                            type='button'
                            className='govuk-button'
                            data-module='govuk-button'
                            onClick={hideConfirmation}
                        >
                            Hide cookie message
                        </button>
                    </div>
                </div>
            </div>
        ) : null

    return (
        <div className='govuk-cookie-banner' data-nosnippet role='region' aria-label='Cookies on CTC Portal'>
            <div className='govuk-cookie-banner__message govuk-width-container'>
                <div className='govuk-grid-row'>
                    <div className='govuk-grid-column-two-thirds'>
                        <h2 className='govuk-cookie-banner__heading govuk-heading-m'>Cookies on CTC Portal</h2>
                        <div className='govuk-cookie-banner__content'>
                            <p className='govuk-body'>We use some essential cookies to make this service work.</p>
                            <p className='govuk-body'>
                                We’d also like to use additional cookies so we can understand how you use the service
                                and make improvements.
                            </p>
                        </div>
                    </div>
                </div>
                <div className='govuk-button-group'>
                    <button type='button' className='govuk-button' data-module='govuk-button' onClick={acceptCookies}>
                        Accept additional cookies
                    </button>
                    <button type='button' className='govuk-button' data-module='govuk-button' onClick={rejectCookies}>
                        Reject additional cookies
                    </button>
                    <a className='govuk-link' href='/cookies'>
                        View cookies
                    </a>
                </div>
            </div>
        </div>
    )
}

export default CookieBanner

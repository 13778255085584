import { ChangeEvent } from 'react'
import { handleKeyPress, handlePaste } from './utils'

interface DayInputProps {
    testId?: string
    value?: string // A string of dd
    disabled?: boolean
    label?: string
    anchorId?: string
    onChange?(value: string): void
}

export default function DayInput({ testId, value = '', disabled = false, label, anchorId, onChange }: DayInputProps) {
    const DAY_LENGTH = 2

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (
            onChange &&
            event.target.value.length <= DAY_LENGTH &&
            (event.target.value === '' || event.target.valueAsNumber <= 31)
        ) {
            onChange(`${event.target.value}`)
        }
    }

    const handleBlur = () => {
        if (value.length > 0 && value.length < DAY_LENGTH) {
            const paddedDay = value.padStart(DAY_LENGTH, '0')
            if (onChange) {
                onChange(`${paddedDay}`)
            }
        }
    }

    return (
        <div className='govuk-date-input__item'>
            <div className='govuk-form-group'>
                <label className='govuk-label govuk-date-input__label' htmlFor={anchorId}>
                    {label}
                </label>
                <input
                    className='govuk-input govuk-date-input__input govuk-input--width-2'
                    id={anchorId}
                    data-testid={testId}
                    type='number'
                    size={DAY_LENGTH}
                    value={value}
                    onChange={handleChange}
                    onBlur={() => handleBlur()}
                    disabled={disabled ?? false}
                    onKeyDown={e => handleKeyPress(e)}
                    onPaste={e => handlePaste(e)}
                    onFocus={e => e.target.addEventListener('wheel', e => e.preventDefault(), { passive: false })}
                />
            </div>
        </div>
    )
}

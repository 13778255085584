export const defaultErrorMessages = {
    required: '$LABEL is mandatory',
    email: '$LABEL should be a valid email address',
    number: '$LABEL must be a number',
    telephone: '$LABEL should be a valid telephone number',
    minLength: '$LABEL must be $CONDITION characters or more',
    maxLength: '$LABEL must be $CONDITION characters or less',
    min: '$LABEL must be $CONDITION or more',
    max: '$LABEL must be $CONDITION or less',
    reservedCharacters: '$LABEL should not contain reserved characters',
    pattern: '$LABEL must be in a valid format',
    isBeforeNow: '$LABEL must be in the past',
    isAfterNow: '$LABEL must be in the future',
    isYearsOld: '$LABEL must be at least $CONDITION years old',
    minYear: '$LABEL must have a year of $CONDITION or after'
} as const

export const COUNTRIES = [
    'United Kingdom',
    'Afghanistan',
    'Aland Islands',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bosnia And Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei',
    'Bulgaria',
    'Burkina Faso',
    'Burma',
    'Burundi',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cape Verde',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Channel Islands',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros',
    'Congo',
    'Congo, The Dem Rep Of The',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Cyprus',
    'Czech Republic',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'East Timor',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Ethiopia',
    'Falkland Islands',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Isl & Mcdonald Isl',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle Of Man',
    'Israel',
    'Italy',
    'Ivory Coast',
    'Jamaica',
    'Japan',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Kosovo',
    'Kuwait',
    'Kyrgyzstan',
    'Laos',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Macedonia',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia',
    'Moldova',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands',
    'Netherlands Antilles',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'North Korea (DPR)',
    'Northern Mariana Islands',
    'Norway',
    'Oman',
    'Other',
    'Pakistan',
    'Palau',
    'Palestinian Terr., Occupied',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Reunion',
    'Romania',
    'Russian Federation',
    'Rwanda',
    'Saint Helena',
    'Saint Kitts And Nevis',
    'Saint Lucia',
    'Saint Pierre And Miquelon',
    'Samoa',
    'San Marino',
    'Sao Tome And Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Korea',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'St. Vincent & The Grenadines',
    'Sth Georgia & Sth Sndwch Isl',
    'Sudan',
    'Suriname',
    'Svalbard And Jan Mayen',
    'Swaziland',
    'Sweden',
    'Switzerland',
    'Syria',
    'Taiwan',
    'Tajikistan',
    'Tanzania',
    'Thailand',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad And Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks And Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',

    'United States',
    'Unspecified',
    'Unspec Australasian Country',
    'Unspec Caribbean Country',
    'Unspec Cent. American Cntry',
    'Unspec Cent. Asian Cntry',
    'Unspec East African Country',
    'Unspec East Europe. Country',
    'Unspec Far Eastern Country',
    'Unspec Indian Ocean Island',
    'Unspec Melanesian Country',
    'Unspec Micronesian Country',
    'Unspec Middle Eastern Cntry',
    'Unspec Near Eastern Country',
    'Unspec North African Country',
    'Unspec North American Cntry',
    'Unspec North Atlantic Island',
    'Unspec North European Cntry',
    'Unspec Polynesian Country',
    'Unspec South African Country',
    'Unspec South American Cntry',
    'Unspec South Asian Country',
    'Unspec South Atlantic Island',
    'Unspec South European Cntry',
    'Unspec Southeast Asian Cntry',
    'Unspec Southern Ocean Isl',
    'Unspec West African Country',
    'Unspec West European Cntry',
    'Uruguay',
    'USA Minor Outlying Islands',
    'Uzbekistan',
    'Vanuatu',
    'Vatican City',
    'Venezuela',
    'Vietnam',
    'Virgin Islands (British)',
    'Virgin Islands (U.S.)',
    'Wallis And Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe'
]

export const MEDICALCONDITIONS = [
    'Abnormal heart rate or arrhythmias or heart surgery',
    'Amnesia',
    'Bi-polar disorder',
    'Brain haemorrhage or aneurysm',
    'Brain tumour',
    'Cardiomyopathy',
    'Deliberate self-harm or suicidal intent or suicide attempt',
    'Delusional disorder',
    'Dementia',
    'Diabetes - Type 1',
    'Diabetes - Type 2',
    'Epilepsy',
    'Heart failure',
    'Medically diagnosed depression',
    'Obsessive compulsive disorder (OCD)',
    'Pacemaker or implanted defibrillator',
    'Personality disorder (that has required professional input)',
    'Post-traumatic stress disorder (PTSD)',
    'Psychosis',
    'Schizophrenia',
    'Sleep apnoea',
    'Other condition causing fits, seizures, blackouts and faints (sudden loss of consciousness)',
    'None of the above'
]

export const COURTOUTCOMES = [
    'Not guilty/Not proven',
    'Absolute discharge',
    'Conditional discharge',
    'Banned from driving',
    'Mistrial',
    'Fixed Penalty/Fine',
    'Referral Order',
    'Custodial prison sentence',
    'Suspended prison sentence',
    'Community sentence or youth rehabilitation order',
    'Caution',
    'NFA'
]

export const ARRESTOUTCOMES = [
    'Released without charge/NFA',
    'Cautioned',
    'Charged with offence',
    'Bailed under investigation',
    'Fixed penalty notice',
    'Other'
]

export const MILITARYCOURTOUTCOMES = [
    'Not guilty',
    'Dismissal from service',
    'Reduced in rank',
    'Reprimand',
    'Severe reprimand',
    'Detention',
    'Admonished'
]

export const CLEARANCELEVELS = ['CTC', 'SC', 'DV', 'EDV', 'Other', "Don't know"]

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { QueryClientProvider, QueryClient, QueryCache } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import './index.scss'
import Router from './components/Router/Router'
import { isDev } from './utils/env-utils'
import { UiProvider } from './context/UiContext'
import { AuthProvider } from './auth/AuthContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: console.error
    }),
    defaultOptions: { queries: { staleTime: 1000 } }
})

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <UiProvider>
                    <BrowserRouter>
                        <Router />
                    </BrowserRouter>
                </UiProvider>
                {!!isDev && <ReactQueryDevtools initialIsOpen={false} />}
            </AuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
)

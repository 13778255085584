import { FormProvider, useForm } from 'react-hook-form'
import { useState } from 'react'
import FileUpload from '../../components/form/FileUpload'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { P } from '../../components/ui'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import Textarea from '../../components/form/Textarea'
import Fieldset from '../../components/form/Fieldset'

function DocumentUploadPage() {
    const methods = useForm()
    const [submitted, setSubmitted] = useState<boolean>(false)

    return (
        <PageTemplate>
            <div className='govuk-grid-row'>
                <div className='govuk-grid-column-full'>
                    {submitted ? (
                        <>
                            <div className='govuk-panel govuk-panel--confirmation'>
                                <h1 className='govuk-panel__title'>Your document has been submitted</h1>
                            </div>

                            <P>An email confirmation has been sent to user.name@youremail.com</P>

                            <h2 className='govuk-heading-m'>What happens next</h2>
                            <P>
                                Your documents are now being checked. If we need any more information, we will contact
                                you.
                            </P>

                            <div className='govuk-!-margin-top-9'>
                                <button
                                    onClick={() => setSubmitted(false)}
                                    type='button'
                                    className='govuk-button'
                                    data-module='govuk-button'
                                >
                                    Back to homepage
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className='govuk-heading-xl'>Document upload</h1>

                            <ErrorSummary errors={methods.formState.errors} idPrefix='field-' />

                            <P>Please review, complete and upload the following documentation:</P>

                            <P>
                                <a href='../../pdf/Merged.pdf' download='Medical consent form' className='govuk-link'>
                                    Download the medical consent form
                                </a>
                            </P>

                            <FormProvider {...methods}>
                                <form
                                    onSubmit={methods.handleSubmit(() => {
                                        setSubmitted(true)
                                        methods.reset()
                                    })}
                                    className='govuk-!-margin-top-9'
                                >
                                    <Fieldset title='Send a message'>
                                        <Textarea id='field-message' name='message' title='Send a message' />
                                    </Fieldset>

                                    <FileUpload
                                        id='field-upload-document'
                                        name='upload-document'
                                        label='Upload your document'
                                        rules={{ required: true }}
                                        errorMessages={{ required: 'Select a file to upload' }}
                                    />

                                    <div className='govuk-!-margin-top-9'>
                                        <button type='submit' className='govuk-button' data-module='govuk-button'>
                                            Send
                                        </button>
                                    </div>
                                </form>
                            </FormProvider>
                        </>
                    )}
                </div>
            </div>
        </PageTemplate>
    )
}

export default DocumentUploadPage

import { useState, useEffect } from 'react'
import { data } from './data'
import PageTemplate from '../../components/PageTemplate/PageTemplate'

function DocumentDownloadPage() {
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const itemsPerPage = 10

    // Filter search
    const filteredData = data.filter(entry => entry['case-id'].toLowerCase().includes(searchTerm.toLowerCase()))

    // Handle pagination
    const totalItems = filteredData.length
    const totalPages = Math.ceil(totalItems / itemsPerPage)
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem)

    const goToPreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1)
    }

    const goToNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1)
    }

    const goToPage = (pageNumber: number) => setCurrentPage(pageNumber)

    // Reset to page 1 if someone searches
    useEffect(() => {
        setCurrentPage(1)
    }, [searchTerm])

    // Styling
    const paginationNumber = {
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#1d70b8',
        background: 'none',
        border: 'none',
        alignItems: 'center',
        margin: 'none',
        padding: 'none'
    }

    const paginationNumberCurrent = {
        color: 'white',
        background: '#1d70b8',
        border: 'none'
    }

    const previousOrNext = {
        cursor: 'pointer',
        background: 'none',
        border: 'none',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#1d70b8'
    }

    return (
        <PageTemplate>
            <div className='govuk-grid-row'>
                <div className='govuk-grid-column-full'>
                    <h1 className='govuk-heading-xl'>Document download</h1>

                    <label className='govuk-label govuk-label--m' htmlFor='case-id-search'>
                        Search by Case ID:
                    </label>
                    <input
                        className='govuk-input govuk-!-margin-bottom-7 govuk-input--width-10'
                        id='case-id-search'
                        name='caseIDSearch'
                        type='text'
                        onChange={e => setSearchTerm(e.target.value)}
                    />

                    <table className='govuk-table govuk-table--small-text-until-tablet'>
                        <thead className='govuk-table__head'>
                            <tr className='govuk-table__row'>
                                <th scope='col' className='govuk-table__header'>
                                    Case ID
                                </th>
                                <th scope='col' className='govuk-table__header'>
                                    Date
                                </th>
                                <th scope='col' className='govuk-table__header'>
                                    Document
                                </th>
                            </tr>
                        </thead>

                        {currentData.map(entry => (
                            <tbody key={entry['case-id']} className='govuk-table__body'>
                                <tr className='govuk-table__row'>
                                    <td className='govuk-table__cell'>{entry['case-id']}</td>
                                    <td className='govuk-table__cell'>{entry.date}</td>
                                    <td className='govuk-table__cell'>
                                        <a href={entry.document} download={entry.document} className='govuk-link'>
                                            {entry.document}
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>

                    <nav className='govuk-pagination govuk-!-margin-top-5' aria-label='Pagination'>
                        {/* Previous */}
                        {currentPage !== 1 && (
                            <div className='govuk-pagination__prev'>
                                <button
                                    type='button'
                                    className='govuk-link govuk-pagination__link'
                                    onClick={goToPreviousPage}
                                    aria-label='Previous page'
                                    style={previousOrNext}
                                >
                                    <svg
                                        className='govuk-pagination__icon govuk-pagination__icon--prev'
                                        height='13'
                                        width='15'
                                        aria-hidden='true'
                                        focusable='false'
                                        viewBox='0 0 15 13'
                                    >
                                        <path d='m6.5938-0.0078125-6.7266 6.7266 6.7441 6.4062 1.377-1.449-4.1856-3.9768h12.896v-2h-12.984l4.2931-4.293-1.414-1.414z' />
                                    </svg>
                                    <span className='govuk-pagination__link-title'>Previous</span>
                                </button>
                            </div>
                        )}

                        {/* Numbers */}
                        <ul className='govuk-pagination__list'>
                            {Array.from({ length: totalPages }, (_, index) => (
                                <button
                                    type='button'
                                    key={index + 1}
                                    className='govuk-pagination__item'
                                    style={currentPage === index + 1 ? paginationNumberCurrent : paginationNumber}
                                    onClick={() => goToPage(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </ul>

                        {/* Next */}
                        {currentPage !== totalPages && (
                            <div className='govuk-pagination__next'>
                                <button
                                    type='button'
                                    className='govuk-link govuk-pagination__link'
                                    onClick={goToNextPage}
                                    aria-label='Next page'
                                    style={previousOrNext}
                                >
                                    <span className='govuk-pagination__link-title'>Next</span>
                                    <svg
                                        className='govuk-pagination__icon govuk-pagination__icon--next'
                                        height='13'
                                        width='15'
                                        aria-hidden='true'
                                        focusable='false'
                                        viewBox='0 0 15 13'
                                    >
                                        <path d='m8.107-0.0078125-1.4136 1.414 4.2926 4.293h-12.986v2h12.896l-4.1855 3.9766 1.377 1.4492 6.7441-6.4062-6.7246-6.7266z' />
                                    </svg>
                                </button>
                            </div>
                        )}
                    </nav>
                </div>
            </div>
        </PageTemplate>
    )
}

export default DocumentDownloadPage

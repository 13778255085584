import { Page } from '../types'
import { COUNTRIES } from '../defaultContent'

const identity: Page[] = [
    {
        pageType: 'question',
        title: 'Identity',
        name: 'identity',
        questions: [
            {
                name: 'title',
                questionType: 'textInput',
                title: 'Title',
                rules: {
                    required: false
                }
            },
            {
                questionType: 'textInput',
                title: 'First name',
                name: 'first-name',
                hint: 'As shown on official documentation e.g. passport or birth/adoption certificate',
                rules: { required: true },
                errorMessages: { required: 'You must provide your first name' }
            },
            {
                questionType: 'radio',
                title: 'Do you have any middle names?',
                name: 'middle-names-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textInput',
                                title: 'Middle name(s)',
                                name: 'middle-names-input',
                                hint: 'Include all your middle names',
                                rules: {
                                    required: true
                                },
                                errorMessages: { required: 'You must provide your middle name' }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: { required: 'You must provide if you have a middle name' }
            },
            {
                questionType: 'textInput',
                title: 'Last name',
                name: 'last-name',
                rules: { required: true },
                errorMessages: { required: 'You must provide your last name' }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Previous names',
        name: 'previous-names',
        questions: [
            {
                questionType: 'radio',
                title: 'Has your name changed since birth?',
                name: 'name-change-radio',
                hint: 'This includes first, middle and last names',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'radio',
                                title: 'Are you adding a first, middle or last name',
                                name: 'name-type-radio',
                                hint: 'Select one option, you can add more later.',
                                options: ['First name', 'Middle name', 'Last name'],
                                rules: { required: true },
                                errorMessages: { required: 'You must select the type of name you are adding' }
                            },
                            {
                                questionType: 'textInput',
                                title: 'Previous name',
                                name: 'previous-name',
                                rules: { required: true },
                                errorMessages: { required: 'You must provide your previous name' }
                            },
                            {
                                questionType: 'select',
                                name: 'previous-name-select',
                                title: 'Why did you change your name?',
                                options: [
                                    '',
                                    'Marriage',
                                    'Civil partnership',
                                    'Divorce or Dissolution',
                                    'Deed Poll',
                                    'Other'
                                ],
                                rules: { required: true },
                                errorMessages: { required: 'You must select the reason for your name change' }
                            },
                            {
                                questionType: 'textarea',
                                title: 'Please provide details',
                                name: 'previous-name-text-area',
                                rules: {
                                    required: true
                                }
                            },
                            {
                                questionType: 'date',
                                title: 'Date you changed your name',
                                name: 'date-changed-name',
                                rules: {
                                    required: true,
                                    isBeforeNow: 'enabled'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: { required: 'You must answer if your name has changed since birth' }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Birth details',
        name: 'birth-details',
        questions: [
            {
                questionType: 'date',
                title: 'Date of birth',
                name: 'date-of-birth',
                hint: 'For example 30/06/1994',
                rules: {
                    required: true,
                    isBeforeNow: 'enabled',
                    isYearsOld: '16'
                }
            },
            {
                questionType: 'autoComplete',
                title: 'Country of birth',
                name: 'country-of-birth',
                items: COUNTRIES,
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'Country name',
                name: 'country-name',
                hint: 'If the country no longer exists then enter what it is called today',
                rules: {
                    required: false
                }
            },
            {
                questionType: 'textInput',
                title: 'Town of birth',
                name: 'town-of-birth',
                rules: {
                    required: true
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'National Insurance/Passport',
        name: 'national-insurance-passport',
        questions: [
            {
                questionType: 'radio',
                title: 'Do you have a National Insurance number?',
                name: 'national-insurance-number-radio',
                hint: `It's on your National Insurance card, benefit letter, payslip or P60. Please enter the number in the correct format AB112233C, with capital letters and no spaces`,
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textInput',
                                title: 'National Insurance number',
                                name: 'national-insurance-number',
                                rules: { required: true, pattern: '^(GYgy[0-9]{6}|[A-Za-z][A-Za-z][0-9]{6}[A-Da-d])$' },
                                errorMessages: { required: 'You must enter your National Insurance number' }
                            }
                        ]
                    },
                    {
                        option: 'No',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: `Why don't you have a National Insurance number`,
                                name: 'no-national-insurance-number',
                                rules: { required: true },
                                errorMessages: {
                                    required:
                                        'You must enter the details explaining why you do not have a National Insurance number'
                                }
                            }
                        ]
                    }
                ],
                rules: { required: true },
                errorMessages: { required: 'You must select if you have a national insurance number' }
            },
            {
                questionType: 'radio',
                title: 'Do you have a current UK passport?',
                name: 'uk-passport-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textInput',
                                title: 'Please provide your passport number',
                                name: 'uk-passport-number',
                                rules: { required: true, pattern: '([0-9]{9})' },
                                errorMessages: { required: 'You must provide your passport number' }
                            }
                        ]
                    },
                    'No'
                ],
                rules: { required: true },
                errorMessages: { required: 'You must select if you have a current UK passport' }
            }
        ]
    }
]
export default identity

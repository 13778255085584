/* eslint-disable arrow-body-style */
import React, { ComponentPropsWithoutRef, forwardRef } from 'react'

type Props = {
    name: string
    numeric?: boolean
    width?: 'full' | 'three-quarters' | 'two-thirds' | 'one-half' | 'one-third' | 'one-quarter'
} & ComponentPropsWithoutRef<'input'>

const Input = forwardRef<HTMLInputElement, Props>(({ numeric, width = 'three-quarters', ...props }, ref) => {
    return (
        <input
            {...props}
            type={props.type || 'text'}
            className={`govuk-input govuk-!-width-${width}`}
            ref={ref}
            inputMode={numeric ? 'numeric' : 'text'}
            data-testid={props.name}
            onFocus={e => e.target.addEventListener('wheel', e => e.preventDefault(), { passive: false })}
        />
    )
})
Input.displayName = 'Input'

export default Input

import { CLEARANCELEVELS } from '../defaultContent'
import { Page } from '../types'

const previousSecurityClearances: Page[] = [
    {
        pageType: 'information',
        title: 'Before you start',
        name: 'before-you-start',
        description:
            "Previous UK security clearances you've held or applied for, including the level of clearance and organisation. \n\n If your security clearance has been refused, suspended or withdrawn, or you've had restrictions on your security clearance, then provide details."
    },
    {
        pageType: 'question',
        title: 'UK security clearance',
        name: 'previous-security-clearance-page',
        questions: [
            {
                name: 'previous-security-clearance-radio',
                questionType: 'radio',
                title: 'Have you ever held or applied for UK security clearance?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                name: 'clearance-level-select',
                                questionType: 'radio',
                                title: 'What was the most recent level of clearance?',
                                hintOption: 'Select clearance level',
                                options: CLEARANCELEVELS,
                                rules: { required: true },
                                errorMessages: {
                                    required: 'You must select your most recent level of clearance'
                                }
                            },
                            {
                                name: 'further-details-textarea',
                                questionType: 'textarea',
                                title: "Provide details if you have selected 'Other'"
                            },
                            {
                                name: 'clearance-org-text-input',
                                questionType: 'textInput',
                                hint: 'e.g. Border Force, Home Office',
                                title: 'Which organisation held your clearance?',
                                rules: { required: true },
                                errorMessages: {
                                    required: 'You must input which organisation held your clearance'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: { required: true },
                errorMessages: {
                    required: 'You must answer whether or not you have applied for UK security clearance'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Clearance removal',
        name: 'clearance-removal-page',
        disabled: [
            {
                field: 'previous-security-clearance-radio',
                condition: 'equals',
                value: 'No'
            }
        ],
        questions: [
            {
                name: 'clearance-removal-radio',
                questionType: 'radio',
                title: 'Has your security clearance ever been refused, suspended or withdrawn?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details',
                                name: 'clearance-removal-further-details',
                                rules: {
                                    required: true
                                },
                                errorMessages: { required: 'You must provide dates and details' }
                            }
                        ]
                    },
                    'No'
                ],
                rules: { required: true },
                errorMessages: {
                    required: 'You must select whether your clearance has ever been refused, suspended or withdrawn'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Clearance restrictions',
        name: 'clearance-restrictions-page',
        disabled: [
            {
                field: 'previous-security-clearance-radio',
                condition: 'equals',
                value: 'No'
            }
        ],
        questions: [
            {
                name: 'clearance-restrictions-radio',
                questionType: 'radio',
                title: 'Are you aware of any restrictions on your security clearance?',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details',
                                name: 'clearance-restrictions-details',
                                rules: {
                                    required: true
                                },
                                errorMessages: { required: 'You must provide dates and details' }
                            }
                        ]
                    },
                    'No'
                ],
                rules: { required: true },
                errorMessages: { required: 'You must select whether you are aware of any restrictions' }
            }
        ]
    }
]

export default previousSecurityClearances

import React from 'react'
import parse from 'html-react-parser'
import defaultContent from '../../content'
import { P } from '../ui'

type Props = {
    header?: string
    content: string
}

function Notification({ header, content }: Props) {
    const id = React.useId()
    return (
        <div className='govuk-notification-banner' role='region' aria-labelledby={id} data-testid='notification-banner'>
            <div className='govuk-notification-banner__header'>
                <h2 className='govuk-notification-banner__title' id={id}>
                    {header || defaultContent.noticeHeaderDefault}
                </h2>
            </div>
            <div className='govuk-notification-banner__content'>
                <P className='govuk-notification-banner__heading'>{parse(content)}</P>
            </div>
        </div>
    )
}

export default Notification

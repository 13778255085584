import { ComponentPropsWithoutRef, useEffect } from 'react'
import { FieldError, useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import { validateField } from '../../utils/form-utils'
import { ErrorMessages, Rules, SelectOption } from '../../config/types'
import Details from './Details'

type Props = {
    name: string
    options: SelectOption[]
    hintOption?: string
    numeric?: boolean
    error?: FieldError
    disabled?: boolean
    rules?: Rules
    title: string
    errorMessages?: ErrorMessages
    detailsHeading?: string
    detailsContent?: string
} & ComponentPropsWithoutRef<'select'>

export default function SelectInputField(props: Props) {
    const {
        name,
        rules,
        options,
        hintOption,
        disabled,
        defaultValue,
        title,
        errorMessages,
        detailsHeading,
        detailsContent
    } = props
    const { register, getFieldState, getValues, setValue } = useFormContext()

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, defaultValue ?? '')
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, rules || {}, value, errorMessages)
    })
    const { error } = getFieldState(name)

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}
            <select
                {...props}
                {...hooksFormRegister}
                className={`govuk-select ${error && 'govuk-select--error'}`}
                disabled={disabled ?? false}
            >
                {hintOption && <option value=''>{hintOption}</option>}
                {options.map(option => (
                    <option value={String(option)} id={`select-${option}`} key={`select-${option}`}>
                        {String(option)}
                    </option>
                ))}
            </select>
        </>
    )
}

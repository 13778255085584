import { ChangeEvent } from 'react'
import { handleKeyPress, handlePaste } from './utils'

interface YearInputProps {
    testId?: string
    value?: string // A string of yyyy
    disabled?: boolean
    label?: string
    anchorId?: string
    onChange?(value: string): void
}

export default function YearInput({ testId, value = '', disabled = false, label, anchorId, onChange }: YearInputProps) {
    const YEAR_LENGTH = 4

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (onChange && event.target.value.length <= YEAR_LENGTH) {
            onChange(`${event.target.value}`)
        }
    }

    const handleBlur = () => {
        if (value.length > 0 && value.length < YEAR_LENGTH) {
            const paddedYear = value.padStart(YEAR_LENGTH, '0')
            if (onChange) {
                onChange(`${paddedYear}`)
            }
        }
    }

    return (
        <div className='govuk-date-input__item'>
            <div className='govuk-form-group'>
                <label className='govuk-label govuk-date-input__label' htmlFor={anchorId}>
                    {label}
                </label>
                <input
                    className='govuk-input govuk-date-input__input govuk-input--width-4'
                    id={anchorId}
                    data-testid={testId}
                    type='number'
                    size={YEAR_LENGTH}
                    value={value}
                    onChange={handleChange}
                    onBlur={() => handleBlur()}
                    disabled={disabled ?? false}
                    onKeyDown={e => handleKeyPress(e)}
                    onPaste={e => handlePaste(e)}
                    onFocus={e => e.target.addEventListener('wheel', e => e.preventDefault(), { passive: false })}
                />
            </div>
        </div>
    )
}

// React-Query keys
export enum Query {
    FormData = 'formData',
    Token = 'token',
    PostcodeLookup = 'PostcodLookup'
}

export type Notification = { header: string; content: string }

export enum Status {
    NotStarted = 'Not Started',
    InProgress = 'In Progress',
    Completed = 'Completed'
}

export enum Button {
    SaveUnfinished = 'save-unfinished',
    Save = 'save'
}

export const LastLocation = 'lastLocation'

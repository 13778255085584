import { ComponentPropsWithRef, forwardRef } from 'react'

type Props = ComponentPropsWithRef<'input'>

const Checkbox = forwardRef<HTMLInputElement, Props>((props, ref) => (
    <input className='govuk-checkboxes__input' type='checkbox' {...props} ref={ref} />
))

Checkbox.displayName = 'Checkbox'

export default Checkbox

/* eslint-disable arrow-body-style */
import { ComponentPropsWithoutRef, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import { containsReservedCharacters, getInputRules, validateField } from '../../utils/form-utils'
import { textinputMaxLength } from '../../constants'
import Input from './TextInput'
import { ErrorMessages, Rules } from '../../config/types'
import Details from './Details'

type Props = {
    name: string
    title: string
    width?: 'full' | 'three-quarters' | 'two-thirds' | 'one-half' | 'one-third' | 'one-quarter'
    rules?: Rules
    errorMessages?: ErrorMessages
    detailsHeading?: string
    detailsContent?: string
    dynamicText?: string
    formAnswers?: Record<string, string>
} & ComponentPropsWithoutRef<'input'>

const TextInputField = (props: Props) => {
    const { name, title, rules, disabled, defaultValue, errorMessages, detailsHeading, detailsContent } = props
    const { register, getFieldState, getValues, setValue } = useFormContext()
    let type = 'text'
    if (rules?.number) type = 'number'

    const inputRules = useMemo(() => ({ ...getInputRules(rules ?? {}, title, textinputMaxLength) }), [rules, title])

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, defaultValue ?? '')
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, inputRules, value, errorMessages)
    })
    const { error } = getFieldState(name)

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <Input
                {...props}
                {...hooksFormRegister}
                disabled={disabled ?? false}
                onKeyDown={e => {
                    // The e.key values are strings for control characters such as 'Backspace'
                    // All non control characters will have a single character length
                    if (e.key.length === 1 && containsReservedCharacters(e.key, inputRules.reservedCharacters))
                        e.preventDefault()
                }}
                onPaste={e => {
                    const clipboardData = e.clipboardData.getData('text')
                    if (containsReservedCharacters(clipboardData, inputRules.reservedCharacters)) e.preventDefault()
                }}
                numeric={!!rules?.number}
                type={type}
            />
        </>
    )
}
TextInputField.displayName = 'TextInputField'

export default TextInputField

import { ComponentPropsWithRef, forwardRef } from 'react'

type Props = ComponentPropsWithRef<'input'>

const Radio = forwardRef<HTMLInputElement, Props>((props, ref) => (
    <input className='govuk-radios__input' type='radio' {...props} ref={ref} />
))

Radio.displayName = 'Radio'

export default Radio

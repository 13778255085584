import { Page } from '../types'

const contactDetails: Page[] = [
    {
        pageType: 'information',
        title: 'Before you start',
        name: 'before-you-start',
        description:
            'In this section you are asked to provide contact details.  It is possible you will be contacted during the vetting process so it is important that your contact details are correct. \n\n Enter all of your current phone numbers and email addresses.  The phone numbers you provide must be direct numbers where we can contact you, not a switchboard or shared number.  You can enter the same email address you used when registering as well as different ones if you have alternative email addresses. '
    },
    {
        pageType: 'list',
        title: 'Add phone numbers',
        answerTitle: 'Phone number',
        name: 'phone-number-list',
        description:
            'Enter all of your current phone numbers. You must enter at least one phone number and one email address. <br><br> The phone numbers you provide must be direct numbers where we can contact you, not a switchboard or shared number. <br><br> You can enter the same email address you used when registering as well as different ones if you have alternative email addresses.',
        questions: [
            {
                name: 'phone-number-type',
                questionType: 'select',
                title: 'Phone number type',
                hintOption: 'Please Select',
                options: ['Home', 'Work', 'Mobile'],
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'Phone number',
                name: 'phone-number',
                detailsHeading: 'More information',
                detailsContent:
                    'For example for UK phone numbers, 07700900789 for mobile or 01632960789 for landline. For International numbers include the country code for example +15551234567',
                rules: {
                    required: true,
                    telephone: true
                }
            },
            {
                name: 'phone-number-primary',
                questionType: 'radio',
                title: 'Is this the number we should use to contact you?',
                options: ['Yes', 'No'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether this is the number we should use to contact you'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Email address',
        name: 'email',
        description:
            'Enter all of your current email addresses.  You must enter at least one email address. You can enter the same email address you used  when registering as well as different ones if you have alternative email addresses. ',
        questions: [
            {
                name: 'email-address-type',
                questionType: 'select',
                title: 'Email address type',
                hintOption: 'Please Select',
                options: ['Home', 'Work', 'Other'],
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'Email address',
                name: 'email-address',
                hint: `Please enter all of your current Email addresses.`,
                rules: {
                    required: true,
                    email: true
                }
            },
            {
                name: 'email-address-primary',
                questionType: 'radio',
                title: 'Is this the email address we should use to contact you?',
                options: ['Yes', 'No'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether this is the email address we should use to contact you'
                }
            }
        ]
    }
]
export default contactDetails

import { isObject } from './array-utils'
import type { FetchFormResponse } from '../service/types'
import { Status } from '../types'
import { dateIsFullyPopulated } from '../components/form/DateInputField/utils'
import { FormConfig, Question, Section, Subsection } from '../config/types'

export const getEnabledSubsections = (
    formConfig: FormConfig,
    fetchFormResponse: FetchFormResponse = {
        answers: {}
    }
): Record<string, Subsection> => {
    // returns a Record<string, Subsection> map of subsections
    // Only really filters the pages, not the subsections.
    // Looks like it would include a subsection whose pages are all disabled.

    // Do a deep clone of the config so we can't corrupt the config
    const clonedConfig: FormConfig = JSON.parse(JSON.stringify(formConfig))
    const { answers, drafts } = fetchFormResponse
    const draftPages: string[] = drafts ? Object.values(drafts).flat() : []
    const draftQuestions: string[] = []

    const filtered: Record<string, Subsection> = {}

    clonedConfig.sections.forEach(section => {
        section.subsections.forEach(subsection => {
            filtered[subsection.slug] = { ...subsection, pages: [] }

            if (drafts) {
                subsection.pages.forEach(page => {
                    if (draftPages.includes(page.name) && 'questions' in page) {
                        page.questions?.forEach(question => draftQuestions.push(question.name))
                    }
                })
            }

            // filter out disabled pages from the subsection
            const enabledPages = subsection.pages.filter(
                ({ disabled }) =>
                    !disabled?.some(
                        ({ field, value }) =>
                            // disable page when answer meets disabled condition OR is an unanswered draft
                            answers[field] === value || (draftQuestions.includes(field) && !answers[field])
                    )
            )

            if (enabledPages.length > 0) {
                filtered[subsection.slug].pages = enabledPages
            }
        })
    })

    return filtered
}

export const isPageCompleted = (questions: Question[], answers: FetchFormResponse['answers']): boolean =>
    !!questions?.find(q => q.name in answers)

export const isListCompleted = (questions: Question[], answers: FetchFormResponse['answers']): boolean => {
    let completed = true

    questions.forEach(question => {
        if (!Object.keys(answers).find(q => q.endsWith(question.name))) {
            completed = false
        }
    })

    return completed
}

export const getOverviewStatus = (
    sections: Section[],
    subsectionMap: Record<string, Subsection>,
    formValues: FetchFormResponse = { answers: {} }
): Record<Subsection['slug'], Status> => {
    const { answers, drafts } = formValues
    if (!answers) return {}

    return sections.reduce((totalSections, current) => {
        const newStatus = current.subsections.reduce((totalSubsections, { slug }) => {
            const questionPages = (subsectionMap[slug]?.pages || [])?.filter(page => page.pageType === 'question')
            const listPages = (subsectionMap[slug]?.pages || [])?.filter(page => page.pageType === 'list')
            const subsectionDrafts = drafts?.[slug]?.filter(page => questionPages.find(({ name }) => name === page))
            const isDraft = !!subsectionDrafts?.length
            let subsectionStatus = isDraft ? Status.InProgress : Status.NotStarted

            if (!isDraft) {
                // if we don't already know this subsection is in draft mode, check if all or some pages have been completed
                if (
                    questionPages.every(
                        page => page.pageType === 'question' && isPageCompleted(page.questions || [], answers)
                    ) &&
                    listPages.every(page => page.pageType === 'list' && isListCompleted(page.questions || [], answers))
                ) {
                    subsectionStatus = Status.Completed
                } else if (
                    questionPages.some(
                        page => page.pageType === 'question' && isPageCompleted(page.questions || [], answers)
                    ) ||
                    listPages.some(page => page.pageType === 'list' && isListCompleted(page.questions || [], answers))
                ) {
                    subsectionStatus = Status.InProgress
                }
            }

            return { ...totalSubsections, [slug]: subsectionStatus }
        }, {})

        return { ...totalSections, ...newStatus }
    }, {})
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const extractConditionalQuestions = (questions: any[]) => {
    let extractedQuestions: Question[] = []
    questions.forEach(question => {
        extractedQuestions.push(question)

        // extract conditional questions from options
        if (question.options) {
            question.options.forEach((option: { questions: Question[] }) => {
                if (isObject(option) && 'questions' in option && option?.questions) {
                    extractedQuestions = extractedQuestions.concat(extractConditionalQuestions(option.questions))
                }
            })
        }
    })
    return extractedQuestions
}

export const getSelectedConditionalQuestions = (question: Question, answer: string | string[]) => {
    const conditionalQuestions: Question[] = []
    if (question.options) {
        // get conditional questions which have been selected
        question.options.forEach(option => {
            if (isObject(option) && 'questions' in option && option.questions) {
                if (option.option === answer || answer?.includes(option.option)) {
                    conditionalQuestions.push(...option.questions)
                }
            }
        })
    }
    return conditionalQuestions
}

export const renderAnswer = (question: Question, value: unknown): string | string[] => {
    // exit if value is empty
    if (!value) return ''

    // plain string values
    if (typeof value === 'string') {
        // date fields
        if (question.questionType === 'date' || question.questionType === 'shortDate') {
            let returnVal = ''
            if (dateIsFullyPopulated(value)) {
                const dateValues = value?.split('-')

                try {
                    const monthDesc = Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(dateValues[1] ?? ''))
                    returnVal = `${dateValues[2] ?? ''} ${monthDesc} ${dateValues[0] ?? ''}`
                } catch (err) {
                    // fallback on invalid date format
                    return ''
                }
            }

            return returnVal.trim()
        }

        // all other fields e.g. basic textfields
        return value
    }

    if (Array.isArray(value)) return value.filter(answer => typeof answer === 'string' && !!answer)

    if (!!value && typeof value === 'object') {
        // Address formatting
        if ('postcode' in value && 'line1' in value) {
            const addressOrder = ['line1', 'line2', 'town', 'county', 'postcode']

            return addressOrder
                .map(field => String(value[field as keyof typeof value] ?? ''))
                .filter(answer => !!answer)
        }

        return Object.keys(value)
            .map(key => {
                const result = value[key as keyof typeof value]
                return result ? String(result).trim() : ''
            })
            .filter(answer => !!answer)
    }

    // fallback
    return ''
}

// sets cookie name, value and expiration date
export function setCookie(name: string, value: string, days: number) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString() // splits the days into milliseconds
    document.cookie = `${name}=${value}; expires=${expires}; path=/`
}

// gets the value of a cookie by name
export function getCookie(name: string): string | undefined {
    const cookieString = document.cookie.split('; ').find(row => row.startsWith(`${name}=`))
    return cookieString ? cookieString.split('=')[1] : undefined
}

import { useEffect, useState } from 'react'
import QRCode from 'qrcode.react'
import { useForm } from 'react-hook-form'
import { useAuthContext } from '../../../auth/AuthContext'
import ErrorSummary from '../../ErrorSummary/ErrorSummary'
import { validateField } from '../../../utils/form-utils'
import content from '../../../content'
import ErrorMessage from '../ErrorMessage'
import Input from '../TextInput'

const SetupAppMFAForm = () => {
    const { confirmSignIn, totpSetupUri, totpSharedSecret } = useAuthContext()
    const [disabled, setDisabled] = useState(true)

    // MFA Compulsory
    useEffect(() => {
        // If the Cognito userpool has MFA compulsory, the setup details will
        // be returned by the previous step.
        if (totpSetupUri && totpSharedSecret) {
            setDisabled(false)
        }
    }, [totpSetupUri, totpSharedSecret])

    const {
        formState: { errors },
        handleSubmit,
        register,
        getFieldState,
        getValues,
        setError,
        setValue
    } = useForm<{ totp: string }>({
        defaultValues: { totp: '' }
    })

    const totpInput = register('totp', {
        validate: (value: string) =>
            validateField(
                'Sign in code',
                {
                    required: true,
                    maxLength: 6,
                    minLength: 6
                },
                value
            )
    })

    return (
        <>
            <ErrorSummary errors={errors} idPrefix='field-' />

            <div className='govuk-inset-text'>
                Every time you sign in to your account you need to enter a sign in code from an authenticator app.
            </div>
            <h2 className='govuk-heading-m'>To get a sign in code using an authenticator:</h2>
            <div className='govuk-grid-row'>
                <div className='govuk-grid-column-two-thirds'>
                    <ol className='govuk-list govuk-list--number'>
                        <li>
                            Download an authenticator app from the app store on your mobile device (we recommend Google
                            Authenticator or Microsoft Authenticator)
                        </li>
                        <li>Open the app, add an account and scan the QR code image on this page</li>
                        <li>
                            Enter the 6 digit code from the authenticator app below (the code will update every 30
                            seconds)
                        </li>
                    </ol>
                    <details className='govuk-details'>
                        <summary className='govuk-details__summary'>
                            <span className='govuk-details__summary-text'>How do I manually enter the QR code?</span>
                        </summary>
                        <div className='govuk-details__text'>
                            Manually enter this code into your Authenticator app: <em>{totpSharedSecret ?? ''}</em>
                        </div>
                    </details>
                </div>
                <div className='govuk-grid-column-one-third'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {totpSetupUri && <QRCode data-testid='qr-code' width={200} value={totpSetupUri ?? ''} />}
                    </div>
                </div>
            </div>

            <form
                onSubmit={handleSubmit(async data => {
                    setDisabled(true)
                    confirmSignIn(data.totp, {
                        onError: () => {
                            setError('totp', {
                                message: String(content.failedMfaToken || '').replace('$LABEL', 'Sign in code')
                            })
                            setValue('totp', '')
                        },
                        onSettled: () => {
                            setDisabled(false)
                        }
                    })
                })}
            >
                <div className='govuk-form-group'>
                    <label htmlFor='field-totp' className='govuk-label'>
                        Sign in code
                    </label>
                    {getFieldState('totp').error && <ErrorMessage error={getFieldState('totp').error ?? {}} />}
                    <Input
                        {...totpInput}
                        id='field-totp'
                        autoComplete='off'
                        type='number'
                        disabled={disabled}
                        onKeyDown={e => {
                            // Limit to integer
                            if (e.key === '.') {
                                e.preventDefault()
                            }
                            // Limit to 6 digits
                            // Any NaN key is a control character that we want to allow
                            if (!Number.isNaN(Number(e.key)) && getValues('totp').length === 6) {
                                e.preventDefault()
                            }
                        }}
                        width='one-quarter'
                    />
                </div>
                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonContinue}
                </button>
            </form>
        </>
    )
}

export default SetupAppMFAForm

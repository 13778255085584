import React from 'react'
import DCFMarkdown from '../DCFMarkdown/DCFMarkdown'
import handleDynamicText from '../../hooks/handleDynamicText'

type Props = {
    children: JSX.Element
    title: string
    hintText?: string
    legendId?: string
    'data-testid'?: string
    formAnswers?: Record<string, string>
}

function Fieldset({ children, title, hintText, legendId, 'data-testid': testId, formAnswers }: Props) {
    return (
        <fieldset className='govuk-fieldset fieldset-container' data-testid={testId ?? ''}>
            <legend className='govuk-fieldset__legend govuk-fieldset__legend--m' id={legendId}>
                <h2 className='govuk-fieldset__heading'>{handleDynamicText(title, formAnswers)}</h2>
            </legend>
            {hintText ? (
                <DCFMarkdown content={hintText} type='hint-text' />
            ) : (
                <hr className='govuk-section-break empty-hint-section-break' />
            )}
            {children}
        </fieldset>
    )
}

export default Fieldset

import { useEffect, useId, useRef, useState } from 'react'
import { Controller, FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import parse from 'html-react-parser'
import ErrorSummary from '../../ErrorSummary/ErrorSummary'
import { Query, Button } from '../../../types'
import content from '../../../content'
import { Props } from './types'
import { FetchFormResponse } from '../../../service/types'
import { P } from '../../ui'
import appConfig from '../../../config'
import Fieldset from '../Fieldset'
import RadioGroup from '../RadioGroup/RadioGroup'
import SelectInputField from '../SelectInputField'
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup'
import TextInputField from '../TextInputField'
import Textarea from '../Textarea'
import PostcodeLookup, { validateManualAddress } from '../PostcodeLookup/PostcodeLookup'
import { ShortDateInputField } from '../DateInputField/ShortDateInputField'
import { DateInputField } from '../DateInputField/DateInputField'
import AutocompleteWrapper from '../Autocomplete/AutocompleteWrapper'
import { Question } from '../../../config/types'
import { renderAnswer } from '../../../utils/config-utils'
import { renderSummaryRow } from '../../../screens/SummaryScreen/SummaryScreen'
import ButtonText from '../../ButtonText'
import { getListAnswers, listHasValue } from '../../../utils/form-utils'
import FileUpload from '../FileUpload'
import { useFormData } from '../../../hooks/useFormData'

enum Screen {
    ListPage = 'ListPage',
    QuestionPage = 'QuestionPage',
    ConfirmDeletePage = 'ConfirmDeletePage'
}

function QuestionPageTemplate({ config, onSave, onSaveUnfinished, errors, disabled, onContinue }: Props) {
    const { data } = useFormData()
    const id = useId()
    const { questions = [], pageType } = config
    const [showSaveButton, setShowSaveButton] = useState<boolean>(true)
    const [screen, setScreen] = useState<string>(pageType === 'list' ? Screen.ListPage : Screen.QuestionPage)
    const [currentQuestions, setCurrentQuestions] = useState<Question[]>(questions)
    const methods = useForm<FetchFormResponse['answers']>({
        ...appConfig.form,
        defaultValues: useQueryClient().getQueryData<FetchFormResponse>([Query.FormData])?.answers
    })

    const titleRef = useRef<HTMLHeadingElement>(null)

    // set props errors on start
    useEffect(() => {
        errors?.forEach(error => methods.setError(error.name, error.error, error.options))
    }, [errors])

    useEffect(() => {
        titleRef.current?.focus()
    }, [config])

    function addQuestion(formValues: FieldValues) {
        // get max id and increment
        const questionIdPrefix =
            Object.keys(formValues)
                .filter(item => item.endsWith(questions[0].name))
                .map(item => Number(item.split('-')[0]))
                .reduce((max, current) => Math.max(max, current), 0) + 1

        setCurrentQuestions(questions.map(q => ({ ...q, name: `${questionIdPrefix}-${q.name}` })))
        setScreen(Screen.QuestionPage)
    }

    useEffect(() => {
        if (pageType === 'list') {
            const formValues = methods.getValues()
            if (!listHasValue(formValues, questions)) {
                addQuestion(formValues)
            }
        }
    }, [])

    async function handleDelete(data: FieldValues, deletedQuestions: Question[]) {
        const deletedQuestionNames = deletedQuestions.map(q => q.name)

        const dataToSave = Object.fromEntries(
            Object.entries(data).map(([question, answer]) =>
                deletedQuestionNames.includes(question) ? [question, ''] : [question, answer]
            )
        )

        onSave(dataToSave, methods.setError)

        methods.reset(dataToSave)

        const updatedFormValues = methods.getValues()
        if (listHasValue(updatedFormValues, questions)) {
            setScreen(Screen.ListPage)
        } else {
            addQuestion(updatedFormValues)
        }
    }

    function renderListSummary(formValues: FieldValues, questions: Question[]) {
        const groupedAnswers = getListAnswers(formValues, questions)
        let displayId = 0

        return (
            <>
                {Object.entries(groupedAnswers).map(([id, answers]) => {
                    const nonEmptyAnswers = answers.filter(answer => answer.answer !== '')

                    if (nonEmptyAnswers.length === 0) {
                        return null
                    }

                    displayId++

                    return (
                        <>
                            <tr className='govuk-table__row' key={displayId}>
                                <th className='govuk-table__cell'>
                                    {'answerTitle' in config ? config.answerTitle : 'Answer'} {displayId}
                                </th>
                                <td
                                    className='govuk-table__cell'
                                    colSpan={3}
                                    style={{ height: '46px', textAlign: 'right' }}
                                >
                                    <ButtonText
                                        type='button'
                                        style={{ fontSize: '19px', marginRight: '20px' }}
                                        onClick={() => {
                                            setCurrentQuestions(questions.map(q => ({ ...q, name: `${id}-${q.name}` })))
                                            setScreen(Screen.QuestionPage)
                                        }}
                                    >
                                        {content.buttonChange}
                                    </ButtonText>
                                    <ButtonText
                                        type='button'
                                        style={{ fontSize: '19px' }}
                                        onClick={() => {
                                            setCurrentQuestions(questions.map(q => ({ ...q, name: `${id}-${q.name}` })))
                                            setScreen(Screen.ConfirmDeletePage)
                                        }}
                                    >
                                        {content.buttonRemove}
                                    </ButtonText>
                                </td>
                            </tr>
                            {answers.map(answer => {
                                const formattedAnswers = renderAnswer(answer.question, answer.answer)
                                const showBullet =
                                    answer.question.questionType === 'checkbox' ? 'govuk-list--bullet' : ''

                                return <>{renderSummaryRow(answer.question, formattedAnswers, showBullet)} </>
                            })}
                        </>
                    )
                })}
            </>
        )
    }

    return (
        <>
            {screen === Screen.ListPage && (
                <FormProvider {...methods}>
                    {(config.description ?? '').split('\n').map(paragraph => (
                        <P key={paragraph.substring(0, 20)}>{parse(paragraph)}</P>
                    ))}
                    <table className='govuk-table'>
                        <tbody className='govuk-table__body'>{renderListSummary(methods.getValues(), questions)}</tbody>
                    </table>

                    <div className='gov-uk-button-group'>
                        <button
                            className='govuk-button save-button'
                            type='button'
                            name='add'
                            onClick={() => addQuestion(methods.getValues())}
                            disabled={disabled}
                        >
                            {content.buttonAddAnother}
                        </button>
                        <button
                            className='govuk-button govuk-button--secondary'
                            type='button'
                            name='continue'
                            onClick={() => {
                                if (onContinue) onContinue()
                            }}
                            disabled={disabled}
                        >
                            {content.buttonContinue}
                        </button>
                    </div>
                </FormProvider>
            )}

            {screen === Screen.ConfirmDeletePage && (
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(data => {
                            handleDelete(data, currentQuestions)
                        })}
                    >
                        <P>{content.confirmRemoval}</P>

                        <table className='govuk-table'>
                            <tbody className='govuk-table__body'>
                                {currentQuestions.map(question => {
                                    const formattedAnswers = renderAnswer(question, methods.getValues(question.name))
                                    const showBullet = question.questionType === 'checkbox' ? 'govuk-list--bullet' : ''

                                    return <>{renderSummaryRow(question, formattedAnswers, showBullet)}</>
                                })}
                            </tbody>
                        </table>

                        <div className='gov-uk-button-group'>
                            <button
                                className='govuk-button govuk-button--warning delete-button'
                                type='submit'
                                name='delete'
                                disabled={disabled}
                            >
                                {content.buttonConfirmRemove}
                            </button>
                            <button
                                className='govuk-button govuk-button--secondary'
                                type='button'
                                name='cancel'
                                onClick={() => setScreen(Screen.ListPage)}
                                disabled={disabled}
                            >
                                {content.buttonCancelRemove}
                            </button>
                        </div>
                    </form>
                </FormProvider>
            )}

            {screen === Screen.QuestionPage && (
                <>
                    <ErrorSummary errors={methods.formState.errors} idPrefix={id} />

                    {(config.description ?? '').split('\n').map(paragraph => (
                        <P key={paragraph.substring(0, 20)}>{parse(paragraph)}</P>
                    ))}

                    <FormProvider {...methods}>
                        <form
                            onSubmit={methods.handleSubmit(data => {
                                onSave(data, methods.setError)
                                if (pageType === 'list') setScreen(Screen.ListPage)
                            })}
                        >
                            {currentQuestions.map(question => {
                                const errorMessage = methods.formState.errors[question.name]
                                const anchorId = `${id}${question.name}`
                                const legendId = `${id}legend`
                                const defaultValue = methods.getValues(question.name)
                                const rules = question?.rules || {}
                                const formAnswers: FetchFormResponse['answers'] = data?.answers || {}

                                return (
                                    <div
                                        className={`govuk-form-group ${errorMessage && 'govuk-form-group--error'}`}
                                        key={question.title}
                                    >
                                        <Fieldset
                                            title={
                                                rules?.required === true
                                                    ? question.title
                                                    : `${question.title} (optional)`
                                            }
                                            hintText={question.hint}
                                            legendId={legendId}
                                            data-testid={`question-fieldset-${question.name}`}
                                            formAnswers={formAnswers}
                                        >
                                            <>
                                                {question.questionType === 'radio' && (
                                                    <RadioGroup
                                                        anchorId={anchorId}
                                                        disabled={disabled}
                                                        question={question}
                                                        id={id}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'date' && (
                                                    <DateInputField
                                                        question={question}
                                                        anchorId={anchorId}
                                                        disabled={disabled}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'shortDate' && (
                                                    <ShortDateInputField
                                                        question={question}
                                                        anchorId={anchorId}
                                                        disabled={disabled}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'select' && (
                                                    <div
                                                        className={`govuk-form-group ${
                                                            errorMessage && 'govuk-form-group--error'
                                                        }`}
                                                        key={question.name}
                                                        id={anchorId}
                                                    >
                                                        <SelectInputField
                                                            name={question.name}
                                                            id={anchorId}
                                                            aria-labelledby={legendId}
                                                            hintOption={question.hintOption}
                                                            options={question.options || []}
                                                            disabled={disabled}
                                                            rules={rules}
                                                            title={question.title}
                                                            errorMessages={question.errorMessages}
                                                            detailsHeading={question.detailsHeading}
                                                            detailsContent={question.detailsContent}
                                                        />
                                                    </div>
                                                )}
                                                {question.questionType === 'checkbox' && (
                                                    <CheckboxGroup
                                                        anchorId={anchorId}
                                                        disabled={disabled}
                                                        question={question}
                                                        id={id}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                        formAnswers={formAnswers}
                                                    />
                                                )}
                                                {question.questionType === 'textInput' && (
                                                    <TextInputField
                                                        aria-labelledby={legendId}
                                                        id={anchorId}
                                                        disabled={disabled}
                                                        title={question.title}
                                                        name={question.name}
                                                        rules={rules}
                                                        defaultValue={defaultValue || ''}
                                                        errorMessages={question.errorMessages}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'textarea' && (
                                                    <Textarea
                                                        aria-labelledby={legendId}
                                                        numeric={!!question.rules?.number || undefined}
                                                        id={anchorId}
                                                        disabled={disabled}
                                                        title={question.title}
                                                        name={question.name}
                                                        rules={rules}
                                                        defaultValue={defaultValue || ''}
                                                        errorMessages={question.errorMessages}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'addressLookup' && (
                                                    <Controller
                                                        name={question.name}
                                                        control={methods.control}
                                                        rules={{
                                                            validate: validateManualAddress
                                                        }}
                                                        render={({
                                                            field: { ref: _ref, ...field },
                                                            fieldState: { error }
                                                        }) => (
                                                            <PostcodeLookup
                                                                {...field}
                                                                onValueSelect={data =>
                                                                    methods.setValue(question.name, data)
                                                                }
                                                                onValueChange={methods.setValue}
                                                                setShowSaveButton={setShowSaveButton}
                                                                defaultValue={defaultValue}
                                                                manualAddressError={error}
                                                                detailsHeading={question.detailsHeading}
                                                                detailsContent={question.detailsContent}
                                                            />
                                                        )}
                                                    />
                                                )}
                                                {question.questionType === 'autoComplete' && (
                                                    <AutocompleteWrapper
                                                        id={anchorId}
                                                        items={question.items || []}
                                                        name={question.name}
                                                        hintText={question.hint}
                                                        title={question.title}
                                                        rules={rules}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                    />
                                                )}
                                                {question.questionType === 'fileUpload' && (
                                                    <FileUpload
                                                        id={anchorId}
                                                        name={question.name}
                                                        label={question.label}
                                                        detailsHeading={question.detailsHeading}
                                                        detailsContent={question.detailsContent}
                                                        rules={rules}
                                                        errorMessages={question.errorMessages}
                                                    />
                                                )}
                                            </>
                                        </Fieldset>
                                    </div>
                                )
                            })}

                            <div className='gov-uk-button-group'>
                                {showSaveButton && (
                                    <button
                                        className='govuk-button save-button'
                                        type='submit'
                                        name={Button.Save}
                                        disabled={disabled}
                                    >
                                        {content.buttonSave}
                                    </button>
                                )}
                                <button
                                    className='govuk-button govuk-button--secondary'
                                    type='button'
                                    name={Button.SaveUnfinished}
                                    onClick={() => {
                                        methods.clearErrors()
                                        onSaveUnfinished(methods.getValues())
                                    }}
                                    disabled={disabled}
                                >
                                    {content.buttonSaveUnfinished}
                                </button>
                            </div>
                        </form>
                    </FormProvider>
                </>
            )}
        </>
    )
}

export default QuestionPageTemplate

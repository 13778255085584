export const data = [
    { 'case-id': 'AO00001', date: '25/12/2024', document: 'document-5.pdf' },
    { 'case-id': 'AO00002', date: '21/12/2024', document: 'document-12.pdf' },
    { 'case-id': 'AO00003', date: '13/01/2024', document: 'document-7.pdf' },
    { 'case-id': 'AO00004', date: '08/02/2024', document: 'document-3.pdf' },
    { 'case-id': 'AO00005', date: '19/03/2024', document: 'document-9.pdf' },
    { 'case-id': 'AO00006', date: '27/04/2024', document: 'document-11.pdf' },
    { 'case-id': 'AO00007', date: '02/05/2024', document: 'document-2.pdf' },
    { 'case-id': 'AO00008', date: '17/06/2024', document: 'document-8.pdf' },
    { 'case-id': 'AO00009', date: '03/07/2024', document: 'document-4.pdf' },
    { 'case-id': 'AO00010', date: '25/08/2024', document: 'document-1.pdf' },
    { 'case-id': 'AO00011', date: '12/09/2024', document: 'document-10.pdf' },
    { 'case-id': 'AO00012', date: '30/10/2024', document: 'document-6.pdf' },
    { 'case-id': 'AO00013', date: '14/11/2024', document: 'document-14.pdf' },
    { 'case-id': 'AO00014', date: '28/12/2024', document: 'document-3.pdf' },
    { 'case-id': 'AO00015', date: '07/01/2024', document: 'document-7.pdf' },
    { 'case-id': 'AO00016', date: '15/02/2024', document: 'document-13.pdf' },
    { 'case-id': 'AO00017', date: '06/03/2024', document: 'document-5.pdf' },
    { 'case-id': 'AO00018', date: '24/04/2024', document: 'document-8.pdf' },
    { 'case-id': 'AO00019', date: '19/05/2024', document: 'document-4.pdf' },
    { 'case-id': 'AO00020', date: '01/06/2024', document: 'document-2.pdf' },
    { 'case-id': 'AO00021', date: '16/07/2024', document: 'document-11.pdf' },
    { 'case-id': 'AO00022', date: '29/08/2024', document: 'document-6.pdf' },
    { 'case-id': 'AO00023', date: '11/09/2024', document: 'document-10.pdf' },
    { 'case-id': 'AO00024', date: '21/10/2024', document: 'document-9.pdf' },
    { 'case-id': 'AO00025', date: '05/11/2024', document: 'document-12.pdf' },
    { 'case-id': 'AO00026', date: '15/12/2024', document: 'document-15.pdf' },
    { 'case-id': 'AO00027', date: '07/01/2024', document: 'document-8.pdf' },
    { 'case-id': 'AO00028', date: '18/02/2024', document: 'document-3.pdf' },
    { 'case-id': 'AO00029', date: '09/03/2024', document: 'document-14.pdf' },
    { 'case-id': 'AO00030', date: '12/04/2024', document: 'document-10.pdf' },
    { 'case-id': 'AO00031', date: '21/05/2024', document: 'document-6.pdf' },
    { 'case-id': 'AO00032', date: '08/06/2024', document: 'document-9.pdf' },
    { 'case-id': 'AO00033', date: '27/07/2024', document: 'document-1.pdf' },
    { 'case-id': 'AO00034', date: '15/08/2024', document: 'document-11.pdf' },
    { 'case-id': 'AO00035', date: '04/09/2024', document: 'document-7.pdf' },
    { 'case-id': 'AO00036', date: '24/10/2024', document: 'document-5.pdf' },
    { 'case-id': 'AO00037', date: '03/11/2024', document: 'document-13.pdf' },
    { 'case-id': 'AO00038', date: '14/12/2024', document: 'document-4.pdf' },
    { 'case-id': 'AO00039', date: '22/01/2024', document: 'document-15.pdf' },
    { 'case-id': 'AO00040', date: '10/02/2024', document: 'document-2.pdf' },
    { 'case-id': 'AO00041', date: '01/03/2024', document: 'document-6.pdf' },
    { 'case-id': 'AO00042', date: '16/04/2024', document: 'document-8.pdf' },
    { 'case-id': 'AO00043', date: '28/05/2024', document: 'document-3.pdf' },
    { 'case-id': 'AO00044', date: '30/06/2024', document: 'document-7.pdf' },
    { 'case-id': 'AO00045', date: '09/07/2024', document: 'document-12.pdf' },
    { 'case-id': 'AO00046', date: '23/08/2024', document: 'document-9.pdf' },
    { 'case-id': 'AO00047', date: '17/09/2024', document: 'document-5.pdf' },
    { 'case-id': 'AO00048', date: '29/10/2024', document: 'document-14.pdf' },
    { 'case-id': 'AO00049', date: '08/11/2024', document: 'document-4.pdf' },
    { 'case-id': 'AO00050', date: '20/12/2024', document: 'document-1.pdf' }
]

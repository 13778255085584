import { Link } from 'react-router-dom'
import config from '../../config'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { P } from '../../components/ui'

const DeclarationSuccessScreen = () => (
    <PageTemplate breadcrumbs={{ sectionName: 'Declaration: Sign your declaration' }}>
        <div className='govuk-panel govuk-panel--confirmation'>
            <h1 className='govuk-panel__title'>Your questionnaire has been submitted</h1>
        </div>

        <P>An email confirmation has been sent to user.name@youremail.com</P>

        <h2 className='govuk-heading-m'>What happens next</h2>
        <P>Your questionnaire is now being checked.</P>
        <P>When there is an update on your application or we need more information, we will contact you.</P>

        <Link to={config.urls.landingAccounts} className='govuk-button'>
            Back to homepage
        </Link>
    </PageTemplate>
)

export default DeclarationSuccessScreen

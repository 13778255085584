import React from 'react'
import { Link } from 'react-router-dom'

export type BreadcrumbsProps = {
    sectionName: string
    sectionTitle?: string
}
function Breadcrumbs({ sectionName, sectionTitle }: BreadcrumbsProps) {
    return (
        <nav className='govuk-breadcrumbs'>
            <ol className='govuk-breadcrumbs__list'>
                <li className='govuk-breadcrumbs__list-item'>
                    <Link className='govuk-breadcrumbs__link' to='/'>
                        Your Questionnaire
                    </Link>
                </li>
                <li className='govuk-breadcrumbs__list-item'>
                    <Link data-testid='breadcrumbs-section-name' className='govuk-breadcrumbs__link' to='/'>
                        {sectionName}
                    </Link>
                </li>
                {sectionTitle && (
                    <li className='govuk-breadcrumbs__list-item'>
                        <span data-testid='breadcrumbs-section-title'>{sectionTitle}</span>
                    </li>
                )}
            </ol>
        </nav>
    )
}

export default Breadcrumbs

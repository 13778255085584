import { PropsWithChildren } from 'react'
import Notification from '../Notification/Notification'
import PageTemplate from '../PageTemplate/PageTemplate'
import content from '../../content'
import { useFormData } from '../../hooks/useFormData'

export default function AccountPage({ children }: PropsWithChildren) {
    const { data } = useFormData()

    if (data?.expired)
        return (
            <PageTemplate>
                <Notification
                    header={data?.notification?.header}
                    content={data?.notification?.content || content.noticeExpired}
                />
            </PageTemplate>
        )

    if (data?.submitted)
        return (
            <PageTemplate>
                <Notification
                    header={data?.notification?.header}
                    content={data?.notification?.content || content.noticeSubmitted}
                />
            </PageTemplate>
        )

    return children
}

import React from 'react'
import type { FieldValues } from 'react-hook-form'
import { P } from '../ui'

type Props = {
    error: FieldValues
}

export default function ErrorMessage({ error }: Props) {
    return (
        <P className='govuk-error-message'>
            <span className={`${error ? '' : 'govuk-visually-hidden'}`}>{error.message}</span>
        </P>
    )
}

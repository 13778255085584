import parse from 'html-react-parser'
import { P } from '../ui'
import content from '../../content'
import { PageInformation } from '../../config/types'

export default function InformationPageTemplate({
    config,
    onContinue,
    disabled
}: {
    config: PageInformation
    onContinue: () => void
    disabled?: boolean
}) {
    return (
        <>
            {(config.description ?? '').split('\n').map(paragraph => (
                <P key={paragraph.substring(0, 20)}>{parse(paragraph)}</P>
            ))}
            <button
                data-testid='information-continue-btn'
                className='govuk-button'
                onClick={onContinue}
                type='button'
                disabled={disabled ?? false}
            >
                {content.buttonContinue}
            </button>
        </>
    )
}

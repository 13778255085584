import React, { ComponentPropsWithoutRef, ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
    children: ReactNode
} & ComponentPropsWithoutRef<'button'>

const StyledButton = styled.button`
    border: none;
    background: transparent;
    padding: 0;
    color: #4c2c92;
    font-size: initial;
    cursor: pointer;
`

function ButtonText({ children, ...props }: Props) {
    return (
        <StyledButton {...props} className='govuk-link'>
            {children}
        </StyledButton>
    )
}

export default ButtonText

import React, { ReactNode } from 'react'
import { Section } from '../../config/types'

interface SectionListWrapperProps {
    showSections: boolean
    section: Section
    sectionIndex: number
    children: ReactNode
}
export const SectionListWrapper: React.FC<SectionListWrapperProps> = ({
    showSections,
    section,
    sectionIndex,
    children
}) => {
    if (showSections) {
        return (
            <li key={section.slug} data-testid='overview-sectionList-section'>
                <h2 className='govuk-heading-m'>
                    <span className='app-task-list__section-number'>{sectionIndex + 1}.</span>
                    {section.title}
                </h2>
                {section.subsections && <ol className='app-task-list__items'>{children}</ol>}
            </li>
        )
    }
    return <>{children}</>
}
interface DeclarationWrapperProps {
    showSections: boolean
    children: ReactNode
    sections: Section[]
}
export const DeclarationWrapper: React.FC<DeclarationWrapperProps> = ({ showSections, children, sections }) => {
    if (showSections) {
        return (
            <li>
                <h2 className='govuk-heading-m'>
                    <span className='app-task-list__section-number'>{sections.length + 1}.</span>
                    Declaration
                </h2>
                <ol className='app-task-list__items'>{children}</ol>
            </li>
        )
    }
    return <>{children}</>
}

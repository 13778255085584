/* eslint-disable arrow-body-style */
import { ComponentPropsWithoutRef, useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'
import { textareaMaxLength } from '../../constants'
import { containsReservedCharacters, getInputRules, validateField } from '../../utils/form-utils'
import { ErrorMessages, Rules } from '../../config/types'
import Details from './Details'

type Props = {
    name: string
    numeric?: boolean
    title: string
    rules?: Rules
    errorMessages?: ErrorMessages
    detailsHeading?: string
    detailsContent?: string
} & ComponentPropsWithoutRef<'textarea'>

const Textarea = (props: Props) => {
    const { name, title, rules, disabled, defaultValue, numeric, errorMessages, detailsHeading, detailsContent } = props

    const { register, getFieldState, getValues, setValue } = useFormContext()

    const inputRules = useMemo(() => ({ ...getInputRules(rules ?? {}, title, textareaMaxLength) }), [rules, title])

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, defaultValue ?? '')
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, inputRules, value, errorMessages)
    })
    const { error } = getFieldState(name)

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <textarea
                {...props}
                {...hooksFormRegister}
                disabled={disabled ?? false}
                onKeyDown={e => {
                    // The e.key values are strings for control characters such as 'Backspace'
                    // All non control characters will have a single character length
                    if (e.key.length === 1 && containsReservedCharacters(e.key, inputRules.reservedCharacters))
                        e.preventDefault()
                }}
                onPaste={e => {
                    const clipboardData = e.clipboardData.getData('text')
                    if (containsReservedCharacters(clipboardData, inputRules.reservedCharacters)) e.preventDefault()
                }}
                className='govuk-textarea'
                rows={5}
                inputMode={numeric ? 'numeric' : 'text'}
                data-testid={name}
            />
        </>
    )
}
Textarea.displayName = 'Textarea'

export default Textarea

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { P } from '../../components/ui'
import { getCookie, setCookie } from '../../utils/cookie-utils'

function CookiePage() {
    const [cookieConsent, setCookieConsent] = useState<string | null>(null)
    const [confirmationMessage, setConfirmationMessage] = useState<string | null>(null)

    useEffect(() => {
        const consent: any = getCookie('cookie_policy')
        setCookieConsent(consent)
    }, [])

    const handleCookieChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCookieConsent(event.target.value)
    }

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        if (cookieConsent) {
            setCookie('cookie_policy', cookieConsent, 365) // set to expire in one year (365 days)
            setConfirmationMessage(
                cookieConsent === 'accepted'
                    ? 'You have accepted additional cookies.'
                    : 'You have rejected additional cookies.'
            )
            window.scrollTo({
                top: 0,
                left: 0
            })
        }
    }

    return (
        <PageTemplate>
            {confirmationMessage && (
                <div
                    className='govuk-notification-banner govuk-notification-banner--success'
                    role='alert'
                    aria-labelledby='govuk-notification-banner-title'
                    data-module='govuk-notification-banner'
                >
                    <div className='govuk-notification-banner__header'>
                        <h2 className='govuk-notification-banner__title' id='govuk-notification-banner-title'>
                            Success
                        </h2>
                    </div>
                    <div className='govuk-notification-banner__content'>
                        <p className='govuk-notification-banner__heading'>
                            {confirmationMessage} Go back to{' '}
                            <Link
                                className='govuk-notification-banner__link'
                                to='/'
                                data-testid='success-banner-backlink'
                            >
                                Your questionnaire
                            </Link>
                            .
                        </p>
                    </div>
                </div>
            )}

            <div className='govuk-grid-row'>
                <div className='govuk-grid-column-full'>
                    <h1 className='govuk-heading-xl'>Cookies</h1>
                    <P className='govuk-body'>
                        Cookies are small files saved on your phone, tablet or computer when you visit a website.
                    </P>

                    <P className='govuk-body'>
                        We use cookies to make this site work and collect information about how you use our service.
                    </P>

                    <h2 className='govuk-heading-l'>Essential cookies</h2>

                    <P className='govuk-body'>
                        Essential cookies keep your information secure while you use this service. We do not need to ask
                        permission to use them.
                    </P>

                    <table className='govuk-table'>
                        <thead className='govuk-table__head'>
                            <tr className='govuk-table__row'>
                                <th scope='col' className='govuk-table__header' style={{ width: '25%' }}>
                                    Name
                                </th>
                                <th scope='col' className='govuk-table__header' style={{ width: '50%' }}>
                                    Purpose
                                </th>
                                <th scope='col' className='govuk-table__header'>
                                    Expires
                                </th>
                            </tr>
                        </thead>
                        <tbody className='govuk-table__body'>
                            <tr className='govuk-table__row'>
                                <td className='govuk-table__cell'>cookie_policy</td>
                                <td className='govuk-table__cell'>Saves your cookie consent settings</td>
                                <td className='govuk-table__cell'>1 year</td>
                            </tr>
                        </tbody>
                    </table>

                    <form onSubmit={handleSubmit}>
                        <fieldset className='govuk-fieldset' aria-describedby='cookie-preferences'>
                            <legend className='govuk-fieldset__legend govuk-fieldset__legend--m'>
                                Do you want to accept additional cookies?
                            </legend>

                            <div className='govuk-radios' data-module='govuk-radios'>
                                <div className='govuk-radios__item'>
                                    <input
                                        className='govuk-radios__input'
                                        type='radio'
                                        id='accept-cookies'
                                        name='cookieConsent'
                                        value='accepted'
                                        checked={cookieConsent === 'accepted'}
                                        onChange={handleCookieChange}
                                    />
                                    <label htmlFor='accept-cookies' className='govuk-label govuk-radios__label'>
                                        Yes
                                    </label>
                                </div>

                                <div className='govuk-radios__item'>
                                    <input
                                        type='radio'
                                        id='reject-cookies'
                                        name='cookieConsent'
                                        value='rejected'
                                        checked={cookieConsent === 'rejected'}
                                        onChange={handleCookieChange}
                                        className='govuk-radios__input'
                                    />
                                    <label htmlFor='reject-cookies' className='govuk-label govuk-radios__label'>
                                        No
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                        <div className='govuk-!-margin-top-4'>
                            <button type='submit' className='govuk-button' data-module='govuk-button'>
                                Save cookie settings
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <p className='govuk-body'>
                Go back to{' '}
                <Link className='govuk-link' to='/'>
                    Your questionnaire
                </Link>
                .
            </p>
        </PageTemplate>
    )
}

export default CookiePage

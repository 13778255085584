import { Page } from '../types'

const nationalSecurityHistory: Page[] = [
    {
        pageType: 'information',
        title: 'Before you start',
        name: 'before-you-start',
        description: `Tell us about any links you've had in any country to the activities featured in this section. \n\n We'll check your answers against national security records.\n\n Previous activity or support is not necessarily a barrier to gaining security clearance; your application will be considered on a case by case basis.\n\n Any links you have that you do not provide will cause delays in your application.`
    },
    {
        pageType: 'question',
        title: 'Supporting or involvement in espionage',
        name: 'national-security-history',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever supported or been involved in any form of espionage?',
                hint: 'For example, obtaining confidential or secret information from companies, government or the military without their permission.',
                name: 'national-security-history-espionage-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of your involvement or support',
                                name: 'national-security-history-espionage-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of your involvement'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever supported or been involved in espionage'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Attack or sabotage of commercial, government or military equipment, assets or interests',
        name: 'attack-or-sabotage-of-commercial-government-military',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever supported or been involved in attacking/sabotaging commercial, government or military equipment, assets, information or infrastructure?',
                hint: 'For example, using physical or cyber attacks (hacking) to undermine the stability of a company, government or the military.',
                name: 'attack-or-sabotage-of-commercial-government-military-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of your involvement',
                                name: 'attack-or-sabotage-of-commercial-government-military-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of your involvement'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'You must answer if you have ever been involved in attacking or sabotaging the government/military'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Supporting or involvement with terrorist entities',
        name: 'supporting-or-involvement-in-terrorist-entity',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever given support or been involved in any terrorist groups or organisations?',
                hint: 'For example, proscribed terrorist groups or organisations listed by the UK Home Office.',
                name: 'supporting-or-involvement-in-terrorist-entity-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of your involvement or support',
                                name: 'supporting-or-involvement-in-terrorist-entity-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of your involvement'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever supported or been involved with a terrorist group'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Supporting or involvement in extremist activity',
        name: 'supporting-or-involvement-in-extremist-activity',
        questions: [
            {
                questionType: 'radio',
                name: 'supporting-or-involvement-in-extremist-activity-radio',
                title: 'Have you ever given support or been involved in extremist activity?',
                detailsHeading: 'More information',
                detailsContent:
                    'For example, active and vocal promotion of religious, racial, political or ideological views that encourage harms  such as terrorism, hate crime, honour based violence, discrimination and segregation and division between communities.',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of your involvement',
                                name: 'supporting-or-involvement-in-extremist-activity-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of your involvement'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever supported or been involved in extremist activity'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Connections to serious organised crime',
        name: 'connections-to-serious-organised-crime',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever had connections to serious organised crime in any country?',
                name: 'connections-to-serious-organised-crime-radio',
                detailsHeading: 'More information',
                detailsContent:
                    'For example, generating wealth at the expense of other people. It includes drug-dealing, counterfeit goods, money laundering, human trafficking and cyber crime.',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of your involvement',
                                name: 'connections-to-serious-organised-crime-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of your involvement'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever had connections to serious organised crime'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Anyone close to you supporting or been involved in the activities you have been asked',
        name: 'any-close-acquaintance-supporting-or-involved-in-above-activities',
        questions: [
            {
                questionType: 'radio',
                title: 'Has anyone you are close to shown support or been involved in any of the activities you have been asked?',
                hint: 'For example, your family, partners or friends.',
                name: 'any-close-acquaintance-supporting-or-involved-in-above-activities-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide as much detail of their activity as you can recall',
                                name: 'any-close-acquaintance-supporting-or-involved-in-above-activities-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of their activity'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'You must answer if anyone close to you has been involved in the activities you have been asked'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Blackmail or coercion',
        name: 'blackmail-or-coercion',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever been a victim of blackmail or coercion?',
                name: 'blackmail-or-coercion-radio',
                detailsHeading: 'More information',
                detailsContent:
                    'For example, when a person or group uses something from your background or past to influence your actions or behaviour or you have been pressured into undertaking an act against your will.',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of the circumstances you were blackmailed',
                                name: 'blackmail-or-coercion-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of the circumstances'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever been a victim of blackmail or coercion'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Bribery',
        name: 'bribery-offer',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever been offered a bribe?',
                hint: 'Bribery is when someone gives you money or another incentive to persuade you to do something that will benefit their cause.',
                name: 'bribery-offer-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide dates and details of the circumstances you were offered the bribe',
                                name: 'bribery-offer-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details of the circumstances'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever been offered a bribe'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Anything else',
        name: 'anything-else',
        questions: [
            {
                questionType: 'radio',
                title: 'Is there anything else about you relating to national security we should know?',
                hint: 'For example, anything that might affect your suitability to hold UK national security clearance.',
                name: 'anything-else-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Provide as much detail as you can.',
                                name: 'anything-else-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide details'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have anything else to declare'
                }
            }
        ]
    }
]
export default nationalSecurityHistory

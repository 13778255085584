const content = {
    errorApi: 'Sorry, there is a problem with the service. Try again later.',
    noticeHeaderDefault: 'Important',
    noticeTokenInvalid: 'You are no longer logged in. Sign in to continue using the platform',
    noticeExpired:
        'Your application has been cancelled as the questionnaire was not completed in the given timeframe.  If you have any queries, please contact your sponsor in your organisation',
    noticeSubmitted:
        'You have already submitted your application.  If you have any further queries, please contact your sponsor in your organisation',
    buttonEnterAddress: 'Enter my address manually',
    buttonSave: 'Save and continue',
    buttonSaveUnfinished: 'Save as unfinished',
    buttonContinue: 'Continue',
    buttonSignin: 'Sign in',
    buttonPostcodeLookup: 'Find address',
    buttonChangePostcode: 'Change postcode',
    buttonSearchAgain: 'Search again',
    buttonGoToSignIn: 'Go to sign in',
    buttonAddAnother: 'Add another',
    buttonChange: 'Change',
    buttonRemove: 'Remove',
    buttonConfirmRemove: 'Remove these answers',
    buttonCancelRemove: 'Cancel and return',
    confirmRemoval: 'Are you sure you want to remove these answers?',
    signinInvalidForm: 'Enter a valid user ID and password.',
    errorSummaryHeader: 'There is a problem',
    postcodelookupManual: 'Enter my address manually',
    postcodelookupFetchEmpty:
        'No address was found for your postcode. Try again with a different postcode or enter your address manually',
    labels: {
        line1: 'Address line 1',
        line2: 'Address line 2',
        town: 'Town or city',
        county: 'County',
        postcode: 'Postcode',
        addressSelect: 'Select an address',
        addressConfirm: 'Confirm address'
    },
    invalidPostcode: 'Enter a valid postcode',
    noAddressFound: 'No address found',
    forgotPassword: 'Forgot password?',
    invalidPassword:
        'The password you have entered does not meet the requirements, check the password requirements shown on this page',
    nonMatchingPasswords: 'The passwords you entered do not match each other',
    invalidVerificationCode: 'Invalid verification code provided, please try again',
    submitQuestionnaireLink: 'Submit your questionnaire',
    failedMfaToken: '$LABEL not recognised. Please try again',
    incorrectMfa: 'Enter a valid sign in code. You have entered an incorrect or expired code',
    invalidDate: '$LABEL must be a valid date'
} as const

export default content

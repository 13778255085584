function PhaseBanner() {
    return (
        <div className='govuk-phase-banner' role='region' aria-label='Phase banner'>
            <p className='govuk-phase-banner__content'>
                <strong
                    className='govuk-tag govuk-phase-banner__content__tag'
                    style={{ backgroundColor: '#1d70b8', color: '#ffffff', fontWeight: 'bold' }}
                    aria-label='Beta phase'
                >
                    BETA
                </strong>
                <span className='govuk-phase-banner__text'>
                    This is a new service. Your{' '}
                    <a
                        className='govuk-link'
                        href='https://www.gov.uk/'
                        target='_blank'
                        rel='noopener noreferrer'
                        aria-label='Provide feedback on this new service'
                    >
                        feedback
                    </a>{' '}
                    will help us to improve it.
                </span>
            </p>
        </div>
    )
}

export default PhaseBanner

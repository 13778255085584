import { Page } from '../types'
import { COUNTRIES } from '../defaultContent'

const citizenship: Page[] = [
    {
        pageType: 'information',
        title: 'Before you start',
        name: 'before-you-start',
        description:
            'Add all nationalities and citizenships you have held. \n\n Includes current and previous nationalities and citizenships you have had since birth. \n\n Where you currently hold or have held dual or multiple nationalities and citizenships you must include all of them.'
    },
    {
        pageType: 'question',
        title: 'Current nationality',
        name: 'nationality-and-citizenship',
        questions: [
            {
                questionType: 'select',
                title: 'What is your current nationality/citizenship?',
                name: 'nationality-and-citizenship-select',
                options: COUNTRIES,
                hintOption: 'Select an option',
                rules: {
                    required: true
                },
                errorMessages: { required: 'You must select your current nationality/citizenship' }
            },
            {
                questionType: 'radio',
                title: 'Has this been your nationality/citizenship since birth?',
                name: 'nationality-citizenship-since-birth-radio',
                options: [
                    'Yes',
                    {
                        option: 'No',
                        questions: [
                            {
                                questionType: 'shortDate',
                                title: 'Since when have you held this nationality/citizenship?',
                                name: 'nationality-and-citizenship-date',
                                hint: 'For example 10/1998',
                                rules: {
                                    required: true,
                                    isBeforeNow: 'enabled'
                                },
                                errorMessages: {
                                    required: 'Enter the date you have held this nationality/citizenship from',
                                    isBeforeNow:
                                        'Date you have held this nationality/citizenship from must be in the past'
                                }
                            }
                        ]
                    }
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether this has been your nationality/citizenship since birth'
                }
            },
            {
                questionType: 'radio',
                title: 'Do you live in the UK?',
                name: 'live-in-uk-residency-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'shortDate',
                                title: 'Enter the date you took up residency in the UK',
                                name: 'live-in-uk-residency-date',
                                rules: {
                                    required: true,
                                    isBeforeNow: 'enabled'
                                },
                                errorMessages: {
                                    required: 'You must enter the date you took up residency in the UK',
                                    isBeforeNow: 'Date you took up residency must be in the past'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: { required: 'Select whether you live in the UK' }
            }
        ]
    },
    {
        disabled: [
            {
                field: 'nationality-citizenship-since-birth-radio',
                condition: 'equals',
                value: 'Yes'
            }
        ],
        pageType: 'question',
        title: 'Other Nationality',
        name: 'nationality-and-citizenship-continued',
        questions: [
            {
                questionType: 'select',
                title: 'What other nationality/citizenship have you held since birth?',
                name: 'other-nationality-and-citizenship-select',
                options: COUNTRIES,
                hintOption: 'Select an option',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select which other nationality/citizenship you have held since birth'
                }
            },
            {
                questionType: 'shortDate',
                title: 'Since when have you held this nationality/citizenship?',
                name: 'other-nationality-and-citizenship-date',
                hint: 'For example 10/1998',
                rules: {
                    required: true,
                    isBeforeNow: 'enabled'
                },
                errorMessages: {
                    required: 'Enter the date you have held this nationality/citizenship from',
                    isBeforeNow: 'Date you have held this nationality/citizenship from must be in the past'
                }
            },
            {
                questionType: 'radio',
                title: 'Are you British Naturalised?',
                name: 'british-nationalised-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textInput',
                                title: 'Please provide Naturalisation Number',
                                name: 'naturalisation-number',
                                hint: 'Number on the certificate',
                                rules: {
                                    required: true
                                }
                            },
                            {
                                questionType: 'shortDate',
                                title: 'Please provide Naturalisation Date',
                                name: 'naturalisation-date',
                                hint: 'Date on the certificate',
                                rules: {
                                    required: true,
                                    isBeforeNow: 'enabled'
                                },
                                errorMessages: { isBeforeNow: 'Naturalisation date must be in the past' }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: { required: 'Select whether you are British Naturalised' }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Previous/Dual nationality',
        name: 'other-nationality-and-citizenship',
        questions: [
            {
                questionType: 'radio',
                title: 'Do you currently hold or have you previously held another nationality/citizenship?',
                name: 'previous-nationality-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'radio',
                                title: 'What type of nationality/citizenship is this?',
                                name: 'nationality-type',
                                options: ['Dual', 'Sole'],
                                rules: {
                                    required: true
                                },
                                errorMessages: { required: 'Select the type of nationality/citizenship' }
                            },
                            {
                                questionType: 'select',
                                title: 'Nationality/citizenship?',
                                name: 'additional-nationality-and-citizenship-select',
                                options: COUNTRIES,
                                hintOption: 'Select an option',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'Select which nationality/citizenship you currently or previously held'
                                }
                            },
                            {
                                questionType: 'shortDate',
                                title: 'Date From',
                                name: 'additional-nationality-date-from',
                                hint: 'Date on the certificate',
                                rules: {
                                    required: true,
                                    isBeforeNow: 'enabled'
                                },
                                errorMessages: {
                                    isBeforeNow:
                                        'Date you have held this nationality/citizenship from must be in the past'
                                }
                            },
                            {
                                questionType: 'shortDate',
                                title: 'Date To',
                                name: 'additional-nationality-date-to',
                                hint: 'Date on the certificate',
                                rules: {
                                    required: true
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'Select whether you currently or previously have held another nationality/citizenship'
                }
            }
        ]
    }
]
export default citizenship

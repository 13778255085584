import { useMemo } from 'react'
import formConfig from '../config/formConfig'
import { useFormData } from './useFormData'
import { getEnabledSubsections } from '../utils/config-utils'

export const useFormConfig = () => {
    const { data } = useFormData()

    const enabledSubsections = useMemo(() => getEnabledSubsections(formConfig, data), [data])

    return {
        formConfig,
        enabledSubsections
    }
}

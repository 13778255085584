import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import Tag from '../../components/Tag/Tag'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { Status } from '../../types'
import Notification from '../../components/Notification/Notification'
import config from '../../config'
import content from '../../content'
import { getOverviewStatus } from '../../utils/config-utils'
import { useFormData } from '../../hooks/useFormData'
import DCFMarkdown from '../../components/DCFMarkdown/DCFMarkdown'
import { useUiContext } from '../../context/UiContext'
import { useFormConfig } from '../../hooks/useFormConfig'
import { SectionListWrapper, DeclarationWrapper } from './Wrappers'

export default function Overview() {
    const { isLoading } = useUiContext()
    const { formConfig, enabledSubsections } = useFormConfig()
    const { sections } = formConfig

    const title = 'title' in formConfig ? String(formConfig.title) : undefined
    const subTitle = 'subTitle' in formConfig ? String(formConfig.subTitle) : undefined
    const showSections = formConfig.showSections === undefined ? true : formConfig.showSections
    let index = 0

    // fetch personal data
    const { data } = useFormData()

    const progressData = useMemo(
        () => getOverviewStatus(sections, enabledSubsections, data),
        [sections, enabledSubsections, data?.answers]
    )

    return (
        <>
            {isLoading ? (
                <PageTemplate title=''>
                    <h2 className='govuk-heading-m'>Loading...</h2>
                </PageTemplate>
            ) : (
                <PageTemplate title={title}>
                    <div data-testid='overview'>
                        {subTitle && <DCFMarkdown content={subTitle} type='subtitle' />}

                        {data?.notification?.header && data?.notification?.content && (
                            <Notification header={data.notification.header} content={data.notification.content} />
                        )}

                        <ol className='govuk-list' data-testid='overview-sectionList'>
                            {sections.map((section, sectionIndex) => (
                                <SectionListWrapper
                                    showSections={showSections}
                                    section={section}
                                    sectionIndex={sectionIndex}
                                    key={section.slug}
                                >
                                    {section.subsections.map((subsection, subsectionIndex) => {
                                        const status = progressData[subsection.slug] || Status.NotStarted
                                        index += 1

                                        const href =
                                            status === Status.NotStarted
                                                ? `/${section.slug}/${subsection.slug}`
                                                : `/${section.slug}/${subsection.slug}/summary`

                                        return (
                                            <li key={subsection.slug} className='app-task-list__item'>
                                                <Link
                                                    to={href}
                                                    data-testid={`overview-subsectionList-${subsection.slug}Link`}
                                                >
                                                    <span className='app-task-list__task-name'>
                                                        {showSections
                                                            ? `${sectionIndex + 1}.${subsectionIndex + 1}`
                                                            : `${index}.`}
                                                        &nbsp;
                                                    </span>
                                                    {subsection.title}
                                                </Link>
                                                <Tag status={status} />
                                            </li>
                                        )
                                    })}
                                </SectionListWrapper>
                            ))}
                            {!!sections.length && (
                                <DeclarationWrapper showSections={showSections} sections={sections}>
                                    <li className='app-task-list__item'>
                                        {Object.values(progressData).some(
                                            (status: string) => status !== Status.Completed
                                        ) ? (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a role='link' aria-disabled='true'>
                                                {content.submitQuestionnaireLink}
                                            </a>
                                        ) : (
                                            <Link to={config.urls.declaration}>{content.submitQuestionnaireLink}</Link>
                                        )}
                                    </li>
                                </DeclarationWrapper>
                            )}
                        </ol>
                    </div>
                </PageTemplate>
            )}
        </>
    )
}

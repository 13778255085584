import { HTMLProps, PropsWithChildren, useCallback } from 'react'
import ReactMarkdown from 'react-markdown'

interface Props extends PropsWithChildren {
    content: string | undefined
    type: string
}

function DCFMarkdown({ content, type }: Props) {
    function className(type: string) {
        switch (type) {
            case 'subtitle':
                return 'govuk-heading-m'
            case 'description':
                return 'govuk-body'
            case 'hint-text':
                return 'govuk-hint'
            default:
                return 'govuk-body'
        }
    }

    const components = {
        a: useCallback(
            (props: HTMLProps<HTMLAnchorElement>) => (
                <a href={props.href} className='govuk-link'>
                    {props.children}
                </a>
            ),
            []
        ),
        ul: useCallback(
            (props: HTMLProps<HTMLUListElement>) => <ul className='govuk-list govuk-list--bullet'>{props.children}</ul>,
            []
        )
    }
    return (
        <ReactMarkdown className={className(type)} components={components}>
            {content}
        </ReactMarkdown>
    )
}

export default DCFMarkdown

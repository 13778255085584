import { ChangeEvent } from 'react'
import Radio from '../Radio'

interface DefaultRadioProps {
    id: string
    name: string
    disabled?: boolean
    anchorId: string | undefined
    index: number
    item: string
    currentValue: string
    handleOnChange(event: ChangeEvent<HTMLInputElement>): void
}

export const DefaultRadio = ({
    id,
    anchorId,
    index,
    item,
    currentValue,
    name,
    disabled,
    handleOnChange
}: DefaultRadioProps) => {
    const fieldId = !!anchorId && index === 0 ? anchorId : `${id}${item}${anchorId}`

    return (
        <div className='govuk-radios__item' key={fieldId}>
            <Radio
                id={fieldId}
                name={name}
                disabled={disabled ?? false}
                value={item}
                checked={currentValue === item}
                onChange={e => handleOnChange(e)}
            />
            <label className='govuk-label govuk-radios__label' htmlFor={fieldId}>
                {item}
            </label>
        </div>
    )
}

import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { validateField } from '../../../utils/form-utils'
import { SelectOptionGroup } from '../../../config/types'
import ErrorMessage from '../ErrorMessage'
import { DefaultRadio } from './DefaultRadio'
import { RadioGroupProps, RadioSubGroupProps } from './Props'

const ConditionalRadioSubGroup = ({
    id,
    anchorId,
    currentValue,
    option,
    name,
    disabled,
    handleOnChange
}: RadioSubGroupProps) => (
    <div className='govuk-radios fieldset-container' data-module='govuk-radios' key={option.label}>
        <legend className='govuk-fieldset__legend govuk-fieldset__legend--s'>
            <h3 className='govuk-fieldset__heading'>{option.label}</h3>
        </legend>
        {option.options.map((option, index) => (
            <DefaultRadio
                id={id}
                name={name}
                disabled={disabled}
                key={option}
                anchorId={anchorId}
                index={index}
                item={option as string}
                currentValue={currentValue}
                handleOnChange={handleOnChange}
            />
        ))}
    </div>
)

export default function ConditionalRadioField({ question, anchorId, disabled, id }: RadioGroupProps) {
    const { register, getFieldState, getValues, setValue } = useFormContext()
    const { options, name, title, rules, errorMessages } = question
    const [currentValue, setCurrentValue] = useState(getValues(name) ?? '')

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value)
        setValue(name, e.target.value)
    }

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, '')
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, rules || {}, value, errorMessages)
    })
    const { error } = getFieldState(name)

    // If the options contains a SubGroup they must all be subgroups
    const optionIsSubGroup: boolean = (options && typeof options[0] === 'object' && 'label' in options[0]) || false

    return (
        <>
            {error && <ErrorMessage error={error} />}
            <div className='govuk-radios' data-module='govuk-radios'>
                {options &&
                    options.map((option, i) => (
                        <>
                            {optionIsSubGroup && (
                                <ConditionalRadioSubGroup
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    option={option as SelectOptionGroup}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                            {!optionIsSubGroup && (
                                <DefaultRadio
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    index={i}
                                    item={option as string}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                        </>
                    ))}
            </div>
        </>
    )
}

import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link, useLocation } from 'react-router-dom'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import content from '../../content'
import { isValidPassword, validateField } from '../../utils/form-utils'
import config from '../../config'
import { P } from '../../components/ui'
import { useAuthContext } from '../../auth/AuthContext'
import ErrorMessage from '../../components/form/ErrorMessage'
import Input from '../../components/form/TextInput'

export default function ResetPasswordScreen() {
    const location = useLocation()
    const email: string = location.state?.email
    const { confirmResetPassword } = useAuthContext()
    const {
        formState: { errors },
        handleSubmit,
        setError,
        getValues,
        control
    } = useForm<{ code: string; password: string; reenteredPassword: string }>({
        ...config.form,
        defaultValues: { code: '', password: '', reenteredPassword: '' }
    })
    const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(false)
    const [disabled, setDisabled] = useState(false)

    if (passwordResetSuccessful)
        return (
            <PageTemplate title='Your password has been reset'>
                <P>You can now use your new password to sign in to your account.</P>
                <Link to={config.urls.signin} className='govuk-button'>
                    {content.buttonGoToSignIn}
                </Link>
            </PageTemplate>
        )

    return (
        <PageTemplate title='We emailed a verification code to you' backLink={config.urls.resetPasswordEmail}>
            <ErrorSummary errors={errors} idPrefix='field-' />

            <P>
                We have sent a verification code to <b>{email}</b> <br />
                You may need to check your junk email or spam folder.
            </P>
            <form
                onSubmit={handleSubmit(async data => {
                    try {
                        setDisabled(true)
                        const code = data.code.trim()
                        confirmResetPassword(
                            { username: email, newPassword: data.password, confirmationCode: code },
                            {
                                onSuccess: () => setPasswordResetSuccessful(true),
                                onError: () => setError('code', { message: content.invalidVerificationCode }),

                                onSettled: () => {
                                    setDisabled(false)
                                }
                            }
                        )
                    } catch (err) {
                        setDisabled(false)
                        const message = (err as Error).message || content.invalidVerificationCode
                        setError('code', { message })
                    }
                })}
            >
                <div className={`govuk-form-group ${errors?.code && 'govuk-form-group--error'}`} key='code'>
                    <label htmlFor='field-code' className='govuk-label'>
                        Verification code
                    </label>
                    <Controller
                        name='code'
                        control={control}
                        rules={{
                            validate: (value: string) => validateField('Verification code', { required: true }, value)
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-code'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
                <h2 className='govuk-heading-l'>Enter a new password</h2>
                <P>Please enter a new password for your account</P>
                <div className='govuk-inset-text'>
                    <P>Your password must contain:</P>
                    <ul>
                        <li>At least 8 characters</li>
                        <li>A lower case letter</li>
                        <li>An upper case letter</li>
                        <li>A number</li>
                        <li>
                            At least one of these special characters: <br />
                            !@#$%^&+=)(-_* <br />
                            but do not use any other special characters
                        </li>
                    </ul>
                </div>
                <div className={`govuk-form-group ${errors?.password && 'govuk-form-group--error'}`} key='password'>
                    <label htmlFor='field-password' className='govuk-label'>
                        Password
                    </label>
                    <Controller
                        name='password'
                        control={control}
                        rules={{
                            validate: {
                                validateField: (value: string) => validateField('Password', { required: true }, value),
                                validPassword: value => isValidPassword(value) || content.invalidPassword
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-password'
                                    type='password'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
                <div
                    className={`govuk-form-group ${errors?.reenteredPassword && 'govuk-form-group--error'}`}
                    key='reentered-password'
                >
                    <label htmlFor='field-reenteredPassword' className='govuk-label'>
                        Re-enter password
                    </label>
                    <Controller
                        name='reenteredPassword'
                        control={control}
                        rules={{
                            validate: {
                                validateField: (value: string) =>
                                    validateField('Re-enter password', { required: true }, value),
                                validPassword: value => isValidPassword(value) || content.invalidPassword,
                                passwordEqual: value => value === getValues().password || content.nonMatchingPasswords
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-reenteredPassword'
                                    type='password'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>

                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonContinue}
                </button>
            </form>
        </PageTemplate>
    )
}

import React from 'react'
import styled from 'styled-components'
import { Props } from './types'

const Wrapper = styled.div.withConfig({
    shouldForwardProp: prop => !['loading', 'barColor', 'backgroundColor'].includes(prop)
})<Props>`
    position: relative;
    overflow: hidden;
    display: block;
    height: ${p => p?.height || ''};
    z-index: 0;
    background-color: ${p => (p.loading ? p.backgroundColor : p.barColor)};

    .bar1,
    .bar2 {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        transition: transform 0.2s linear;
        transform-origin: left;
        background-color: ${p => p.barColor};
        width: auto;
    }
    .bar1 {
        animation: progressbar-bar1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }
    .bar2 {
        animation: progressbar-bar2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
    }
`

function ProgressBar({ backgroundColor = '#a7caed', barColor = '#1d70b8', height = '10px', ...props }: Props) {
    return (
        <Wrapper {...props} backgroundColor={backgroundColor} barColor={barColor} height={height}>
            {props.loading && (
                <>
                    <span className='bar1' />
                    <span className='bar2' />
                </>
            )}
        </Wrapper>
    )
}

export default ProgressBar

import React, { ComponentPropsWithRef } from 'react'
import { Status } from '../../types'

type Props = {
    status: Status
} & ComponentPropsWithRef<'strong'>

const getTagClassByStatus = (status: Status) => {
    switch (status) {
        case Status.Completed:
            return 'govuk-tag--green'
        case Status.InProgress:
            return 'govuk-tag--blue'
        case Status.NotStarted:
        default:
            return 'govuk-tag--grey'
    }
}

function Tag({ status, ...props }: Props) {
    return (
        <strong {...props} className={`app-task-list__tag govuk-tag ${getTagClassByStatus(status)}`}>
            {status}
        </strong>
    )
}

export default Tag

import { ChangeEvent } from 'react'
import Checkbox from '../Checkbox'

interface DefaultCheckboxProps {
    id: string
    name: string
    disabled?: boolean
    anchorId: string | undefined
    index: number
    item: string
    currentValue: string[]
    handleOnChange(event: ChangeEvent<HTMLInputElement>, value: string): void
}

export const DefaultCheckbox = ({
    id,
    anchorId,
    index,
    item,
    currentValue,
    name,
    disabled,
    handleOnChange
}: DefaultCheckboxProps) => {
    const fieldId = !!anchorId && index === 0 ? anchorId : `${id}${item}${anchorId}`

    return (
        <div className='govuk-checkboxes__item' key={fieldId}>
            <Checkbox
                id={fieldId}
                name={name}
                disabled={disabled ?? false}
                value={item}
                checked={currentValue.includes(item)}
                onChange={e => handleOnChange(e, item)}
            />
            <label className='govuk-label govuk-checkboxes__label' htmlFor={fieldId}>
                {item}
            </label>
        </div>
    )
}

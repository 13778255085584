import React, { useEffect, useRef } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'
import content from '../../content'

type Props = {
    header?: string
    idPrefix?: string
    errors: FieldErrors<FieldValues>
}

function ErrorSummary({ errors, header = content.errorSummaryHeader, idPrefix = '' }: Props) {
    const divRef = useRef<HTMLDivElement>(null)
    const errorKeys = Object.keys(errors)

    useEffect(() => {
        if (errorKeys.length) divRef.current?.focus()
    }, [errorKeys.join(',')])

    return errorKeys.length ? (
        <div className='govuk-error-summary' data-module='govuk-error-summary' tabIndex={-1} ref={divRef}>
            <div role='alert'>
                <h2 className='govuk-error-summary__title'>{header}</h2>
                <div className='govuk-error-summary__body'>
                    <ul className='govuk-list govuk-error-summary__list'>
                        {errorKeys.map(key => (
                            <li key={key}>
                                <a href={`#${idPrefix}${key}`}>{errors[key]?.message?.toString()}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    ) : null
}

export default ErrorSummary

import { Page } from '../types'

const employmentHistory: Page[] = [
    {
        pageType: 'information',
        title: 'Before you start',
        name: 'before-you-start',
        description:
            'If you have been dismissed from any employment (paid or voluntary) or had your contract terminated before the end date, provide the reasons for this. \n\n   If you have ever resigned from employment (paid or voluntary) while under investigation for misconduct or been investigated for gross misconduct, provide details, reasons and outcome. \n\n   If you have ever been investigated for gross misconduct, provide details.'
    },
    {
        pageType: 'question',
        title: 'Dismissed in the past',
        name: 'dismissed-in-the-past',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever been dismissed from any employment (paid or voluntary) or had your contract terminated before the end date?',
                name: 'dismissed-in-the-past-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Please provide the reasons for dismissal/contract termination',
                                name: 'dismissed-in-the-past-reason-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide the reasons for dismissal/contract termination'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'You must answer if you have ever been dismissed from any employment or had your contract terminated'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Resigned in the past',
        name: 'resigned-in-the-past',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever resigned from employment (paid or voluntary) whilst under investigation for misconduct?',
                name: 'resigned-under-investigation-in-the-past-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Please provide full details of the misconduct investigation, the reason for resignation and outcome',
                                name: 'under-investigation-in-the-past-details-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide the full details of the investigation'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'You must answer if you have ever resigned from employment whilst under investigation for misconduct'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Investigated for gross misconduct',
        name: 'investigated-for-gross-misconduct',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever been investigated for gross misconduct?',
                name: 'investigated-for-gross-misconduct-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'textarea',
                                title: 'Please provide full details of the misconduct investigation and outcome',
                                name: 'investigated-for-gross-misconduct-details-text-area',
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required: 'You must provide the full details of the investigation'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever been investigated for gross misconduct'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Any previous employment in the police services',
        name: 'previous-employment-in-police-services',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you ever been employed in the police services (including as a staff member)?',
                name: 'previous-employment-in-police-services-radio',
                options: ['Yes', 'No'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer if you have ever been employed in the police services'
                }
            }
        ]
    }
]
export default employmentHistory

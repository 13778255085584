import { KeyboardEvent, ClipboardEvent } from 'react'
import content from '../../../content'
import { Question, Rules } from '../../../config/types'

const digitRegExp = /^[1234567890]*$/

export function handleKeyPress(e: KeyboardEvent<HTMLInputElement>) {
    // We want to only allow digits, can't use the regex or it will stop control characters too.
    if ('.-+eE'.includes(e.key)) {
        e.preventDefault()
    }
}

export function handlePaste(e: ClipboardEvent<HTMLInputElement>) {
    const clipboardData = e.clipboardData.getData('text')
    if (!digitRegExp.test(clipboardData)) {
        e.preventDefault()
    }
}

export function invalidDateErrorMessage(question: Question): string {
    return String(content.invalidDate).replace('$LABEL', question.title)
}

export function dateIsFullyPopulated(value: string) {
    if (!value || value.indexOf('-') < 0) {
        return false
    }
    const values = value.split('-')
    if (values.length === 3) {
        return values[0].length > 0 && values[1].length > 0 && values[2].length > 0
    }
    return values[0].length > 0 && values[1].length > 0
}

export function dateIsEmpty(value: string) {
    return !value || value === '-' || value === '--'
}

export function getMinYearValue(rules: Rules): number {
    let minYearInt = parseInt(rules.minYear ?? '', 10)
    if (Number.isNaN(minYearInt)) {
        minYearInt = 1900
    }
    return minYearInt
}

export function isMinYearRuleValid(rules: Rules, value: string): boolean {
    if (dateIsFullyPopulated(value)) {
        const minYearInt = getMinYearValue(rules)
        const values = value.split('-')
        const yearInt = parseInt(values[0], 10)
        if (!Number.isNaN(yearInt) && yearInt < minYearInt) {
            return false
        }
    }
    return true
}

export function isValidDate(value: string) {
    const values = value.split('-')
    if (values.length < 2 || values.length > 3) {
        return false
    }
    const year = parseInt(values[0], 10)
    const month = parseInt(values[1], 10)
    // For short dates default the day to the 1st, will be valid for all months
    const day = values.length === 3 ? parseInt(values[2], 10) : 1
    const toCheck = new Date(year, month - 1, day)
    if (Number.isNaN(toCheck)) {
        return false
    }
    // Javascript allows dates like 2000-02-31, they evaluate to 2000-03-02
    // Just check the month hasn't rolled over.
    return month === toCheck.getMonth() + 1
}

import { useRef, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../ErrorMessage'
import Radio from '../Radio'
import { isObject } from '../../../utils/array-utils'
import TextInputField from '../TextInputField'
import { validateField } from '../../../utils/form-utils'
import Textarea from '../Textarea'
import { DateInputField } from '../DateInputField/DateInputField'
import { ShortDateInputField } from '../DateInputField/ShortDateInputField'
import SelectInputField from '../SelectInputField'
import { Question, SelectOptionDefault, SelectOptionGroup } from '../../../config/types'
import ConditionalRadioField from './ConditionalRadioField'
import ConditionalCheckboxField from '../CheckboxGroup/ConditionalCheckboxField'
import { DefaultRadio } from './DefaultRadio'
import { ConditionalRadioProps, RadioGroupProps, RadioItemProps, RadioSubGroupProps } from './Props'
import AutocompleteWrapper from '../Autocomplete/AutocompleteWrapper'
import Details from '../Details'
import DCFMarkdown from '../../DCFMarkdown/DCFMarkdown'

const ConditionalRadio = ({
    id,
    anchorId,
    index,
    item,
    currentValue,
    name,
    disabled,
    handleOnChange
}: ConditionalRadioProps) => {
    const value = item.option
    const fieldId = !!anchorId && index === 0 ? anchorId : `${id}${value}${anchorId}`
    const questions = (item.questions as Question[]) ?? []
    const showConditional = !!questions.length && value === currentValue
    const conditionalFieldId = `${fieldId}_conditional`

    const {
        unregister,
        formState: { errors }
    } = useFormContext()

    const previousValue = useRef<string>()

    // Need to check if we were the previously selected option
    // if so we need to unregister all the sub-questions that we have.
    useEffect(() => {
        if (previousValue.current === value && currentValue !== value) {
            const questionNames: string[] = []
            questions.reduce((questionNames, question) => {
                questionNames.push(question.name)
                return questionNames
            }, questionNames)
            unregister(questionNames)
        }
        previousValue.current = currentValue
    }, [currentValue])

    return (
        <>
            <div className='govuk-radios__item' key={fieldId}>
                <Radio
                    id={fieldId}
                    name={name}
                    disabled={disabled}
                    value={value}
                    checked={currentValue === value}
                    data-aria-controls={conditionalFieldId}
                    aria-expanded={showConditional}
                    onChange={e => handleOnChange(e)}
                />
                <label className='govuk-label govuk-radios__label' htmlFor={fieldId}>
                    {value}
                </label>
            </div>
            {showConditional && (
                <div
                    className={
                        showConditional
                            ? 'govuk-radios__conditional'
                            : 'govuk-radios__conditional govuk-radios__conditional--hidden'
                    }
                    id={conditionalFieldId}
                    hidden={!showConditional}
                >
                    {questions.map(question => {
                        const rules = question?.rules || {}

                        return (
                            <div
                                className={`govuk-form-group ${
                                    errors && errors[question.name] && 'govuk-form-group--error'
                                }`}
                                key={question.name}
                            >
                                <label className='govuk-label' htmlFor={`${id}${question.name}`}>
                                    {rules?.required === true ? question.title : `${question.title} (optional)`}
                                </label>
                                {question.hint && <DCFMarkdown content={question.hint} type='hint-text' />}
                                {question.questionType === 'textInput' && (
                                    <TextInputField
                                        name={question.name}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'textarea' && (
                                    <Textarea
                                        name={question.name}
                                        disabled={disabled ?? false}
                                        numeric={!!question.rules?.number || undefined}
                                        id={`${id}${question.name}`}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'radio' && (
                                    <ConditionalRadioField
                                        question={question}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'date' && (
                                    <DateInputField
                                        question={question}
                                        anchorId={`${id}${question.name}`}
                                        disabled={disabled}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'shortDate' && (
                                    <ShortDateInputField
                                        question={question}
                                        anchorId={`${id}${question.name}`}
                                        disabled={disabled}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'checkbox' && (
                                    <ConditionalCheckboxField
                                        question={question}
                                        disabled={disabled ?? false}
                                        id={`${id}${question.name}`}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'select' && (
                                    <SelectInputField
                                        name={question.name}
                                        id={id}
                                        hintOption={question.hintOption}
                                        options={question.options || []}
                                        disabled={disabled}
                                        rules={rules}
                                        title={question.title}
                                        errorMessages={question.errorMessages}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                                {question.questionType === 'autoComplete' && (
                                    <AutocompleteWrapper
                                        id={id}
                                        items={question.items || []}
                                        name={question.name}
                                        title={question.title}
                                        rules={rules}
                                        detailsHeading={question.detailsHeading}
                                        detailsContent={question.detailsContent}
                                    />
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </>
    )
}

const RadioItem = ({ id, anchorId, index, item, currentValue, name, disabled, handleOnChange }: RadioItemProps) => {
    const isConditionalRadio = isObject(item) && 'questions' in item
    return (
        <>
            {isConditionalRadio && (
                <ConditionalRadio
                    id={id}
                    anchorId={anchorId}
                    name={name}
                    disabled={disabled}
                    index={index}
                    item={item as SelectOptionDefault}
                    currentValue={currentValue}
                    handleOnChange={handleOnChange}
                />
            )}
            {!isConditionalRadio && (
                <DefaultRadio
                    id={id}
                    name={name}
                    disabled={disabled}
                    anchorId={anchorId}
                    index={index}
                    item={item as string}
                    currentValue={currentValue}
                    handleOnChange={handleOnChange}
                />
            )}
        </>
    )
}

const RadioSubGroup = ({ id, anchorId, currentValue, option, name, disabled, handleOnChange }: RadioSubGroupProps) => (
    <div className='govuk-radios fieldset-container' data-module='govuk-radios' key={option.label}>
        <legend className='govuk-fieldset__legend govuk-fieldset__legend--s'>
            <h3 className='govuk-fieldset__heading'>{option.label}</h3>
        </legend>
        {option.options.map((option, index) => (
            <RadioItem
                id={id}
                name={name}
                disabled={disabled}
                key={option}
                anchorId={anchorId}
                index={index}
                item={option as string | SelectOptionDefault}
                currentValue={currentValue}
                handleOnChange={handleOnChange}
            />
        ))}
    </div>
)

export default function RadioGroup({
    question,
    anchorId,
    disabled,
    id,
    detailsHeading,
    detailsContent
}: RadioGroupProps) {
    const { register, getFieldState, getValues, setValue } = useFormContext()
    const { options, name, title, rules, errorMessages } = question
    const [currentValue, setCurrentValue] = useState(getValues(name) ?? '')

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value)
        setValue(name, e.target.value)
    }

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, '')
        }
    }, [])

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, rules || {}, value, errorMessages)
    })
    const { error } = getFieldState(name)

    // If the options contains a SubGroup they must all be subgroups
    const optionIsSubGroup: boolean = (options && typeof options[0] === 'object' && 'label' in options[0]) || false

    return (
        <>
            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <div className='govuk-radios' data-module='govuk-radios'>
                {options &&
                    options.map((option, i) => (
                        <>
                            {optionIsSubGroup && (
                                <RadioSubGroup
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    option={option as SelectOptionGroup}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                            {!optionIsSubGroup && (
                                <RadioItem
                                    {...hooksFormRegister}
                                    id={id}
                                    name={name}
                                    disabled={disabled}
                                    anchorId={anchorId}
                                    index={i}
                                    item={option as string | SelectOptionDefault}
                                    currentValue={currentValue}
                                    handleOnChange={handleOnChange}
                                />
                            )}
                        </>
                    ))}
            </div>
        </>
    )
}

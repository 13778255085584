import { useIsFetching, useIsMutating } from '@tanstack/react-query'
import React, { createContext, FC, ReactNode, useContext } from 'react'

type Props = {
    isLoading: boolean
}

const UIContext = createContext<Props>({} as Props)

export const UiProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const isFetching = useIsFetching()
    const isMutating = useIsMutating()
    const isLoading = isFetching > 0 || isMutating > 0

    const context: Props = {
        isLoading
    }

    return <UIContext.Provider value={context}>{children}</UIContext.Provider>
}

export const useUiContext = () => {
    const context = useContext(UIContext)
    return context
}

import { Page } from '../types'

const illegalSubstancesUse: Page[] = [
    {
        pageType: 'question',
        title: 'Illegal substances',
        name: 'illegal-substances-use',
        description:
            "Illegal Substances are any drug which is not legally obtainable, or which is legally obtainable but has not been legally obtained. The term includes prescribed drugs not being used for prescribed purposes. <br><br> It also includes New Psychoactive Substances (NPS) informally known as 'legal highs' which are drugs containing one or more chemical substances that produce similar effects to drugs like cocaine, cannabis and ecstasy.",
        questions: [
            {
                questionType: 'radio',
                title: "Have you ever used illegal substances (or unprescribed controlled substances), or New psychoactive substances (NPS), formerly known as 'legal highs'?",
                name: 'illegal-substances-use-radio',
                options: [
                    {
                        option: 'Yes',
                        questions: [
                            {
                                questionType: 'radio',
                                title: 'Have you used illegal substances/NPS since applying for this role?',
                                name: 'illegal-substances-use-since-applying-radio',
                                hintOption: 'Select an option',
                                options: ['Yes', 'No'],
                                rules: {
                                    required: true
                                },
                                errorMessages: {
                                    required:
                                        'You must answer whether or not you have ever used illegal substances/NPS since applying for this role'
                                }
                            }
                        ]
                    },
                    'No'
                ],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must answer whether or not you have ever used illegal substances/NPS'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Use whilst holding clearance',
        name: 'illegal-substances-use-while-holding-clearance',
        questions: [
            {
                questionType: 'radio',
                title: 'Have you used illegal substances/NPS whilst holding clearance?',
                name: 'illegal-substances-use-while-holding-clearance-radio',
                hintOption: 'Select an option',
                options: ['Yes', 'No'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required:
                        'You must answer whether or not you have ever used illegal substances/NPS whilst holding clearance'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Illegal substance/NPS details',
        name: 'illegal-substances-used-or-using',
        disabled: [
            {
                field: 'illegal-substances-use-radio',
                condition: 'equals',
                value: 'No'
            }
        ],
        questions: [
            {
                questionType: 'textarea',
                title: 'What illegal substances/NPS are you using/have you used?',
                name: 'illegal-substances-used-or-using-text-input',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must declare which illegal substances/NPS you are using or have used'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Frequency of use',
        name: 'illegal-substances-frequency-of-use',
        disabled: [
            {
                field: 'illegal-substances-use-radio',
                condition: 'equals',
                value: 'No'
            }
        ],
        questions: [
            {
                questionType: 'textInput',
                hint: 'e.g. daily, weekly, etc',
                title: 'What is the frequency of use?',
                name: 'illegal-substances-frequency-of-use-text-input',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must declare the frequency of illegal substances/NPS use'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Last usage',
        name: 'when-last-used-illegal-substances',
        disabled: [
            {
                field: 'illegal-substances-use-radio',
                condition: 'equals',
                value: 'Yes'
            }
        ],
        questions: [
            {
                questionType: 'textInput',
                title: 'When did you last use illegal substances/NPS?',
                hint: "Enter 'never' if you have never used illegal substances/NPS",
                name: 'when-last-used-illegal-substances-text-input',
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must declare when you last used illegal substances/NPS'
                }
            }
        ]
    }
]
export default illegalSubstancesUse

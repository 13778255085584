import content from '../content'
import { api } from '../utils/data-utils'
import { ApiError } from '../utils/api-error'
import { FetchFormResponse, UpdateFormParams } from './types'

export const updateForm = async (token: string, params: UpdateFormParams): Promise<void> => {
    // there is no content returned from the update endpoint
    await api(token, `/form`, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
    })
}

export const submitDeclaration = async (token: string): Promise<boolean> => {
    const res = await api(token, '/form/submit', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    })
    if (res.status === 200) return true
    throw new ApiError(content.errorApi) // Can this ever be reached?
}

export const fetchForm = async (token: string): Promise<FetchFormResponse> => {
    const res = await api(token, `/form`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    })

    const json = await res.json()

    return json || {}
}

import { useMemo } from 'react'
import { Routes, Route, Outlet } from 'react-router-dom'
import Overview from '../../screens/OverviewScreen/OverviewScreen'
import SubsectionScreen from '../../screens/SubsectionScreen/SubsectionScreen'
import SummaryScreen from '../../screens/SummaryScreen/SummaryScreen'
import Error404Screen from '../../screens/ErrorScreen/Error404Screen'
import DefaultErrorScreen from '../../screens/ErrorScreen/DefaultErrorScreen'
import config from '../../config'
import SigninScreen from '../../screens/SigninScreen/SigninScreen'
import AccountPage from '../AccountPage/AccountPage'
import Declaration from '../../screens/DeclarationScreen/DeclarationScreen'
import DeclarationSuccessScreen from '../../screens/DeclarationSuccessScreen/DeclarationSuccessScreen'
import ResetPasswordEmailScreen from '../../screens/ResetPassword/ResetPasswordEmailScreen'
import ResetPasswordScreen from '../../screens/ResetPassword/ResetPasswordScreen'
import { RequireAuth } from '../../auth/RequireAuth'
import { useFormConfig } from '../../hooks/useFormConfig'
import { SubsectionRouterProps } from './SubsectionRouterProps'
import CookiePage from '../../screens/CookiesScreen/CookiePage'
import DocumentDownloadPage from '../../screens/DocumentDownloadScreen/DocumentDownload'
import DocumentUploadPage from '../../screens/DocumentUploadScreen/DocumentUpload'

const { urls } = config

const Account = () => (
    <AccountPage>
        <Outlet />
    </AccountPage>
)

function Router() {
    const { formConfig } = useFormConfig()

    const subsectionProps: SubsectionRouterProps[] = useMemo(
        () =>
            formConfig.sections.reduce(
                (accumulator: SubsectionRouterProps[], section) => [
                    ...accumulator,
                    ...(section?.subsections || []).map(subsection => ({
                        subsection,
                        urlPath: `/${section.slug}/${subsection.slug}`,
                        sectionTitle: subsection.title,
                        sectionName: section.title
                    }))
                ],
                []
            ),
        [formConfig]
    )

    return (
        <Routes>
            <Route path={urls.signin} element={<SigninScreen />} />
            <Route path={urls.resetPasswordEmail} element={<ResetPasswordEmailScreen />} />
            <Route path={urls.resetPassword} element={<ResetPasswordScreen />} />

            <Route
                element={
                    <RequireAuth>
                        <Account />
                    </RequireAuth>
                }
            >
                <Route path={urls.landing} element={<Overview />} />
                <Route path={urls.declaration} element={<Declaration />} />
                <Route path={urls.declarationSuccess} element={<DeclarationSuccessScreen />} />
                {subsectionProps.map(props => (
                    <Route path={props.urlPath} key={props.urlPath}>
                        <Route path='summary' element={<SummaryScreen {...props} />} />
                        <Route path=':page?' element={<SubsectionScreen {...props} />} />
                    </Route>
                ))}
            </Route>

            <Route path={urls.defaultError} element={<DefaultErrorScreen />} />
            <Route path='*' element={<Error404Screen />} />
            <Route path={urls.cookies} element={<CookiePage />} />
            <Route path={urls.documentDownload} element={<DocumentDownloadPage />} />
            <Route path={urls.documentUpload} element={<DocumentUploadPage />} />
        </Routes>
    )
}
export default Router

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { P } from '../../components/ui'
import config from '../../config'
import { useAuthContext } from '../../auth/AuthContext'
import { validateField } from '../../utils/form-utils'
import content from '../../content'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import ErrorMessage from '../../components/form/ErrorMessage'
import Input from '../../components/form/TextInput'

export default function EnterAppMFACodeScreen() {
    const { confirmSignIn } = useAuthContext()
    const [disabled, setDisabled] = useState(false)

    const {
        formState: { errors },
        handleSubmit,
        setError,
        getValues,
        getFieldState,
        register,
        setValue
    } = useForm<{ totp: string }>({
        ...config.form,
        defaultValues: { totp: '' }
    })

    const totpInput = register('totp', {
        validate: (value: string) =>
            validateField(
                'Sign in code',
                {
                    required: true,
                    maxLength: 6,
                    minLength: 6
                },
                value
            )
    })

    return (
        <>
            <ErrorSummary errors={errors} idPrefix='field-' />

            <div className='govuk-grid-row'>
                <div className='govuk-grid-column-two-thirds'>
                    <P>
                        Please visit your authenticator app and enter the 6 digit code from the authenticator app below
                        (the code will update every 30 seconds).
                    </P>
                </div>
                <div className='govuk-grid-column-one-third'>
                    <details className='govuk-details'>
                        <summary className='govuk-details__summary'>
                            <span className='govuk-details__summary-text'>Problems getting this code?</span>
                        </summary>
                        <div className='govuk-details__text'>
                            Make sure you are using the correct authenticator app that you initially signed up with.
                        </div>
                    </details>
                </div>
            </div>

            <form
                onSubmit={handleSubmit(async data => {
                    setDisabled(true)
                    confirmSignIn(data.totp, {
                        onError: () => {
                            setError('totp', {
                                message: content.incorrectMfa
                            })
                            setValue('totp', '')
                        },
                        onSettled: () => {
                            setDisabled(false)
                        }
                    })
                })}
            >
                <div className='govuk-form-group'>
                    <label htmlFor='field-totp' className='govuk-label'>
                        Sign in code
                    </label>
                    {getFieldState('totp').error && <ErrorMessage error={getFieldState('totp').error ?? {}} />}
                    <Input
                        {...totpInput}
                        id='field-totp'
                        autoComplete='off'
                        type='number'
                        onKeyDown={e => {
                            // Limit to integer
                            if (e.key === '.') {
                                e.preventDefault()
                            }
                            // Limit to 6 digits
                            // Any NaN key is a control character that we want to allow
                            if (!Number.isNaN(Number(e.key)) && getValues('totp').length === 6) {
                                e.preventDefault()
                            }
                        }}
                        width='one-quarter'
                        disabled={disabled}
                    />
                </div>

                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonContinue}
                </button>
            </form>
        </>
    )
}

import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'
import {
    AuthUser,
    getCurrentUser,
    signIn,
    SignInOutput,
    ConfirmSignInOutput,
    confirmSignIn,
    fetchAuthSession,
    signOut,
    setUpTOTP,
    verifyTOTPSetup,
    updateMFAPreference,
    resetPassword,
    ResetPasswordOutput,
    confirmResetPassword
} from '@aws-amplify/auth'
import { Amplify, type ResourcesConfig } from 'aws-amplify'
import { sessionStorage } from 'aws-amplify/utils'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { applicationName } from '../constants'
import { LastLocation } from '../types'

const authConfig: ResourcesConfig = {
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID ?? 'eu-west-2_KR11oO3Dr',
            userPoolClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID ?? '38d014df46gq0r4pjopl11bu78'
        }
    }
}
Amplify.configure(authConfig)
cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage)

// This is the state that is not retrieved from Cognito or calculated
interface AuthState {
    challengeName?: string
    totpSharedSecret?: string
    totpSetupUri?: string
}

// This is state mutators, state obtained from useQuery, and calculated state
export interface AuthContextProps extends AuthState {
    user?: AuthUser
    isAuthenticated: boolean
    signIn: UseMutateFunction<
        SignInOutput,
        Error,
        {
            username: string
            password: string
        },
        unknown
    >
    confirmSignIn: UseMutateFunction<ConfirmSignInOutput, Error, string, unknown>
    fetchJWT(): Promise<{ accessToken?: string; idToken?: string }>
    fetchMfaSetupDetails(): void
    signOut: UseMutateFunction<void, Error, void, unknown>
    setMfaPreferences: UseMutateFunction<void, Error, string, unknown>
    resetPassword: UseMutateFunction<ResetPasswordOutput, Error, string, unknown>
    confirmResetPassword: UseMutateFunction<
        void,
        Error,
        { username: string; newPassword: string; confirmationCode: string },
        unknown
    >
}

export const AuthContext = createContext<AuthContextProps | null>(null)

export const AuthProvider = ({ children }: PropsWithChildren) => {
    const [state, setState] = useState<AuthState>({})

    const queryClient = useQueryClient()

    const { isError: authError, data: authUser } = useQuery<AuthUser, Error>({
        queryKey: ['cognitoUser'],
        queryFn: async () => {
            const user = await getCurrentUser() // https://aws-amplify.github.io/amplify-js/api/functions/aws_amplify.auth.getCurrentUser.html
            return user
        },
        staleTime: Infinity,
        enabled: !!sessionStorage.storage?.length
    })

    const { mutate: setMfaPreferencesMutate } = useMutation<void, Error, string>({
        mutationFn: async code => {
            await verifyTOTPSetup({ code })
            await updateMFAPreference({ sms: 'DISABLED', totp: 'PREFERRED' })
        },
        onSuccess: () => {
            setState(prev => ({
                ...prev,
                challengeName: 'DONE'
            }))
        }
    })

    const { mutate: signInMutate } = useMutation<SignInOutput, Error, { username: string; password: string }>({
        mutationFn: async ({ username, password }) => await signIn({ username, password }),
        onSuccess: async output => {
            queryClient.invalidateQueries({ queryKey: ['cognitoUser'] })
            sessionStorage.removeItem(LastLocation)
            if (output.nextStep) {
                const nextChallenge = output.nextStep.signInStep

                if (
                    output.nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP' &&
                    output.nextStep.totpSetupDetails
                ) {
                    const { sharedSecret, getSetupUri } = output.nextStep.totpSetupDetails
                    const setupUri = await getSetupUri(applicationName)
                    setState(prev => ({
                        ...prev,
                        totpSharedSecret: sharedSecret,
                        totpSetupUri: setupUri.toString()
                    }))
                }

                setState(prev => ({
                    ...prev,
                    challengeName: nextChallenge
                }))
            }
        }
    })

    const { mutate: confirmSignInMutate } = useMutation<ConfirmSignInOutput, Error, string>({
        mutationFn: async challengeResponse => await confirmSignIn({ challengeResponse }),
        onSuccess: async output => {
            queryClient.invalidateQueries({ queryKey: ['cognitoUser'] })
            if (output.nextStep) {
                const nextChallenge = output.nextStep.signInStep

                if (
                    output.nextStep.signInStep === 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP' &&
                    output.nextStep.totpSetupDetails
                ) {
                    const { sharedSecret, getSetupUri } = output.nextStep.totpSetupDetails
                    const setupUri = await getSetupUri(applicationName)
                    setState(prev => ({
                        ...prev,
                        totpSharedSecret: sharedSecret,
                        totpSetupUri: setupUri.toString()
                    }))
                }

                setState(prev => ({
                    ...prev,
                    challengeName: nextChallenge
                }))
            }
        }
    })

    const { mutate: signOutMutate } = useMutation<void, Error, void>({
        mutationFn: async () => {
            await signOut()
        },
        onSettled: () => {
            queryClient.clear()
            sessionStorage.clear()
            localStorage.clear()
        }
    })

    const { mutate: resetPasswordMutate } = useMutation<ResetPasswordOutput, Error, string>({
        mutationFn: async username => await resetPassword({ username })
    })

    const { mutate: confirmResetPasswordMutate } = useMutation<
        void,
        Error,
        { username: string; newPassword: string; confirmationCode: string }
    >({
        mutationFn: async ({ username, newPassword, confirmationCode }) =>
            await confirmResetPassword({ username, newPassword, confirmationCode })
    })

    const isAuthenticated = useMemo(() => {
        const { challengeName } = state
        return !authError && authUser !== undefined && (challengeName === 'DONE' || challengeName === undefined)
    }, [authUser, state.challengeName])

    const fetchJWT = async (): Promise<{ accessToken?: string; idToken?: string }> => {
        const session = await fetchAuthSession()
        return { accessToken: session.tokens?.accessToken.toString(), idToken: session.tokens?.idToken?.toString() }
    }

    // Gets the URI to use for setting up the TOTP MFA
    const fetchMfaSetupDetails = async () => {
        const setupDetails = await setUpTOTP()
        setState(prev => ({
            ...prev,
            totpSharedSecret: setupDetails.sharedSecret,
            totpSetupUri: setupDetails.getSetupUri(applicationName).toString()
        }))
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                isAuthenticated,
                user: authUser,
                signIn: signInMutate,
                confirmSignIn: confirmSignInMutate,
                fetchJWT,
                fetchMfaSetupDetails,
                signOut: signOutMutate,
                setMfaPreferences: setMfaPreferencesMutate,
                resetPassword: resetPasswordMutate,
                confirmResetPassword: confirmResetPasswordMutate
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuthContext = (): AuthContextProps => {
    const authContext = useContext(AuthContext)
    if (!authContext) {
        throw Error('No AuthProvider found.')
    }
    return authContext
}

import React from 'react'
import { Link } from 'react-router-dom'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { P } from '../../components/ui'

function Error404Screen() {
    return (
        <PageTemplate>
            <h1 className='govuk-heading-l'>Something has gone wrong!</h1>
            <P>Sorry, there is a problem with the service. Try again later.</P>
            <P>
                Return to{' '}
                <Link data-testid='default-error-overview-link' to='/'>
                    Your questionnaire
                </Link>{' '}
                page.
            </P>
        </PageTemplate>
    )
}

export default Error404Screen

import { PropsWithChildren } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from './AuthContext'
import config from '../config'
import { LastLocation } from '../types'

const { urls } = config

export const RequireAuth = ({ children }: PropsWithChildren) => {
    const location = useLocation()
    const { isAuthenticated } = useAuthContext()
    if (!isAuthenticated) {
        return <Navigate to={urls.signin} state={{ from: location }} replace />
    }
    if (isAuthenticated) {
        sessionStorage.setItem(LastLocation, location.pathname)
    }
    return children
}

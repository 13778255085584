import { useId } from 'react'
import { Controller, useForm } from 'react-hook-form'
import type { Props, Fields } from './types'
import ErrorSummary from '../../ErrorSummary/ErrorSummary'
import { validateField } from '../../../utils/form-utils'
import content from '../../../content'
import config from '../../../config'
import ErrorMessage from '../ErrorMessage'
import Input from '../TextInput'

export default function PhoneSetupForm({ onSuccess }: Props) {
    const id = useId()

    const {
        formState: { errors },
        handleSubmit,
        control,
        getValues
    } = useForm<Fields>({
        ...config.form,
        defaultValues: { mobile: '', mobile2: '' }
    })

    return (
        <>
            <ErrorSummary errors={errors} idPrefix={id} />

            <div className='govuk-inset-text'>
                Every time you sign in to your account you will need to enter an access code, which will be sent to your
                mobile phone
            </div>

            <form
                onSubmit={handleSubmit(async () => {
                    onSuccess()
                })}
            >
                <div className={`govuk-form-group ${errors.mobile && 'govuk-form-group--error'}`}>
                    <label htmlFor={`${id}mobile`} className='govuk-label govuk-label--s'>
                        Mobile phone number (with country code)
                    </label>
                    <div id={`mobilehint-${id}`} className='govuk-hint'>
                        For example, if you live in the UK, enter your mobile number like this <br />
                        +4477 990 00123
                    </div>
                    <Controller
                        name='mobile'
                        control={control}
                        rules={{
                            validate: (value: string) =>
                                validateField('Mobile phone number', { required: true, telephone: true }, value)
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id={`${id}mobile`}
                                    aria-describedby={`mobilehint-${id}`}
                                    type='tel'
                                    width='three-quarters'
                                    autoComplete='off'
                                />
                            </>
                        )}
                    />
                </div>

                <div className='govuk-inset-text'>
                    It is important that we have the correct mobile phone number for you, please re-enter to confirm
                    your mobile phone number below
                </div>

                <div className={`govuk-form-group ${errors.mobile && 'govuk-form-group--error'}`}>
                    <label htmlFor={`${id}mobile2`} className='govuk-label govuk-label--s'>
                        Re-enter mobile phone number (with country code)
                    </label>
                    <Controller
                        name='mobile2'
                        control={control}
                        rules={{
                            validate: {
                                validateField: (value: string) =>
                                    validateField(
                                        'Re-enter mobile phone number',
                                        { required: true, telephone: true },
                                        value
                                    ),
                                mobileEqual: value =>
                                    value === getValues().mobile || 'Mobile phone numbers do not match'
                            }
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id={`${id}mobile2`}
                                    type='tel'
                                    width='three-quarters'
                                    autoComplete='off'
                                />
                            </>
                        )}
                    />
                </div>

                <button type='submit' className='govuk-button'>
                    {content.buttonContinue}
                </button>
            </form>
        </>
    )
}

import { P } from '../../components/ui'

export default function CTCPortal() {
    return (
        <>
            <h1 className='govuk-heading-xl'>Sign into your CTC Portal account</h1>

            <P>Sign in to your CTC Portal account to:</P>
            <ul>
                <li className='li-no-padding'>
                    <P>You are a vetting subject with an ongoing application for clearance</P>
                </li>
                <li className='li-no-padding'>
                    <P>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</P>
                </li>
                <li className='li-no-padding'>
                    <P>Ut enim ad minim veniam, quis nostrud exercitation ullamco</P>
                </li>
                <li>
                    <P>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum</P>
                </li>
            </ul>
            <P>
                Use the username and password you set up when you applied for clearance. You can ask for a reminder if
                you are not sure
            </P>
            <div className='govuk-inset-text'>
                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            </div>
        </>
    )
}

import React, { useRef, useEffect, useMemo, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import Autocomplete from 'accessible-autocomplete/react'
import ErrorMessage from '../ErrorMessage'
import { ErrorMessages, Rules } from '../../../config/types'
import { getInputRules, validateField } from '../../../utils/form-utils'
import { textareaMaxLength } from '../../../constants'
import Details from '../Details'

type Props = {
    id: string
    items: string[]
    name: string
    title: string
    hintText?: string
    label?: string
    errorMessages?: ErrorMessages
    rules?: Rules
    detailsHeading?: string
    detailsContent?: string
}

function AutocompleteWrapper(props: Props) {
    const { id, items, hintText, label, name, errorMessages, title, rules, detailsHeading, detailsContent } = props
    const inputRef = useRef<HTMLInputElement | null>(null)
    const { register, getValues, setValue, getFieldState, trigger } = useFormContext()

    const inputRules = useMemo(() => ({ ...getInputRules(rules ?? {}, title, textareaMaxLength) }), [rules, title])

    useEffect(() => {
        if (getValues(name) === undefined) {
            setValue(name, '')
        }
    }, [setValue])

    const suggest = useCallback(
        (query: string, populateResults: (results: string[]) => void) => {
            const filteredResults = items.filter(result => result.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            populateResults(filteredResults)
        },
        [items]
    )

    const hooksFormRegister = register(name, {
        validate: (value: string) => validateField(title, inputRules, value, errorMessages)
    })

    const { error } = getFieldState(name)

    const handleConfirm = (value: string | undefined) => {
        if (value) {
            setValue(name, value, { shouldValidate: true })
            trigger(name)
        }
    }

    return (
        <>
            {hintText ? (
                <>
                    <label className='govuk-fieldset__legend govuk-fieldset__legend--s' htmlFor={name}>
                        {label}
                    </label>
                    <div className='govuk-hint'>{hintText}</div>
                </>
            ) : (
                <label className='govuk-label' htmlFor={name}>
                    {label}
                </label>
            )}

            {error && <ErrorMessage error={error} />}
            {detailsHeading && <Details heading={detailsHeading} content={detailsContent} />}

            <div className='govuk-!-width-three-quarters'>
                <Autocomplete
                    {...hooksFormRegister}
                    defaultValue={getValues(name) || ''}
                    id={id}
                    name={name}
                    source={suggest}
                    confirmOnBlur
                    ref={inputRef}
                    onConfirm={handleConfirm}
                />
            </div>
        </>
    )
}
AutocompleteWrapper.displayName = 'Autocomplete'

export default AutocompleteWrapper

import React, { useMemo } from 'react'
import { useSearchParams, Navigate } from 'react-router-dom'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { Page } from './types'
import config from '../../config'
import Notification from '../../components/Notification/Notification'
import SigninForm from '../../components/form/SigninForm/SigninForm'
import PhoneSetupForm from '../../components/form/PhoneSetupForm/PhoneSetupForm'
import MfaSmsForm from '../../components/form/MfaSmsForm/MfaSmsForm'
import content from '../../content'
import { useAuthContext } from '../../auth/AuthContext'
import SetNewPasswordScreen from '../SetNewPassword/SetNewPasswordScreen'
import SetupAppMFAForm from '../../components/form/SetupAppMFA/SetupAppMFAForm'
import EnterAppMFACodeScreen from '../AppMFA/EnterAppMFACodeScreen'
import { LastLocation } from '../../types'

const codes: Record<string, string> = { 1: content.noticeTokenInvalid }

export default function SigninScreen() {
    const [searchParams] = useSearchParams()
    const code = codes[String(searchParams.get('ncode'))]

    const { isAuthenticated, challengeName } = useAuthContext()

    const pages: Page[] = [
        {
            children: (
                <>
                    {code && <Notification content={code} />}
                    <SigninForm onSuccess={() => {}} />
                </>
            )
        },
        {
            title: 'Enter your mobile phone number',
            backLink: config.urls.signin,
            children: <PhoneSetupForm onSuccess={() => {}} />
        },
        {
            title: 'Enter the sign in code',
            backLink: config.urls.signin,
            children: <MfaSmsForm onSuccess={() => {}} phonenumber='********726' />,
            fullWidth: true
        },
        {
            title: 'Change your password',
            backLink: config.urls.signin,
            children: <SetNewPasswordScreen onSuccess={() => {}} />,
            fullWidth: true
        },
        {
            title: 'Enter your sign in code',
            backLink: config.urls.signin,
            children: <SetupAppMFAForm />,
            fullWidth: true
        },
        {
            title: 'Enter the sign in code',
            backLink: config.urls.signin,
            children: <EnterAppMFACodeScreen />
        }
    ]

    const activePage = useMemo(() => {
        switch (challengeName) {
            case 'CONFIRM_SIGN_IN_WITH_TOTP_CODE':
                return pages[5]
            case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
                return pages[3]
            case 'CONTINUE_SIGN_IN_WITH_TOTP_SETUP':
                return pages[4]
            default:
                return pages[0]
        }
    }, [challengeName])

    if (isAuthenticated || !activePage) {
        return <Navigate to={sessionStorage.getItem(LastLocation) || config.urls.landingAccounts} />
    }

    return <PageTemplate {...activePage} />
}

import { UseFormProps } from 'react-hook-form'

/*
    default config parameters for forms
    Full config values: https://react-hook-form.com/docs/useform
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const form: Partial<UseFormProps<any>> = {
    shouldFocusError: false,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit'
}

const config = {
    apiUrl: process.env.REACT_APP_API_URL || null,
    disableAuth: process.env.REACT_APP_DISABLE_AUTH === 'true',
    urls: {
        landing: '/',
        landingAccounts: '/',
        signin: '/signin',
        declaration: '/declaration',
        declarationSuccess: '/declaration/success',
        resetPasswordEmail: '/reset-password-email',
        resetPassword: '/reset-password',
        enterAppMFACode: '/enter-app-mfa-code',
        setNewPassword: '/set-new-password',
        defaultError: '/error',
        cookies: '/cookies',
        documentDownload: '/document-download',
        documentUpload: '/document-upload'
    },
    form
} as const

export default config

import { Page } from '../types'
import { COUNTRIES } from '../defaultContent'

const addresses: Page[] = [
    {
        pageType: 'information',
        title: 'Before you begin',
        name: 'before-you-begin-addresses',
        description:
            "Add all addresses you have lived in the past 5 years, including:  <ul><li> Home addresses </li><li> Military accommodation </li><li> Temporary accommodation </li><li> Student and term time addresses </li><li> Addresses you have lived in while away from home </li><li> Periods of no fixed address (for example, boats, caravans and/or homeless) </li></ul>You don't need to include holiday addresses where you have stayed for less than 30 days<br><br>"
    },
    {
        pageType: 'question',
        title: 'Address type',
        name: 'address-type',
        questions: [
            {
                name: 'address-type',
                questionType: 'radio',
                title: 'What type of address are you adding?',
                detailsHeading: 'More information',
                detailsContent:
                    'Select one option. You can add additional addresses later. Provide as much detail as you can about the location or no fixed address, for example: <ul><li>Name of ship, barge or boat, including the location or marina address (if applicable)</li><li>Name of caravan, including address and where your post is sent to</li><li> Name and address of temporary accommodation and/or a long term hostel</li></ul> If you have a period of no fixed address please provide the reason for this<br>',
                hintOption: 'Please Select',
                options: ['UK', 'Other'],
                rules: {
                    required: true
                },
                errorMessages: {
                    required: 'You must select which type of address you are adding'
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Enter UK address',
        name: 'enter-uk-address',
        disabled: [
            {
                field: 'address-type',
                condition: 'equals',
                value: 'Other'
            }
        ],
        questions: [
            {
                questionType: 'addressLookup',
                title: '',
                name: 'uk-address',
                rules: {
                    required: true
                }
            },
            {
                questionType: 'date',
                title: 'Date from',
                name: 'uk-address-date-from',
                rules: {
                    required: true,
                    isBeforeNow: 'enabled'
                }
            },
            {
                questionType: 'date',
                title: 'Date to',
                name: 'uk-address-date-to',
                rules: {
                    required: true
                }
            }
        ]
    },
    {
        pageType: 'question',
        title: 'Other address',
        name: 'other-address',
        disabled: [
            {
                field: 'address-type',
                condition: 'equals',
                value: 'UK'
            }
        ],
        questions: [
            {
                questionType: 'textInput',
                title: 'Street address line 1',
                name: 'other-address-street-address-line-one',
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'Street address line 2',
                name: 'other-address-street-address-line-two'
            },
            {
                questionType: 'textInput',
                title: 'City / Town',
                name: 'other-address-city-or-town',
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'State / Province / Region',
                name: 'other-address-state-province-region',
                rules: {
                    required: true
                }
            },
            {
                questionType: 'textInput',
                title: 'Postal / ZIP Code',
                name: 'other-address-postal-or-zip-code',
                rules: {
                    required: true
                }
            },
            {
                questionType: 'autoComplete',
                title: 'Country',
                name: 'other-address-country',
                items: COUNTRIES,
                rules: {
                    required: true
                }
            },
            {
                questionType: 'date',
                title: 'Date from',
                name: 'other-address-date-from',
                rules: {
                    required: true,
                    isBeforeNow: 'enabled'
                }
            },
            {
                questionType: 'date',
                title: 'Date to',
                name: 'other-address-date-to',
                rules: {
                    required: true
                }
            }
        ]
    }
]

export default addresses

import { Fields } from './types'

// For Manual Address
export const fields: Fields[] = [
    {
        name: 'line1',
        required: true
    },
    {
        name: 'line2'
    },
    {
        name: 'town',
        width: 'medium',
        required: true
    },
    {
        name: 'county',
        width: 'medium'
    },
    {
        name: 'postcode',
        width: 'small',
        required: true
    }
]

import { useId } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Props } from './types'
import content from '../../../content'
import { P } from '../../ui'
import { validateField } from '../../../utils/form-utils'
import ButtonText from '../../ButtonText'
import ErrorSummary from '../../ErrorSummary/ErrorSummary'
import config from '../../../config'
import { useAuthContext } from '../../../auth/AuthContext'
import ErrorMessage from '../ErrorMessage'
import Input from '../TextInput'

const label = 'Sign in code'

export default function MfaSmsForm({ phonenumber }: Props) {
    const id = useId()
    const fieldId = `${id}code`
    const {
        formState: { errors },
        handleSubmit,
        control
    } = useForm<{ code: string }>({
        ...config.form,

        defaultValues: { code: '' }
    })

    const { confirmSignIn } = useAuthContext()

    return (
        <>
            <ErrorSummary errors={errors} idPrefix={id} />

            <div className='govuk-width-container'>
                <main className='govuk-main-wrapper'>
                    <div className='govuk-grid-row'>
                        <div className='govuk-grid-column-two-thirds'>
                            <P>We have sent a 6 digit sign in code to: {phonenumber}</P>

                            <form
                                onSubmit={handleSubmit(async data => {
                                    confirmSignIn(data.code)
                                })}
                            >
                                <div className={`govuk-form-group ${errors.code && 'govuk-form-group--error'}`}>
                                    <label htmlFor={fieldId} className='govuk-label govuk-label--s'>
                                        {label}
                                    </label>
                                    <Controller
                                        name='code'
                                        control={control}
                                        rules={{
                                            validate: (value: string) => validateField(label, { required: true }, value)
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <>
                                                {error && <ErrorMessage error={error} />}
                                                <Input {...field} width='one-quarter' id={fieldId} autoComplete='off' />
                                            </>
                                        )}
                                    />
                                    <ButtonText type='button' style={{ marginLeft: 30 }}>
                                        Resend code
                                    </ButtonText>
                                </div>

                                <button type='submit' className='govuk-button'>
                                    {content.buttonContinue}
                                </button>
                            </form>
                        </div>

                        <div className='govuk-grid-column-one-third'>
                            <details className='govuk-details'>
                                <summary className='govuk-details__summary'>
                                    <span className='govuk-details__summary-text'>Problems getting this code?</span>
                                </summary>
                                <div className='govuk-details__text'>
                                    <P>
                                        Make sure your mobile phone is switched on and has signal. You can also request
                                        to resend the code if you have not received it.
                                    </P>
                                </div>
                            </details>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

import { Link } from 'react-router-dom'
import { FieldValues } from 'react-hook-form'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import { SubsectionRouterProps } from '../../components/Router/SubsectionRouterProps'
import Tag from '../../components/Tag/Tag'
import { Status } from '../../types'
import type { FetchFormResponse } from '../../service/types'
import { getSelectedConditionalQuestions, isPageCompleted, renderAnswer } from '../../utils/config-utils'
import { useFormData } from '../../hooks/useFormData'
import { useFormConfig } from '../../hooks/useFormConfig'
import { Question } from '../../config/types'
import handleDynamicText from '../../hooks/handleDynamicText'

export function renderSummaryRow(
    question: Question,
    formattedAnswers: string | string[],
    showBullet: string,
    dynamicQuestion?: string
) {
    return (
        <tr className='govuk-table__row' key={question.name}>
            <td className='govuk-table__cell'>{dynamicQuestion || question.title}</td>
            <td className='govuk-table__cell' colSpan={2} style={{ height: '46px' }}>
                {Array.isArray(formattedAnswers) ? (
                    <ul className={`govuk-list ${showBullet} govuk-!-margin-0`}>
                        {formattedAnswers.map(a => (
                            <li key={a}>{a}</li>
                        ))}
                    </ul>
                ) : (
                    formattedAnswers
                )}
            </td>
        </tr>
    )
}

export function renderListAnswers(formValues: FieldValues, questions: Question[]) {
    const listAnswers: { question: Question; questionName: string; answer: string }[] = []

    Object.entries(formValues).forEach(([questionName, answer]) => {
        const question = questions.find(q => questionName.endsWith(q.name))
        if (question) listAnswers.push({ question, questionName, answer })
    })

    return (
        <>
            {listAnswers.map(listAnswer => {
                const formattedAnswers = renderAnswer(listAnswer.question, listAnswer.answer)
                const showBullet = listAnswer.question.questionType === 'checkbox' ? 'govuk-list--bullet' : ''

                return <>{renderSummaryRow(listAnswer.question, formattedAnswers, showBullet)}</>
            })}
        </>
    )
}

function SummaryScreen({ subsection, sectionTitle, sectionName, urlPath }: SubsectionRouterProps) {
    const { enabledSubsections } = useFormConfig()
    const { pages } = enabledSubsections[subsection.slug]

    const { data } = useFormData()
    const answers: FetchFormResponse['answers'] = data?.answers || {}

    return (
        <PageTemplate breadcrumbs={{ sectionName, sectionTitle }} title={sectionTitle} subTitle={sectionName}>
            <table className='govuk-table'>
                <caption className='govuk-table__caption govuk-table__caption--l'>Your answers</caption>

                {pages.map(({ title, pageType, ...page }) => {
                    const isDraft = !!data?.drafts?.[subsection.slug]?.includes(page.name)
                    const questions = 'questions' in page ? page.questions || [] : []
                    const isCompleted = isPageCompleted(questions, answers) ? Status.Completed : Status.NotStarted
                    const status = isDraft ? Status.InProgress : isCompleted

                    return (
                        <tbody className='govuk-table__body' key={subsection.slug}>
                            <tr className='govuk-table__row'>
                                <th
                                    scope='row'
                                    className='govuk-table__header govuk-!-width-one-half'
                                    style={{ paddingTop: 30, paddingBottom: 15 }}
                                >
                                    {handleDynamicText(title, answers)}
                                </th>
                                <td
                                    className='govuk-table__cell  govuk-!-width-one-quarter  govuk-!-width-one-quarter'
                                    data-testid='status'
                                    style={{ paddingTop: 30, paddingBottom: 15 }}
                                >
                                    {pageType === 'question' && <Tag status={status} />}
                                </td>
                                <td
                                    className='govuk-table__cell'
                                    style={{ textAlign: 'right', paddingTop: 30, paddingBottom: 15 }}
                                >
                                    <Link to={`${urlPath}/${page.name}`}>
                                        {pageType === 'information' && 'View'}
                                        {pageType === 'list' && 'View'}
                                        {pageType === 'question' &&
                                            (status === Status.Completed ? 'Change' : 'Go to Question')}
                                    </Link>
                                </td>
                            </tr>
                            {pageType === 'question' &&
                                questions.map(question => {
                                    const formattedAnswers = renderAnswer(question, answers[question.name])
                                    const conditionalQuestions = getSelectedConditionalQuestions(
                                        question,
                                        answers[question.name]
                                    )
                                    const showBullet = question.questionType === 'checkbox' ? 'govuk-list--bullet' : ''
                                    const dynamicQuestion = handleDynamicText(question.title, answers)

                                    return (
                                        <>
                                            {renderSummaryRow(question, formattedAnswers, showBullet, dynamicQuestion)}

                                            {conditionalQuestions.map(conditionalQuestion => {
                                                const formattedConditionalAnswer = renderAnswer(
                                                    conditionalQuestion,
                                                    answers[conditionalQuestion.name]
                                                )

                                                return (
                                                    <>
                                                        {renderSummaryRow(
                                                            conditionalQuestion,
                                                            formattedConditionalAnswer,
                                                            conditionalQuestion.questionType === 'checkbox'
                                                                ? 'govuk-list--bullet'
                                                                : '',
                                                            dynamicQuestion
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </>
                                    )
                                })}

                            {pageType === 'list' && renderListAnswers(answers, questions)}
                        </tbody>
                    )
                })}
            </table>
        </PageTemplate>
    )
}

export default SummaryScreen

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import PageTemplate from '../../components/PageTemplate/PageTemplate'
import config from '../../config'
import ErrorSummary from '../../components/ErrorSummary/ErrorSummary'
import content from '../../content'
import { validateField } from '../../utils/form-utils'
import { P } from '../../components/ui'
import { useAuthContext } from '../../auth/AuthContext'
import ErrorMessage from '../../components/form/ErrorMessage'
import Input from '../../components/form/TextInput'

const label = 'Email address'

export default function ResetPasswordEmailScreen() {
    const { resetPassword } = useAuthContext()
    const navigate = useNavigate()
    const {
        formState: { errors },
        handleSubmit,
        control,
        setError
    } = useForm<{ email: string }>({
        ...config.form,
        defaultValues: { email: '' }
    })
    const [disabled, setDisabled] = useState(false)

    return (
        <PageTemplate title='Reset password' backLink={config.urls.signin}>
            <ErrorSummary errors={errors} idPrefix='field-' />

            <P>
                Enter the email address you are registered with. To confirm it is your email address we will send you a
                code.
            </P>
            <form
                onSubmit={handleSubmit(async data => {
                    try {
                        setDisabled(true)
                        resetPassword(data.email, {
                            onSuccess: () => navigate(config.urls.resetPassword, { state: { email: data.email } }),
                            onError: () => navigate(config.urls.resetPassword, { state: { email: data.email } }),
                            onSettled: () => {
                                setDisabled(false)
                            }
                        })
                    } catch (err) {
                        setDisabled(false)
                        const message = (err as Error).message || content.errorApi
                        setError('email', { message })
                    }
                })}
                noValidate
            >
                <div className={`govuk-form-group ${errors?.email && 'govuk-form-group--error'}`}>
                    <label htmlFor='field-email' className='govuk-label'>
                        {label}
                    </label>
                    <Controller
                        name='email'
                        control={control}
                        rules={{
                            validate: (value: string) => validateField(label, { required: true, email: true }, value)
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                {error && <ErrorMessage error={error} />}
                                <Input
                                    {...field}
                                    id='field-email'
                                    type='email'
                                    width='three-quarters'
                                    autoComplete='off'
                                    disabled={disabled}
                                />
                            </>
                        )}
                    />
                </div>
                <button type='submit' className='govuk-button' disabled={disabled}>
                    {content.buttonContinue}
                </button>
            </form>
        </PageTemplate>
    )
}

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Header from '../Header/Header'
import Footer from '../form/Footer'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import { Props } from './types'
import { useUiContext } from '../../context/UiContext'
import DCFMarkdown from '../DCFMarkdown/DCFMarkdown'
import PhaseBanner from '../form/PhaseBanner'
import CookieBanner from '../form/CookieBanner'
import { useFormData } from '../../hooks/useFormData'
import { FetchFormResponse } from '../../service/types'
import handleDynamicText from '../../hooks/handleDynamicText'

const Container = styled.div.withConfig({
    shouldForwardProp: prop => !['loading'].includes(prop)
})<{ loading?: boolean }>`
    position: relative;

    &:after {
        content: ${p => (p.loading ? "''" : 'normal')};
        position: absolute;
        inset: 0px;
        overflow: hidden;
        height: 100%;
        width: 100%;
        z-index: 100;
        transition: background-color 800ms ease-in-out 0s;
        will-change: background-color;
        background-color: rgba(255, 255, 255, 0.85);
    }
`

function PageTemplate({ children, breadcrumbs, title, subTitle, fullWidth = false, backLink }: Props) {
    const { isLoading } = useUiContext()
    const titleRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
        titleRef.current?.focus()
    }, [title])

    const { data } = useFormData()
    const formAnswers: FetchFormResponse['answers'] = data?.answers || {}
    const dynamicTitle = handleDynamicText(title, formAnswers)

    return (
        <>
            <CookieBanner />
            <a href='#main-content' className='govuk-skip-link' data-module='govuk-skip-link'>
                Skip to main content
            </a>

            <Header loading={isLoading} />

            <Container className='govuk-width-container' loading={isLoading}>
                <PhaseBanner />
                {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
                {backLink && (
                    <nav>
                        <a className='govuk-back-link' href={backLink}>
                            Back
                        </a>
                    </nav>
                )}
                <main className='govuk-main-wrapper' id='main-content' role='main'>
                    <div className='govuk-grid-row'>
                        <div
                            className={fullWidth ? 'govuk-grid-column-full' : 'govuk-grid-column-two-thirds'}
                            data-testid='pageWidth'
                            aria-live='polite'
                            aria-busy={Boolean(isLoading).toString() as 'true' | 'false'}
                        >
                            {title && (
                                <h1 className='govuk-heading-xl' ref={titleRef} tabIndex={-1}>
                                    {subTitle && <DCFMarkdown content={subTitle} type='subtitle' />}
                                    {dynamicTitle}
                                </h1>
                            )}
                            {children}
                        </div>
                    </div>
                </main>
            </Container>
            <Footer />
        </>
    )
}

export default PageTemplate

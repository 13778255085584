import parse from 'html-react-parser'

type Props = {
    heading: string
    content: string | undefined
}

function Details({ heading, content }: Props) {
    return (
        <details className='govuk-details'>
            <summary className='govuk-details__summary'>
                <span className='govuk-details__summary-text'>{heading}</span>
            </summary>
            <div className='govuk-details__text'>{parse(content || '')}</div>
        </details>
    )
}

export default Details
